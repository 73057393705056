import {
    ADD_NEW_GUEST,
    ADD_NEW_GUEST_SUCCESS,
    ADD_NEW_GUEST_FAILURE,
    RESET_ADD_NEW_GUEST,
    GET_GUESTS,
    REMOVE_GUEST,
    REMOVE_GUEST_SUCCESS,
    RESET_REMOVE_GUEST,
    REMOVE_GUEST_FAILURE,
    SET_GUEST_INVITES,
    SET_GUEST_ACCESS_KEYS,
    SET_ONE_TIME_ACCESS_CODES
} from 'store/actions/types';

export const getGuests = () => {
    return {
        type: GET_GUESTS
    };
};

export const storeGuestsInvites = guestsInvites => {
    return {
        type: SET_GUEST_INVITES,
        payload: guestsInvites
    };
};

export const storeGuestsAccessKeys = accessKeys => {
    return {
        type: SET_GUEST_ACCESS_KEYS,
        payload: accessKeys
    };
};

export const storeOneTimeAccessCodes = oneTimeAccessKeys => {
    return {
        type: SET_ONE_TIME_ACCESS_CODES,
        payload: oneTimeAccessKeys
    };
};

export const addingNewGuest = guest => {
    return {
        type: ADD_NEW_GUEST,
        payload: guest
    };
};

export const addingNewGuestSuccess = () => {
    return {
        type: ADD_NEW_GUEST_SUCCESS
    };
};

export const addingNewGuestFailure = error => {
    return {
        type: ADD_NEW_GUEST_FAILURE,
        payload: error
    };
};

export const resettingNewGuest = type => {
    return {
        type: RESET_ADD_NEW_GUEST,
        payload: type
    };
};

export const resettingRemoveGuest = type => {
    return {
        type: RESET_REMOVE_GUEST,
        payload: type
    };
};

export const removeGuest = guest => {
    return {
        type: REMOVE_GUEST,
        payload: guest
    };
};

export const removeGuestSuccess = () => {
    return {
        type: REMOVE_GUEST_SUCCESS
    };
};

export const removeGuestFailure = error => {
    return {
        type: REMOVE_GUEST_FAILURE,
        payload: error
    };
};
