import {
    ADD_NEW_REGISTRATION,
    ADD_NEW_REGISTRATION_SUCCESS,
    ADD_NEW_REGISTRATION_FAILURE,
    RESET_ADD_NEW_REGISTRATION,
    ADD_NEW_OCCUPANT_REGISTRATION,
    REMOVE_REGISTRATION,
    REMOVE_REGISTRATION_SUCCESS,
    REMOVE_REGISTRATION_FAILURE,
    RESET_REMOVE_REGISTRATION,
    GET_REGISTRATIONS_BY_PROPERTY_ID,
    GET_REGISTRATIONS_BY_PROPERTY_ID_SUCCESS,
    GET_REGISTRATIONS_BY_PROPERTY_ID_FAILURE,
    EDIT_REGISTRATION,
    EDIT_REGISTRATION_SUCCESS,
    EDIT_REGISTRATION_FAILURE,
    RESET_EDIT_REGISTRATION,
    UPDATE_FAMILY_MEMBER,
    UPDATE_FAMILY_MEMBER_SUCCESS,
    UPDATE_FAMILY_MEMBER_FAILURE,
    RESET_UPDATE_FAMILY_MEMBER
} from './types';

export const addingNewOccupantRegistration = member => {
    return {
        type: ADD_NEW_OCCUPANT_REGISTRATION,
        payload: member
    };
};

export const addingNewRegistration = member => {
    return {
        type: ADD_NEW_REGISTRATION,
        payload: member
    };
};

export const addingNewRegistrationSuccess = () => {
    return {
        type: ADD_NEW_REGISTRATION_SUCCESS
    };
};

export const addingNewRegistrationFailure = error => {
    return {
        type: ADD_NEW_REGISTRATION_FAILURE,
        payload: error
    };
};

export const resetAddingNewRegistration = () => {
    return {
        type: RESET_ADD_NEW_REGISTRATION
    };
};

export const editRegistration = member => {
    return {
        type: EDIT_REGISTRATION,
        payload: member
    };
};

export const editRegistrationSuccess = () => {
    return {
        type: EDIT_REGISTRATION_SUCCESS
    };
};

export const editRegistrationFailure = error => {
    return {
        type: EDIT_REGISTRATION_FAILURE,
        payload: error
    };
};

export const resetEditRegistration = () => {
    return {
        type: RESET_EDIT_REGISTRATION
    };
};

export const updateFamilyMember = member => {
    return {
        type: UPDATE_FAMILY_MEMBER,
        payload: member
    };
};

export const updateFamilyMemberSuccess = () => {
    return {
        type: UPDATE_FAMILY_MEMBER_SUCCESS
    };
};

export const updateFamilyMemberFailure = error => {
    return {
        type: UPDATE_FAMILY_MEMBER_FAILURE,
        payload: error
    };
};

export const resetUpdateFamilyMember = () => {
    return {
        type: RESET_UPDATE_FAMILY_MEMBER
    };
};

export const resettingRemoveRegistration = type => {
    return {
        type: RESET_REMOVE_REGISTRATION,
        payload: type
    };
};

export const removeRegistration = registration => {
    return {
        type: REMOVE_REGISTRATION,
        payload: registration
    };
};

export const removeRegistrationSuccess = () => {
    return {
        type: REMOVE_REGISTRATION_SUCCESS
    };
};

export const removeRegistrationFailure = error => {
    return {
        type: REMOVE_REGISTRATION_FAILURE,
        payload: error
    };
};

export const getRegistrationsByPropertyID = data => {
    return {
        type: GET_REGISTRATIONS_BY_PROPERTY_ID,
        payload: data
    };
};

export const getRegistrationsByPropertyIDFailure = error => {
    return {
        type: GET_REGISTRATIONS_BY_PROPERTY_ID_FAILURE,
        payload: error
    };
};

export const getRegistrationsByPropertyIDSuccess = data => {
    return {
        type: GET_REGISTRATIONS_BY_PROPERTY_ID_SUCCESS,
        payload: data
    };
};
