import PropTypes from 'prop-types';
import React, { createContext } from 'react';

import { connect } from 'react-redux';
import { login, logout, resetPassword } from 'store/slices/authSlice';

// project import
// import Loader from 'components/Loader';

export const AuthProvider = ({ authUser, children }) => {
    const AuthContext = createContext(null);
    return (
        <AuthContext.Provider
            value={{
                ...authUser,
                login,
                logout,
                resetPassword
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.node,
    authUser: PropTypes.object
};

const mapStateToProps = ({ auth }) => {
    const { authUser } = auth;
    return { authUser };
};

export default connect(mapStateToProps, {})(AuthProvider);
