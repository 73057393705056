import { initializeApp } from 'firebase/app';
import {
    getFirestore,
    Timestamp,
    collection,
    getDocs,
    setDoc,
    updateDoc,
    deleteDoc,
    query,
    doc,
    onSnapshot,
    limit,
    orderBy,
    startAt,
    getCountFromServer,
    getDoc,
    where,
    serverTimestamp,
    deleteField
} from 'firebase/firestore';

import {
    getAuth,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendEmailVerification,
    updateProfile,
    onAuthStateChanged,
    signOut,
    setPersistence,
    browserLocalPersistence,
    sendPasswordResetEmail,
    EmailAuthProvider,
    verifyPasswordResetCode,
    confirmPasswordReset,
    reauthenticateWithCredential,
    updatePassword,
    updateEmail
} from 'firebase/auth';

import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';

import { getFunctions, httpsCallable } from 'firebase/functions';
import { getDatabase } from 'firebase/database';

var config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

const app = initializeApp(config);

// Initialize Cloud Firestore and get a reference to the service; Initialize Auth; Initialize Cloud Functions
export const auth = getAuth(app);
setPersistence(auth, browserLocalPersistence);

export const db = getFirestore(app);
export const func = getFunctions(app);
export const rtdb = getDatabase(app);
export const storage = getStorage(app);

// Helpers
export const toFirebaseTimestamp = timestamp => {
    return new Timestamp(timestamp.seconds, timestamp.nanoseconds);
};

export const timeStampNow = () => {
    return serverTimestamp();
};

export const fsTimeStampNow = () => {
    return Timestamp.now();
};

export const timeStampNowSeconds = () => {
    return Timestamp.now().seconds;
};

export const fsTools = {
    collection,
    getDocs,
    setDoc,
    updateDoc,
    deleteDoc,
    query,
    doc,
    onSnapshot,
    limit,
    orderBy,
    startAt,
    getCountFromServer,
    getDoc,
    where,
    serverTimestamp,
    deleteField
};

export const authTools = {
    onAuthStateChanged,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendEmailVerification,
    updateProfile,
    signOut,
    sendPasswordResetEmail,
    EmailAuthProvider,
    verifyPasswordResetCode,
    confirmPasswordReset,
    reauthenticateWithCredential,
    getAuth,
    updatePassword,
    updateEmail
};

export const storageTools = {
    getDownloadURL,
    getStorage,
    ref,
    uploadBytes
};

export const funcTools = {
    httpsCallable
};
