// React
import React, { useState, useEffect, useCallback } from 'react';

// Assets
import { IdcardOutlined, CloseCircleOutlined } from '@ant-design/icons';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';

// Firebase
import { timeStampNowSeconds } from 'config/firebase';

// Packages
import { Controlled as ControlledZoom } from 'react-medium-image-zoom';
import { PatternFormat } from 'react-number-format';
import {
    Button,
    IconButton,
    Stack,
    Typography,
    List,
    ListItem,
    ListItemText
} from '@mui/material';

// Styles
import '../../styles/image-zoom.css';

const MemberDetails = ({
    member,
    setZoomActive,
    memberKeys,
    activePanel,
    setActivePanel,
    accessConfirm,
    setAccessConfirm,
    panels,
    propertyId,
    setOverride,
    handleGateAccess
}) => {
    const [zoomedId, setZoomedId] = useState(false);
    const [zoomedVehicle, setZoomedVehicle] = useState(false);
    const [access, setAccess] = useState(null);
    const [showAccess, setShowAccess] = useState(null);

    const vehicle = member?.vehicles?.length
        ? member.vehicles.filter(vehicle => vehicle.primary)[0]
        : null;

    const orgId = member.invited_at
        ? member.org_id
        : member?.properties[propertyId]?.org_id;

    const panelList = orgId ? panels[orgId] : [];

    const getAccess = useCallback((keys, member) => {
        if (keys && keys.length) {
            return keys.some(key => {
                const isValid = key => {
                    const today = new Date();
                    const startTime = new Date();
                    const endTime = new Date();
                    const nowMs = timeStampNowSeconds() * 1000;
                    const nowSec = timeStampNowSeconds();
                    const day = today.toLocaleString('en-US', { weekday: 'short' });
                    const dayStr = day.toLowerCase();
                    const beginSec = key.access_begins ? key.access_begins.seconds : null;
                    const expireSec = key.access_expires
                        ? key.access_expires.seconds
                        : null;

                    /**** Valid Day ****/
                    const includesDay = key?.access_days?.length
                        ? key.access_days.includes(dayStr)
                        : true;

                    /**** Valid Time Window by Day ****/
                    const start = key.access_start_time
                        ? startTime.setHours(
                              key.access_start_time.hours,
                              key.access_start_time.minutes,
                              0,
                              0
                          )
                        : key.access_start_time;

                    const end = key.access_end_time
                        ? endTime.setHours(
                              key.access_end_time.hours,
                              key.access_end_time.minutes,
                              0,
                              0
                          )
                        : key.access_end_time;

                    const inTimeWindow =
                        !start && !end ? true : nowMs >= start && nowMs <= end;

                    /**** Valid Time Window by Total Duration ****/
                    const inExpireWindow = !beginSec
                        ? !expireSec
                            ? true
                            : expireSec >= nowSec
                        : beginSec <= nowSec
                        ? expireSec
                            ? expireSec >= nowSec
                            : true
                        : false;

                    /**** Valid by Suspension ****/
                    const suspended = key.suspended;
                    return includesDay && inTimeWindow && inExpireWindow && !suspended;
                };
                const hasAccess = isValid(key) && !member.super_suspend;
                setShowAccess(true);
                setAccess(hasAccess);
                return hasAccess;
            });
        }
        setAccess(false);
        setShowAccess(true);
        return false;
    }, []);

    useEffect(() => {
        if (member) {
            if (member.invited_at) {
                setShowAccess(true);
            }
            if (memberKeys) {
                getAccess(memberKeys, member);
            }
        }
    }, [memberKeys, member, getAccess, setShowAccess]);

    useEffect(() => {
        if (showAccess) {
            setOverride(!access);
        }
    }, [access, showAccess, setOverride]);

    const handleZoom = useCallback(
        (type, state) => {
            setZoomActive(true);
            setZoomedId(type === 'id' ? state : false);
            setZoomedVehicle(type === 'id' ? false : state);
        },
        [setZoomActive]
    );

    const handleConfirmAccess = () => {
        if (accessConfirm) {
            handleGateAccess();
        } else {
            setAccessConfirm(true);
        }
    };

    const handlePanelSelect = panel => {
        const panelData = {
            orgId,
            id: panel.id,
            name: panel.name
        };
        setActivePanel({
            ...panelData
        });
    };

    if (!showAccess) return null;

    return (
        <Stack sx={{ p: 3 }}>
            <Stack
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    height: '4.4vh',
                    width: '100%'
                }}
            >
                <Stack
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        width: '100%'
                    }}
                >
                    <Typography
                        sx={{
                            width: '3.6vw',
                            fontWeight: 700
                        }}
                        variant="h6"
                        component="span"
                    >
                        Phone:
                    </Typography>
                    <Typography variant="h6" component="span">
                        <PatternFormat
                            value={member?.phone?.number ? member.phone.number : ''}
                            displayType={'text'}
                            format="###-###-####"
                            mask="_"
                        />
                    </Typography>
                </Stack>
                <Stack
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        width: '100%'
                    }}
                >
                    <Typography
                        sx={{
                            width: '3.6vw',
                            fontWeight: 700
                        }}
                        variant="h6"
                        component="span"
                    >
                        Email:
                    </Typography>
                    <Typography variant="h6" component="span">
                        {member.email}
                    </Typography>
                </Stack>
            </Stack>
            <Stack
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    height: '1.8vh',
                    width: '100%',
                    marginTop: '2vh'
                }}
            >
                <Typography
                    sx={{
                        fontStyle: 'italic',
                        textTransform: 'capitalize',
                        fontSize: '13px',
                        color:
                            access === false ||
                            (!memberKeys && !memberKeys?.length && !member?.invited_at)
                                ? 'rgba(230, 58, 58, 1)'
                                : ''
                    }}
                    variant="h6"
                    component="span"
                >
                    {member?.invited_at
                        ? 'Access Pending'
                        : access === false || (!memberKeys && !memberKeys?.length)
                        ? 'Access Prohibited'
                        : 'Access Allowed'}
                </Typography>
                <Typography
                    sx={{
                        fontStyle: 'italic',
                        textTransform: 'capitalize',
                        fontSize: '13px',
                        fontWeight: '600',
                        marginLeft: '1vw',
                        color:
                            access === false || (!memberKeys && !memberKeys?.length)
                                ? 'rgba(230, 58, 58, 1)'
                                : ''
                    }}
                    variant="h6"
                    component="span"
                >
                    {member.super_suspend ? '**Administrative Suspension**' : ''}
                </Typography>
            </Stack>
            <Stack
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    height: '21vh',
                    width: '100%',
                    marginTop: '3.5vh'
                }}
            >
                <Typography
                    sx={{
                        fontSize: '12px',
                        marginBottom: '1vh',
                        fontWeight: '700'
                    }}
                    component="span"
                    variant="body1"
                >
                    Identification:
                </Typography>
                <Stack
                    sx={{
                        height: '18.5vh',
                        width: '100%',
                        borderRadius: '2em'
                    }}
                >
                    {member?.photo_id ? (
                        <ControlledZoom isZoomed={zoomedId} onZoomChange={handleZoom}>
                            <img
                                style={{
                                    height: '18.5vh',
                                    width: '100%',
                                    borderRadius: '2em',
                                    objectFit: 'fill'
                                }}
                                src={member.photo_id}
                                loading="lazy"
                                alt={'identification'}
                            />
                            <div
                                style={{
                                    position: 'absolute',
                                    bottom: '1.5vh',
                                    right: '.75vw',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <IconButton
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '2.8vh',
                                        height: '2.8vh',
                                        backgroundColor: 'rgba(0, 0, 0, 0.36)',
                                        borderRadius: '8px',
                                        padding: 0,
                                        margin: 0
                                    }}
                                    onClick={() => handleZoom('id', !zoomedId)}
                                >
                                    {!zoomedId ? (
                                        <ZoomOutMapIcon
                                            style={{
                                                width: '75%',
                                                color: 'rgba(255,255,255,1)',
                                                padding: 0,
                                                margin: 0
                                            }}
                                        />
                                    ) : (
                                        <ZoomInMapIcon
                                            style={{
                                                width: '75%',
                                                color: 'rgba(255,255,255,1)',
                                                padding: 0,
                                                margin: 0
                                            }}
                                        />
                                    )}
                                </IconButton>
                            </div>
                        </ControlledZoom>
                    ) : (
                        <Stack
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                                width: '100%',
                                borderRadius: 5,
                                backgroundColor: 'rgba(208, 208, 208, 1)'
                            }}
                        >
                            <IdcardOutlined
                                style={{
                                    color: 'rgba(255,255,255,1)',
                                    fontSize: '5vw'
                                }}
                            />
                        </Stack>
                    )}
                </Stack>
            </Stack>
            <Stack
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    height: '21vh',
                    width: '100%',
                    marginTop: '1.5vh'
                }}
            >
                <Typography
                    sx={{
                        fontSize: '12px',
                        marginBottom: '1vh',
                        fontWeight: 700
                    }}
                    component="span"
                    variant="body1"
                >
                    Vehicle:
                </Typography>
                <Stack
                    sx={{
                        height: '18.5vh',
                        width: '100%',
                        borderRadius: '2em'
                    }}
                >
                    {vehicle?.photo_id ? (
                        <>
                            <ControlledZoom
                                isZoomed={zoomedVehicle}
                                onZoomChange={handleZoom}
                            >
                                <img
                                    style={{
                                        height: '18.5vh',
                                        width: '100%',
                                        borderRadius: '2em',
                                        objectFit: 'fill'
                                    }}
                                    src={vehicle.photo_id}
                                    loading="lazy"
                                    alt={'vehicle'}
                                />
                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: '1.5vh',
                                        right: '.75vw',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <IconButton
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '2.8vh',
                                            height: '2.8vh',
                                            backgroundColor: 'rgba(0, 0, 0, 0.36)',
                                            borderRadius: '8px',
                                            padding: 0,
                                            margin: 0
                                        }}
                                        onClick={() =>
                                            handleZoom('vehicle', !zoomedVehicle)
                                        }
                                    >
                                        {!zoomedId ? (
                                            <ZoomOutMapIcon
                                                style={{
                                                    width: '75%',
                                                    color: 'rgba(255,255,255,1)',
                                                    padding: 0,
                                                    margin: 0
                                                }}
                                            />
                                        ) : (
                                            <ZoomInMapIcon
                                                style={{
                                                    width: '75%',
                                                    color: 'rgba(255,255,255,1)',
                                                    padding: 0,
                                                    margin: 0
                                                }}
                                            />
                                        )}
                                    </IconButton>
                                </div>
                            </ControlledZoom>
                            <Stack style={{ marginTop: '1.5vh', marginBottom: '1vh' }}>
                                <Stack
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        alignItems: 'flex-start'
                                    }}
                                >
                                    <Stack
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start',
                                            width: '50%'
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: '700',
                                                textTransform: 'capitalize',
                                                width: '25%'
                                            }}
                                            component="span"
                                            variant="body1"
                                        >
                                            {`Make: `}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                textTransform: 'capitalize'
                                            }}
                                            component="span"
                                            variant="body1"
                                        >
                                            {vehicle.make}
                                        </Typography>
                                    </Stack>
                                    <Stack
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start',
                                            width: '50%'
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: '700',
                                                textTransform: 'capitalize',
                                                width: '25%'
                                            }}
                                            component="span"
                                            variant="body1"
                                        >
                                            {`Model: `}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                textTransform: 'capitalize'
                                            }}
                                            component="span"
                                            variant="body1"
                                        >
                                            {vehicle.model}
                                        </Typography>
                                    </Stack>
                                </Stack>
                                <Stack
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        alignItems: 'flex-start',
                                        marginTop: '.5vh'
                                    }}
                                >
                                    <Stack
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start',
                                            width: '50%'
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: '700',
                                                textTransform: 'capitalize',
                                                width: '25%'
                                            }}
                                            component="span"
                                            variant="body1"
                                        >
                                            {`Plate: `}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                textTransform: 'capitalize'
                                            }}
                                            component="span"
                                            variant="body1"
                                        >
                                            {`${vehicle.tag}/${vehicle.state}`}
                                        </Typography>
                                    </Stack>
                                    <Stack
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start',
                                            width: '50%'
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: '700',
                                                textTransform: 'capitalize',
                                                width: '25%'
                                            }}
                                            component="span"
                                            variant="body1"
                                        >
                                            {`Color: `}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                width: '50%',
                                                textTransform: 'capitalize'
                                            }}
                                            component="span"
                                            variant="body1"
                                        >
                                            {vehicle.color}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </>
                    ) : (
                        <Stack
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                                width: '100%',
                                borderRadius: 5,
                                backgroundColor: 'rgba(208, 208, 208, 1)'
                            }}
                        >
                            <DirectionsCarIcon
                                style={{
                                    color: 'rgba(255,255,255,1)',
                                    fontSize: '5vw'
                                }}
                            />
                        </Stack>
                    )}
                </Stack>
            </Stack>
            {accessConfirm && (
                <Stack
                    sx={{
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        marginTop: '11vh',
                        backgroundColor: 'rgba(58, 230, 137, 0.14)',
                        height: '15vh',
                        width: '100%',
                        borderRadius: '9px',
                        border: '1px solid rgba(58, 230, 137, 1)'
                    }}
                >
                    <Stack
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            backgroundColor: 'rgba(58, 230, 137, 1)',
                            height: '23%',
                            width: '100%',
                            borderTopLeftRadius: '9px',
                            borderTopRightRadius: '9px',
                            border: '1px solid rgba(58, 230, 137, 1)',
                            margin: 0,
                            padding: 0
                        }}
                    >
                        <Typography
                            sx={{
                                marginLeft: '4%',
                                fontSize: '12px',
                                fontWeight: '700',
                                textTransform: 'capitalize',
                                width: '80%'
                            }}
                            component="span"
                            variant="body1"
                        >
                            Select Gate
                        </Typography>
                        <IconButton
                            onClick={() => setAccessConfirm(false)}
                            aria-label="close"
                        >
                            <CloseCircleOutlined
                                style={{ color: 'rgba(255,255,255,1)' }}
                            />
                        </IconButton>
                    </Stack>
                    <List
                        sx={{
                            width: '100%',
                            overflowY: 'auto',
                            marginTop: '.5vh',
                            maxHeight: '80%'
                        }}
                    >
                        {panelList.map(panel => (
                            <ListItem
                                key={panel.id}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    height: '3.5vh',
                                    width: '95%',
                                    backgroundColor:
                                        activePanel?.id === panel.id
                                            ? 'rgba(129, 250, 185, 1)'
                                            : 'transparent',
                                    borderRadius: '16px',
                                    border:
                                        activePanel?.id !== panel.id
                                            ? '1px solid rgba(129, 250, 185, 1)'
                                            : 'none',
                                    paddingX: '4%',
                                    marginLeft: '2.5%',
                                    marginBottom: '.5vh'
                                }}
                                button={true}
                                disableGutters
                                onClick={() => {
                                    handlePanelSelect(panel);
                                }}
                            >
                                <ListItemText
                                    sx={{
                                        left: '4%'
                                    }}
                                    primary={
                                        <Typography
                                            sx={{
                                                display: 'block'
                                            }}
                                            component="span"
                                            variant="body1"
                                            color="text.primary"
                                        >
                                            {panel.name}
                                        </Typography>
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>
                </Stack>
            )}
            <Stack
                sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%'
                }}
            >
                <Button
                    sx={{
                        position: 'absolute',
                        bottom: '3vh',
                        width: '75%',
                        height: '4.5vh',
                        borderRadius: '26.5px',
                        '&:active::after': {
                            borderRadius: '26.5px'
                        },
                        fontSize: '0.9375rem',
                        fontWeight: '600',
                        backgroundColor:
                            access === false || (!memberKeys && !memberKeys?.length)
                                ? 'rgba(230, 58, 58, 1)'
                                : 'rgba(58, 230, 137, 1)'
                    }}
                    disabled={member.super_suspend || (accessConfirm && !activePanel)}
                    onClick={handleConfirmAccess}
                    variant="contained"
                >
                    {(access === false || (!memberKeys && !memberKeys?.length)) &&
                    !member.super_suspend
                        ? `${accessConfirm ? 'Confirm' : 'Grant'} Override Access`
                        : `${accessConfirm ? 'Confirm' : 'Grant'} Access`}
                </Button>
            </Stack>
        </Stack>
    );
};
export default MemberDetails;
