import {
    STORE_INTERCOM_COLLECTION,
    CREATE_PANEL_INTERCOM,
    CREATE_PANEL_INTERCOM_SUCCESS,
    CREATE_PANEL_INTERCOM_FAILURE,
    END_PANEL_INTERCOM,
    END_PANEL_INTERCOM_SUCCESS,
    END_PANEL_INTERCOM_FAILURE
} from './types';

export const storeIntercomCollection = coms => {
    return {
        type: STORE_INTERCOM_COLLECTION,
        payload: coms
    };
};

export const creatingPanelIntercom = com => {
    return {
        type: CREATE_PANEL_INTERCOM,
        payload: com
    };
};

export const createPanelIntercomSuccess = intercom => {
    return {
        type: CREATE_PANEL_INTERCOM_SUCCESS,
        payload: intercom
    };
};

export const createPanelIntercomFailure = error => {
    return {
        type: CREATE_PANEL_INTERCOM_FAILURE,
        payload: error
    };
};

export const endPanelIntercom = data => {
    return {
        type: END_PANEL_INTERCOM,
        payload: data
    };
};

export const endPanelIntercomSuccess = () => {
    return {
        type: END_PANEL_INTERCOM_SUCCESS
    };
};

export const endPanelIntercomFailure = error => {
    return {
        type: END_PANEL_INTERCOM_FAILURE,
        payload: error
    };
};
