import {
    all,
    fork,
    take,
    cancelled,
    takeLatest,
    put,
    call,
    cancel,
    race
} from 'redux-saga/effects';

import {
    LOGOUT_USER,
    ADD_ORG_PROPERITES,
    GET_PROPERTY_BY_ID,
    DELETE_PROPERITY,
    GET_ORG_PROPERITES_BY_SHORT_CODE,
    SET_ACTIVE_USER_ORG,
    GET_ORG_PROPERITES_COLLECTION
} from '../actions/types';

import { eventChannel } from 'redux-saga';

import {
    getOrgPropertiesCollectionSuccess,
    getOrgPropertiesCollectionFailure,
    getPropertyByIdCollectionSuccess,
    getPropertyByIdCollectionFailure,
    deletePropertySuccess,
    addOrgPropertiesSuccess,
    addOrgPropertiesFailure,
    deletePropertyFailure,
    getOrgPropertiesByShortCodeSuccess,
    getOrgPropertiesByShortCodeFailure
} from '../actions/Properties';

import { db, func } from 'config/firebase';
import { httpsCallable } from 'firebase/functions';

import {
    collection,
    deleteDoc,
    doc,
    getDoc,
    onSnapshot,
    setDoc
} from 'firebase/firestore';
import { openSnackbar } from 'store/actions/Snackbar';

import { log } from 'utils/Loggers';

const orgPropertiesCollectionRef = collection(db, 'org_properties');
const propertiesCollectionRef = collection(db, 'properties');
const gettingOrgProperties = httpsCallable(func, 'getOrgProperties');

export function* propertyCollectionWatch({ propertyId }) {
    let unsubscribePropertyCollectionData;

    const propertyDocRef = doc(db, 'properties', propertyId);

    const propertyCollectionChannel = eventChannel(emit => {
        unsubscribePropertyCollectionData = onSnapshot(propertyDocRef, propertyDoc => {
            if (propertyDoc.exists()) {
                emit(propertyDoc.data());
            } else {
                emit(new Error('Property not found'));
            }
        });

        return () => unsubscribePropertyCollectionData();
    });

    try {
        while (true) {
            const { userSignOut, propertyCollectionData, error } = yield race({
                userSignOut: take(LOGOUT_USER),
                propertyCollectionData: take(propertyCollectionChannel),
                error: take(propertyCollectionChannel)
            });

            if (userSignOut) {
                propertyCollectionChannel.close();
            } else if (error) {
                yield put(
                    openSnackbar({
                        open: true,
                        message: `Error: ${error.message}`,
                        variant: 'alert',
                        alert: {
                            color: 'error',
                            variant: 'outlined'
                        },
                        close: false
                    })
                );
                yield put(getPropertyByIdCollectionFailure(error));
            } else {
                yield put(getPropertyByIdCollectionSuccess(propertyCollectionData));
            }
        }
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: `Error: ${error.message}`,
                variant: 'alert',
                alert: {
                    color: 'error',
                    variant: 'outlined'
                },
                close: false
            })
        );
        yield put(getPropertyByIdCollectionFailure(error));
    } finally {
        unsubscribePropertyCollectionData();
        if (yield cancelled()) {
            propertyCollectionChannel.close();
            unsubscribePropertyCollectionData();
        }
    }
}

function createOrgPropertiesChannel(orgId) {
    return eventChannel(emit => {
        const unsubscribe = onSnapshot(
            doc(orgPropertiesCollectionRef, orgId),
            docSnap => {
                if (docSnap.exists()) {
                    emit(getOrgPropertiesCollectionSuccess(docSnap.data()));
                } else {
                    emit(
                        getOrgPropertiesCollectionFailure(new Error('No such document'))
                    );
                }
            },
            error => emit(getOrgPropertiesCollectionFailure(error))
        );

        return unsubscribe;
    });
}

export function* getAllOrgProperties({ payload }) {
    const orgId = payload.org_id;
    const channel = yield call(createOrgPropertiesChannel, orgId);
    const task = yield fork(function* () {
        try {
            while (true) {
                const action = yield take(channel);
                yield put(action);
            }
        } finally {
            if (yield cancelled()) {
                channel.close();
            }
        }
    });

    yield take([LOGOUT_USER]);
    yield cancel(task);
}

function* addOrgPropertiesToOrgPropertiesCollection(addedProperties, orgId) {
    const orgPropertiesRef = doc(db, 'org_properties', orgId);
    const orgPropertiesDoc = yield call(getDoc, orgPropertiesRef);

    if (orgPropertiesDoc.exists()) {
        const existingOrgProperties = orgPropertiesDoc.data();
        for (const property of addedProperties) {
            existingOrgProperties[property.id] = {
                id: property.id,
                address: property.full_address,
                status: property.status || null
            };
        }
        delete existingOrgProperties.undefined;

        yield call(setDoc, orgPropertiesRef, existingOrgProperties);
    } else {
        const newOrgProperties = {};

        for (const property of addedProperties) {
            newOrgProperties[property.id] = {
                id: property.id,
                address: property.full_address,
                status: property.status || null
            };
        }

        yield call(setDoc, orgPropertiesRef, newOrgProperties);
    }
}

function* addPropertiesToCollection(properties, orgId) {
    const addedProperties = yield all(
        properties.map(function* (property) {
            const propertyRef = doc(db, 'properties', `${property.id}`);

            const addressParts = [
                property.address_1,
                property.address_2,
                property.city,
                property.state
            ]
                .filter(part => part)
                .join(' ');

            const addressString = addressParts.trim();
            const propertyToAdd = {
                id: property.id,
                address_1: property.address_1,
                address_2: property?.address_2 || null,
                city: property?.city || null,
                state: property?.state || null,
                zip: property?.zip || null,
                org_id: orgId,
                status: property.status,
                longitude: property?.longitude || null,
                latitude: property?.latitude || null,
                full_address: addressString
            };

            yield call(setDoc, propertyRef, propertyToAdd);

            return propertyToAdd;
        })
    );

    return addedProperties;
}

export function* addOrgProperties({ payload }) {
    try {
        const { properties, orgId } = payload;

        const addedProperties = yield call(addPropertiesToCollection, properties, orgId);

        yield call(addOrgPropertiesToOrgPropertiesCollection, addedProperties, orgId);

        yield put(addOrgPropertiesSuccess(addedProperties));
        yield put(
            openSnackbar({
                open: true,
                message: `Added properties: <strong>${addedProperties
                    .map(property => property.full_address)
                    .join(', ')}</strong>`,
                variant: 'alert',
                alert: {
                    color: 'success',
                    variant: 'outlined'
                },
                close: false
            })
        );
    } catch (err) {
        yield put(addOrgPropertiesFailure(err));

        yield put(
            openSnackbar({
                open: true,
                message: `Error: ${err}`,
                variant: 'alert',
                alert: {
                    color: 'error',
                    variant: 'outlined'
                },
                close: false
            })
        );
    }
}

export function* removeOrgProperty({ payload }) {
    try {
        const { propertyId, orgId } = payload;

        yield call(deleteDoc, doc(propertiesCollectionRef, propertyId));

        const orgPropertiesRef = doc(db, 'org_properties', orgId);
        const orgPropertiesDoc = yield call(getDoc, orgPropertiesRef);

        if (orgPropertiesDoc.exists()) {
            const existingOrgProperties = orgPropertiesDoc.data();
            delete existingOrgProperties[propertyId];

            yield call(setDoc, orgPropertiesRef, existingOrgProperties);
        }

        yield put(deletePropertySuccess(propertyId));

        yield put(
            openSnackbar({
                open: true,
                message: `Property with ID ${propertyId} removed successfully.`,
                variant: 'alert',
                alert: {
                    color: 'success',
                    variant: 'outlined'
                },
                close: false
            })
        );
    } catch (err) {
        yield put(deletePropertyFailure(err));

        yield put(
            openSnackbar({
                open: true,
                message: `Error: ${err}`,
                variant: 'alert',
                alert: {
                    color: 'error',
                    variant: 'outlined'
                },
                close: false
            })
        );
    }
}

export function* getPropertyById(propertyId) {
    try {
        const propertyDocRef = doc(db, 'properties', propertyId);
        const propertyDoc = yield call(getDoc, propertyDocRef);

        if (propertyDoc.exists()) {
            return propertyDoc.data();
        }
    } catch (err) {
        log('Org Error: getting property by id (FS)', {
            error: err,
            propertyId
        });
    }
}

export function* getPropertyByIdCollection({ propertyId }) {
    try {
        const propertyDocRef = doc(db, 'properties', propertyId);
        const propertyDoc = yield call(getDoc, propertyDocRef);

        if (propertyDoc.exists()) {
            yield put(getPropertyByIdCollectionSuccess(propertyDoc.data()));
        } else {
            throw new Error('Property not found');
        }
    } catch (err) {
        yield put(
            openSnackbar({
                open: true,
                message: `Error: ${err}`,
                variant: 'alert',
                alert: {
                    color: 'error',
                    variant: 'outlined'
                },
                close: false
            })
        );
        yield put(getPropertyByIdCollectionFailure(err));
    }
}

function* getOrgPropertiesByShortCode({ payload }) {
    try {
        const { data } = yield call(gettingOrgProperties, {
            shortCode: payload
        });

        if (data?.properties) {
            yield put(getOrgPropertiesByShortCodeSuccess(data.properties));
        } else {
            throw new Error('Properties not found');
        }
    } catch (err) {
        yield put(
            openSnackbar({
                open: true,
                message: `Error: ${err}`,
                variant: 'alert',
                alert: {
                    color: 'error',
                    variant: 'outlined'
                },
                close: false
            })
        );
        yield put(getOrgPropertiesByShortCodeFailure(err));
        log(
            'Registration Error: getting properties for registration form by org shortCode (CF)',
            {
                error: err,
                shortCode: payload
            }
        );
    }
}

///////////// Action Creators For Root Saga //////////////////

export function* getOrgPropertiesCollection() {
    yield takeLatest(SET_ACTIVE_USER_ORG, getAllOrgProperties);
}

export function* getOrgProperties() {
    yield takeLatest(GET_ORG_PROPERITES_COLLECTION, getAllOrgProperties);
}

export function* getRegistrationOrgProperties() {
    yield takeLatest(GET_ORG_PROPERITES_BY_SHORT_CODE, getOrgPropertiesByShortCode);
}

export function* getProperty() {
    yield takeLatest(GET_PROPERTY_BY_ID, propertyCollectionWatch);
}

export function* addOrgPropertiesList() {
    yield takeLatest(ADD_ORG_PROPERITES, addOrgProperties);
}

export function* deleteProperty() {
    yield takeLatest(DELETE_PROPERITY, removeOrgProperty);
}

export default function* rootSaga() {
    yield all([
        fork(getOrgPropertiesCollection),
        fork(addOrgPropertiesList),
        fork(getProperty),
        fork(deleteProperty),
        fork(getRegistrationOrgProperties),
        fork(getOrgProperties)
    ]);
}
