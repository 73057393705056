import React, { lazy } from 'react';

// project import
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';

const AppStoreRedirect = Loadable(
    lazy(() => import('pages/main-pages/AppStoreRedirect'))
);

// ==============================|| AUTH ROUTING ||============================== //

const MiscRoutes = {
    path: '/',
    children: [
        {
            path: '/',
            element: <CommonLayout />,
            children: [
                {
                    path: 'app',
                    element: <AppStoreRedirect />
                }
            ]
        }
    ]
};

export default MiscRoutes;
