import {
    GET_PROPERTY_MEMBERS,
    GET_PROPERTY_MEMBERS_SUCCESS,
    GET_PROPERTY_MEMBERS_FAILURE,
    GET_INVITED_PROPERTY_MEMBERS_SUCCESS,
    GET_INVITED_PROPERTY_MEMBERS_FAILURE,
    GET_MEMBER_KEYS,
    GET_MEMBER_KEYS_SUCCESS,
    GET_MEMBER_KEYS_FAILURE,
    RESET_PROPERTY_MEMBERS,
    RESET_MEMBER_KEYS,
    ALLOW_GATE_ACCESS_SUCCESS,
    ALLOW_GATE_ACCESS_FAILURE,
    ALLOW_GATE_ACCESS,
    DISALLOW_GATE_ACCESS_SUCCESS,
    DISALLOW_GATE_ACCESS_FAILURE,
    DISALLOW_GATE_ACCESS
} from '../actions/types';

const INITIAL_STATE = {
    error: null,
    loading: null,
    members: null,
    invitedMembers: null,
    memberId: null,
    memberKeys: null,
    propertyId: null,
    accessGranted: false,
    accessDenied: false
};

const virtualGuardReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_PROPERTY_MEMBERS:
            return {
                ...state,
                loading: true,
                propertyId: action.payload.propertyId
            };
        case GET_PROPERTY_MEMBERS_SUCCESS:
            return {
                ...state,
                loading: false,
                members: action.payload
            };
        case GET_INVITED_PROPERTY_MEMBERS_SUCCESS:
            return {
                ...state,
                loading: false,
                invitedMembers: action.payload
            };
        case GET_PROPERTY_MEMBERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case GET_INVITED_PROPERTY_MEMBERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case GET_MEMBER_KEYS:
            return {
                ...state,
                loading: true,
                memberId: action.payload.memberId
            };
        case GET_MEMBER_KEYS_SUCCESS:
            return {
                ...state,
                loading: false,
                memberKeys: action.payload
            };
        case GET_MEMBER_KEYS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case RESET_PROPERTY_MEMBERS:
            return {
                ...state,
                error: null,
                loading: null,
                members: null,
                invitedMembers: null,
                propertyId: null,
                accessGranted: false,
                accessDenied: false
            };
        case RESET_MEMBER_KEYS:
            return {
                ...state,
                error: null,
                loading: null,
                memberId: null,
                memberKeys: null
            };
        case ALLOW_GATE_ACCESS:
            return {
                ...state,
                accessGranted: false,
                loading: true,
                error: false
            };
        case ALLOW_GATE_ACCESS_SUCCESS:
            return {
                ...state,
                accessGranted: true,
                loading: false,
                error: false
            };
        case ALLOW_GATE_ACCESS_FAILURE:
            return {
                ...state,
                accessGranted: false,
                loading: false,
                error: action.payload
            };
        case DISALLOW_GATE_ACCESS:
            return {
                ...state,
                accessDenied: false,
                loading: true,
                error: false
            };
        case DISALLOW_GATE_ACCESS_SUCCESS:
            return {
                ...state,
                accessDenied: true,
                loading: false,
                error: false
            };
        case DISALLOW_GATE_ACCESS_FAILURE:
            return {
                ...state,
                accessDenied: false,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
};

export default virtualGuardReducer;
