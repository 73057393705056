// React
import React from 'react';
import PropTypes from 'prop-types';

// Assets
import { ExclamationCircleOutlined } from '@ant-design/icons';

// Components
import Avatar from 'components/@extended/Avatar';
import { PopupTransition } from 'components/@extended/Transitions';

// Packages
import { Button, Dialog, DialogContent, Stack, Typography } from '@mui/material';

const SendInvitesConfirmModal = ({
    title,
    text,
    open,
    onClose,
    onConfirm,
    confirmButtonText,
    cancelButtonText
}) => {
    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            keepMounted
            TransitionComponent={PopupTransition}
            maxWidth="xs"
            aria-labelledby="item-delete-title"
            aria-describedby="item-delete-description"
        >
            <DialogContent sx={{ mt: 2, my: 1 }}>
                <Stack alignItems="center" spacing={3.5}>
                    <Avatar
                        color="warning"
                        sx={{ width: 72, height: 72, fontSize: '1.75rem' }}
                    >
                        <ExclamationCircleOutlined />
                    </Avatar>
                    <Stack spacing={2}>
                        <Typography variant="h4" align="center">
                            {title}
                        </Typography>
                        <Typography align="center">{text}</Typography>
                    </Stack>

                    <Stack direction="row" spacing={2} sx={{ width: 1 }}>
                        <Button
                            fullWidth
                            onClick={() => onClose(false)}
                            color="error"
                            variant="outlined"
                        >
                            {cancelButtonText || 'No, Cancel'}
                        </Button>
                        <Button
                            fullWidth
                            color="primary"
                            variant="contained"
                            onClick={onConfirm}
                            autoFocus
                        >
                            {confirmButtonText || 'Yes, send it!'}
                        </Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default SendInvitesConfirmModal;

SendInvitesConfirmModal.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    cancelButtonText: PropTypes.string,
    confirmButtonText: PropTypes.string,
    open: PropTypes.bool,
    onConfirm: PropTypes.func
};
