import {
    all,
    fork,
    takeLatest,
    take,
    cancelled,
    race,
    put,
    call,
    select
} from 'redux-saga/effects';

import {
    GET_CALLS_COLLECTION,
    LOGOUT_USER,
    FIELD_PANEL_CALL,
    END_PANEL_CALL
} from 'store/actions/types';

import { eventChannel } from 'redux-saga';

// import { db, timeStampNow, rtdb } from '../../config/Firebase';
import { db } from 'config/firebase';

import {
    collection,
    doc,
    onSnapshot,
    query,
    serverTimestamp,
    updateDoc,
    where
} from 'firebase/firestore';

import {
    storeCallsCollection,
    fieldPanelCallSuccess,
    fieldPanelCallFailure,
    endPanelCallSuccess,
    endPanelCallFailure
} from 'store/actions/Calls';

import { log } from 'utils/Loggers';
import { closeSnackbar, openSnackbar } from 'store/actions/Snackbar';
import * as selectors from './Selectors';
import { VIRTUAL_GUARD } from 'utils/route-constants';

const callsCollectionRef = collection(db, 'calls');

////////////// Get User Profile ////////////////

export function* callsCollectionWatch(user) {
    let unsubscribeCallsCollectionData;

    const isVirtualGuard = user.type === 'virtual_guard';
    const orgIds = Object.keys(user.orgs);

    const callsCollectionQuery = isVirtualGuard
        ? query(
              callsCollectionRef,
              where('org_id', 'in', orgIds),
              where('virtual_guard', '==', true),
              where('status', 'in', ['pending', 'active'])
          )
        : query(
              callsCollectionRef,
              where('org_id', '==', user.active_org_id),
              where('active_station_roll', '==', user.active_station_id),
              where('status', 'in', ['pending', 'active']),
              where('virtual_guard', '!=', true)
          );

    const callsCollectionChannel = eventChannel(emit => {
        unsubscribeCallsCollectionData = onSnapshot(
            callsCollectionQuery,
            querySnapshot => {
                const pending = [];
                const active = [];
                var calls = {};

                querySnapshot.forEach(doc => {
                    const data = doc.data();
                    if (data.status === 'pending') {
                        pending.push(data);
                    } else {
                        active.push(data);
                    }
                });

                calls = { active, pending };
                emit(calls);
            }
        );

        return unsubscribeCallsCollectionData;
    });

    try {
        while (true) {
            const { userSignOut, callsCollectionData } = yield race({
                userSignOut: take(LOGOUT_USER),
                callsCollectionData: take(callsCollectionChannel)
            });

            if (userSignOut) {
                callsCollectionChannel.close();
            } else {
                yield put(storeCallsCollection(callsCollectionData));

                const currentRoute = yield select(selectors._getCurrentRoute);
                const isSnackbarOpen = yield select(selectors._getSnackbarOpen);
                const isGuardAlert = yield select(selectors._getSnackbarIsGueard);

                if (
                    callsCollectionData?.pending?.length > 0 &&
                    currentRoute !== VIRTUAL_GUARD
                ) {
                    yield put(
                        openSnackbar({
                            open: true,
                            message:
                                'You have one or more calls waiting for your attention.',
                            variant: 'alert',
                            alert: {
                                color: 'success',
                                variant: 'outlined'
                            },
                            isGuardAlert: true,
                            autoHideDuration: null,
                            close: false
                        })
                    );
                }

                if (
                    isSnackbarOpen &&
                    isGuardAlert &&
                    callsCollectionData?.pending?.length === 0
                ) {
                    yield put(closeSnackbar());
                }
            }
        }
    } catch (error) {
        log('Watching Calls: error fetching calls collection data (FS)', {
            error,
            user
        });
    } finally {
        unsubscribeCallsCollectionData();
        if (yield cancelled()) {
            callsCollectionChannel.close();
            unsubscribeCallsCollectionData();
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////// Fielding Panel Call ///////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const fieldingPanelCallRequest = async ({ panelCall, userData }) => {
    try {
        const docRef = doc(callsCollectionRef, panelCall.id);
        await updateDoc(docRef, {
            fielded_by: userData.uid,
            field_start: serverTimestamp(),
            status: 'active'
        });

        return { res: true };
    } catch (error) {
        return { error };
    }
};

export function* fieldingPanelCall({ payload }) {
    const { panelCall, userData } = payload;
    const { res, error } = yield call(() =>
        fieldingPanelCallRequest({ panelCall, userData })
    );

    if (res) {
        yield put(fieldPanelCallSuccess());
    } else {
        yield put(fieldPanelCallFailure(error));

        log('Fielding Calls: error updating call data on fielding panel call (FS)', {
            error,
            userData,
            panelCall
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Ending Panel Call ////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const endingPanelCallRequest = async ({ panelCall, userData }) => {
    try {
        const docRef = doc(callsCollectionRef, panelCall.id);
        await updateDoc(docRef, {
            field_end: serverTimestamp(),
            status: 'completed'
        });

        return { res: true };
    } catch (error) {
        return { error };
    }
};

export function* endingPanelCall({ payload }) {
    const { panelCall, userData } = payload;
    const { res, error } = yield call(() =>
        endingPanelCallRequest({ panelCall, userData })
    );

    if (res) {
        yield put(endPanelCallSuccess());
    } else {
        yield put(endPanelCallFailure(error));
        log('Ending Calls: error updating call data on ending panel call (FS)', {
            error,
            panelCall,
            userData
        });
    }
}

///////////// Action Creators For Root Saga //////////////////

export function* getCallCollection() {
    yield takeLatest(GET_CALLS_COLLECTION, callsCollectionWatch);
}

export function* fieldPanelCall() {
    yield takeLatest(FIELD_PANEL_CALL, fieldingPanelCall);
}

export function* endPanelCall() {
    yield takeLatest(END_PANEL_CALL, endingPanelCall);
}

export default function* rootSaga() {
    yield all([fork(getCallCollection), fork(fieldPanelCall), fork(endPanelCall)]);
}
