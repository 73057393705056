import { ADD_ACCESS_KEYS_DOCUMENT, GET_ACCESS_KEYS_DOCUMENT } from '../actions/types';

import { all, fork, takeLatest, put, call } from 'redux-saga/effects';

import { db, timeStampNow } from 'config/firebase';

import {
    addAccesKeysDocFailure,
    addAccesKeysDocSuccess,
    getAccesKeysDocFailure,
    getAccesKeysDocSuccess
} from '../actions/AccessKeys';
import {
    collection,
    deleteDoc,
    doc,
    getDoc,
    setDoc,
    updateDoc
} from '@firebase/firestore';

const accessKeysCollectionRef = collection(db, 'access_keys');

export function* getAccessKeysById({ payload }) {
    try {
        const accessKeyRef = doc(accessKeysCollectionRef, payload);
        const docSnapshot = yield call(getDoc, accessKeyRef);

        if (docSnapshot.exists()) {
            const key = docSnapshot.data();
            yield put(getAccesKeysDocSuccess(key));
        } else {
            yield put(getAccesKeysDocFailure('Document does not exist'));
        }
    } catch (err) {
        console.error(err);
        yield put(getAccesKeysDocFailure(err));
    }
}

export function* deleteAccessKey({ payload }) {
    try {
        const accessKeyRef = doc(accessKeysCollectionRef, payload);
        yield call(deleteDoc, accessKeyRef);
        return true;
    } catch (error) {
        console.error('Access key deletion error:', error);
        return false;
    }
}

export function* newAccessKey(data) {
    try {
        const newAccessKeyRef = doc(accessKeysCollectionRef);

        const newAccessKeyDocument = {
            ...data,
            access_created: timeStampNow(),
            key_id: newAccessKeyRef.id,
            plates: [],
            vehicles: [],
            favorite: false,
            suspended: false,
            validated: false,
            active: true
        };

        yield setDoc(newAccessKeyRef, newAccessKeyDocument);
        return { res: newAccessKeyDocument };
    } catch (error) {
        console.error('Error: creating One Time Access Code', error);
        throw error;
    }
}

export function* editAccessKey(data) {
    try {
        const accessKeyRef = doc(accessKeysCollectionRef, data.key_id);

        yield updateDoc(accessKeyRef, data);

        return { res: data };
    } catch (error) {
        console.error('Error: editing reservation', error);
        throw error;
    }
}

export function* addAccessKey({ payload }) {
    try {
        const {
            access_days,
            access_end_time,
            access_begins,
            access_expires,
            access_start_time,
            address,
            company_name,
            consumer_id,
            creator_first_name,
            creator_id,
            creator_last_name,
            email,
            favorite,
            first_name,
            last_name,
            org_id,
            phone,
            phone_number,
            role,
            suspended,
            validated,
            photo_id,
            property_id,
            type,
            active
        } = payload;

        const accessKeyDocRef = doc(accessKeysCollectionRef);
        const accessKeyId = accessKeyDocRef.id;

        const newAccessKey = {
            access_created: timeStampNow(),
            access_days,
            access_end_time,
            access_expires: access_expires,
            access_begins: access_begins,
            access_start_time,
            address,
            company_name,
            consumer_id,
            creator_first_name,
            creator_id,
            creator_last_name,
            email,
            favorite,
            first_name,
            key_id: accessKeyId,
            photo_id,
            phone_number,
            last_name,
            property_id,
            org_id,
            phone,
            plates: [],
            role,
            suspended,
            validated,
            active,
            type,
            vehicles: []
        };

        yield call(setDoc, accessKeyDocRef, newAccessKey);

        yield put(addAccesKeysDocSuccess());
        return newAccessKey;
    } catch (error) {
        console.error('Error creating access key document:', error);
        yield put(addAccesKeysDocFailure(`${error}`));
        return false;
    }
}

export function* suspendAccess({ payload: accessKey }) {
    try {
        const accessKeyRef = doc(accessKeysCollectionRef, accessKey);
        yield updateDoc(accessKeyRef, { suspended: true });
    } catch (error) {
        console.error('Error suspending access:', error);
    }
}

////////////////////////

export function* createAccess() {
    yield takeLatest(ADD_ACCESS_KEYS_DOCUMENT, addAccessKey);
}

export function* getAccessKey() {
    yield takeLatest(GET_ACCESS_KEYS_DOCUMENT, getAccessKeysById);
}

export default function* rootSaga() {
    yield all([fork(getAccessKey), fork(createAccess)]);
}
