import {
    GENERATE_LIVEKIT_TOKEN,
    GENERATE_LIVEKIT_TOKEN_SUCCESS,
    GENERATE_LIVEKIT_TOKEN_FAILURE,
    RESET_LIVEKIT_TOKEN
} from '../actions/types';

export const generateLiveKitToken = roomInfo => {
    return {
        type: GENERATE_LIVEKIT_TOKEN,
        payload: roomInfo
    };
};

export const generateLiveKitTokenSuccess = token => {
    return {
        type: GENERATE_LIVEKIT_TOKEN_SUCCESS,
        payload: token
    };
};

export const generateLiveKitTokenFailure = error => {
    return {
        type: GENERATE_LIVEKIT_TOKEN_FAILURE,
        payload: error
    };
};

export const resetLiveKitToken = () => {
    return {
        type: RESET_LIVEKIT_TOKEN
    };
};
