import {
    EDIT_REGISTRATION,
    ADD_NEW_REGISTRATION,
    ADD_NEW_OCCUPANT_REGISTRATION,
    GET_REGISTRATIONS_BY_PROPERTY_ID,
    REMOVE_REGISTRATION,
    UPDATE_FAMILY_MEMBER
} from '../actions/types';

import { all, fork, takeLatest, put, call, select } from 'redux-saga/effects';

import { db, func, rtdb, timeStampNow } from 'config/firebase';

import {
    addingNewRegistrationSuccess,
    addingNewRegistrationFailure,
    getRegistrationsByPropertyIDSuccess,
    getRegistrationsByPropertyIDFailure,
    removeRegistrationSuccess,
    removeRegistrationFailure,
    editRegistrationSuccess,
    editRegistrationFailure,
    updateFamilyMemberSuccess,
    updateFamilyMemberFailure
} from '../actions/Registration';
import { httpsCallable } from 'firebase/functions';
import {
    equalTo,
    onValue,
    orderByChild,
    push,
    query as dbQuery,
    ref,
    remove,
    set
} from 'firebase/database';
import {
    Timestamp,
    addDoc,
    collection,
    doc,
    getDoc,
    getDocs,
    query,
    updateDoc,
    where
} from 'firebase/firestore';
import * as selectors from './Selectors';

const createNewRegistration = httpsCallable(func, 'createNewRegistration');

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////// Fetch Registrations ///////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* getRegistrationsByPropertyId({ payload }) {
    const { orgId, propertyId } = payload;
    try {
        const registrationsRef = ref(rtdb, `registration/${orgId}`);
        const registrationsQuery = dbQuery(
            registrationsRef,
            orderByChild('property_id'),
            equalTo(propertyId)
        );

        const snapshot = yield call(
            () =>
                new Promise((resolve, reject) => {
                    onValue(registrationsQuery, resolve, reject, { onlyOnce: true });
                })
        );

        const invitedMembers = [];
        snapshot.forEach(childSnapshot => {
            invitedMembers.push(childSnapshot.val());
        });

        if (invitedMembers.length > 0) {
            yield put(getRegistrationsByPropertyIDSuccess(invitedMembers));
        } else {
            throw new Error('No registrations found for the given property ID');
        }
    } catch (err) {
        yield put(getRegistrationsByPropertyIDFailure(err));
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////// Add New Registration ///////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

function* addOccupantRegistration({ payload }) {
    const { member, shortCode, propertyId, orgId } = payload;
    const { occupant, ...memberWithoutOccupant } = member;

    const members = [memberWithoutOccupant];
    if (occupant !== null) {
        members.push({ ...occupant });
    }

    try {
        const { data } = yield call(createNewRegistration, {
            members,
            shortCode,
            orgId,
            propertyId
        });

        if (data?.res) {
            yield put(addingNewRegistrationSuccess(data.memmberErrors));
        } else {
            console.log(data.error, 'end error');
            yield put(addingNewRegistrationFailure(data));
            // Error Handling for sentry with put and maybe UI message
            console.log(`Error: error adding new registration (RTDB)`, {
                error: data.error
            });
        }
    } catch (error) {
        console.log(error, 'addError');
        yield put(addingNewRegistrationFailure(error));
    }
}

function* addRegistration({ payload }) {
    const { member, propertyId, orgId } = payload;
    try {
        const { email } = member;
        let userUnique = true;
        let inviteUnique = true;
        let inviteError = null;
        let userError = null;

        const userQuery = query(collection(db, 'users'), where('email', '==', email));
        const userQuerySnapshot = yield call(getDocs, userQuery);
        if (!userQuerySnapshot.empty) {
            const userDocs = userQuerySnapshot.docs;
            for (const doc of userDocs) {
                const user = doc.data();
                if (user?.properties && user?.properties[propertyId]) {
                    userError = `This information is already associated with a member with ${email} email of this community. Please contact your community manager for further assistance.`;
                    userUnique = false;
                    break;
                } else {
                    yield call(
                        updateFamilyMember({
                            member,
                            propertyId,
                            orgId,
                            userId: user.uid
                        })
                    );
                }
            }
        }
        console.log('userUnique', userUnique);

        if (userUnique) {
            const registrationsRef = ref(rtdb, `registration/${orgId}`);
            const registrationsQuery = dbQuery(
                registrationsRef,
                orderByChild('email'),
                equalTo(email)
            );

            const snapshot = yield call(
                () =>
                    new Promise((resolve, reject) => {
                        onValue(registrationsQuery, resolve, reject, { onlyOnce: true });
                    })
            );

            if (snapshot.exists()) {
                inviteError = `The email ${email} is already associated with an invitation request for this community. Once approved by the community manager, you should receive your invitation to sign up and create your account. If this is the first time you have requested an invitation to this community and you are seeing this message, please contact your community manager for further assistance.`;
                inviteUnique = false;
            } else {
                const inviteQuery = query(
                    collection(db, 'guest_invites'),
                    where('email', '==', email),
                    where('org_id', '==', orgId),
                    where('role', '==', 'resi')
                );
                const inviteQuerySnapshot = yield call(getDocs, inviteQuery);

                if (!inviteQuerySnapshot.empty) {
                    inviteError = `The email ${email} is already associated with an invitation for this community. If you have not received your invitation previously, please contact your community manager for further assistance.`;
                    inviteUnique = false;
                }
            }
        }

        if (userUnique && inviteUnique) {
            const registerOrgRef = ref(rtdb, `registration/${orgId}`);
            const newRegisterRef = push(registerOrgRef);
            const registerKey = newRegisterRef.key;

            yield call(set, newRegisterRef, {
                ...member,
                id: registerKey,
                property_id: propertyId,
                registered_at: Timestamp.now().seconds
            });

            yield put(addingNewRegistrationSuccess());
        } else {
            yield put(
                addingNewRegistrationFailure(
                    userError ||
                        inviteError ||
                        'There was an error processing your request. Please try again later.'
                )
            );
        }
    } catch (error) {
        console.error(error);
        yield put(addingNewRegistrationFailure(error));
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////// Edit Registration ///////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

function* editRegistration({ payload }) {
    const { memberData, orgId, registrationId } = payload;

    try {
        const registerOrgRef = ref(rtdb, `registration/${orgId}/${registrationId}`);

        const existingRegistrationSnapshot = yield call(
            () =>
                new Promise((resolve, reject) => {
                    onValue(registerOrgRef, resolve, reject, { onlyOnce: true });
                })
        );

        if (!existingRegistrationSnapshot.exists()) {
            yield put(editRegistrationFailure('Registration not found.'));
            return;
        }

        const existingRegistration = existingRegistrationSnapshot.val();

        const updatedRegistration = {
            ...existingRegistration,
            ...memberData
        };

        console.log('updatedRegistration', updatedRegistration);
        yield call(set, registerOrgRef, updatedRegistration);

        yield put(editRegistrationSuccess());
    } catch (error) {
        console.error(error);
        yield put(editRegistrationFailure(error));
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////// Update Family Member //////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

function* updateFamilyMember({ payload }) {
    const { memberData, userId, orgId, propertyId } = payload;
    console.log('payload', payload);
    try {
        const orgRef = doc(db, 'orgs', orgId);

        const orgSnapshot = yield call(getDoc, orgRef);
        if (!orgSnapshot.exists()) {
            yield put(updateFamilyMemberFailure('Org not found.'));
            return;
        }

        const org = orgSnapshot.data();

        const userRef = doc(db, 'users', userId);

        const userSnapshot = yield call(getDoc, userRef);
        if (!userSnapshot.exists()) {
            yield put(updateFamilyMemberFailure('User not found.'));
            return;
        }

        const existingUser = userSnapshot.data();

        if (
            existingUser?.properties[propertyId].primary &&
            existingUser?.properties[propertyId].type === 'member'
        ) {
            yield put(
                updateFamilyMemberFailure(
                    'You can not update another primary family member.'
                )
            );
            return;
        }

        const propertyRef = doc(db, 'properties', propertyId);
        const propertySnapshot = yield call(getDoc, propertyRef);
        const property = propertySnapshot.data();

        let updatedProperties = null;
        if (!existingUser?.properties[propertyId]) {
            updatedProperties = {
                ...existingUser.properties,
                [propertyId]: {
                    type: memberData.member_type,
                    permissions: memberData.permissions,
                    primary: memberData.member_type === 'member',
                    org_id: orgId,
                    notifications: true,
                    address: {
                        address_1: property.address_1,
                        address_2: property.address_2,
                        city: property.city,
                        full_address: property.full_address,
                        state: property.state,
                        zip: property.zip
                    }
                }
            };
        } else {
            updatedProperties = {
                ...existingUser.properties,
                [propertyId]: {
                    ...existingUser.properties?.[propertyId],
                    type: memberData.member_type,
                    permissions: memberData.permissions
                }
            };
        }

        const updatedUserData = {
            first_name: memberData.first_name,
            last_name: memberData.last_name,
            phone: memberData.phone
        };

        const updatedUser = {
            ...updatedUserData,
            properties: updatedProperties
        };
        console.log('updatedUser', updatedUser);
        yield call(updateDoc, userRef, updatedUser);

        const orgMemberRef = ref(rtdb, `orgs/${orgId}/members/${userId}`);

        const existingOrgMemberSnapshot = yield call(
            () =>
                new Promise((resolve, reject) => {
                    onValue(orgMemberRef, resolve, reject, { onlyOnce: true });
                })
        );

        let updatedOrgMember = null;
        if (!existingOrgMemberSnapshot.exists()) {
            const streetNumberMatch = property.address_1.match(/^\d+/);
            const streetNumber = streetNumberMatch ? streetNumberMatch[0] : null;
            updatedOrgMember = {
                access_group: 1,
                access_key: true,
                first_name: memberData.first_name,
                last_name: memberData.last_name,
                phone: `${memberData.phone.code}${memberData.phone.number}`,
                directory: memberData.permissions.calls,
                role: 'resi',
                uid: userId,
                email: existingUser.email,
                suspended: false,
                property_id: propertyId,
                address: property.address_1,
                street_num: streetNumber
            };
        } else {
            const existingMember = existingOrgMemberSnapshot.val();

            updatedOrgMember = {
                ...existingMember,
                first_name: memberData.first_name,
                last_name: memberData.last_name,
                phone: `${memberData.phone.code}${memberData.phone.number}`,
                directory: memberData.permissions.calls
            };
        }

        yield call(set, orgMemberRef, updatedOrgMember);

        const accessKeysRef = collection(db, 'access_keys');
        const accessKeysQuery = query(accessKeysRef, where('consumer_id', '==', userId));
        const accessKeysSnapshot = yield call(getDocs, accessKeysQuery);

        if (accessKeysSnapshot.empty) {
            const userData = yield select(selectors._userData);

            const accessKeyData = {
                access_begins: timeStampNow(),
                access_days: null,
                access_end_time: null,
                access_expires: null,
                access_start_time: null,
                access_created: timeStampNow(),
                address: {
                    address_1: property.address_1,
                    address_2: property.address_2,
                    city: property.city,
                    full_address: property.full_address,
                    state: property.state,
                    zip: property.zip,
                    latitude: property.latitude,
                    longitude: property.longitude
                },
                company_name: null,
                consumer_id: userId,
                creator_first_name: userData.first_name,
                creator_id: userData.uid,
                creator_last_name: userData.last_name,
                email: existingUser.email,
                first_name: memberData.first_name,
                last_name: memberData.last_name,
                org_id: orgId,
                org_name: org.org_name,
                phone: memberData.phone,
                phone_number: `${memberData.phone.code}${memberData.phone.number}`,
                property_id: propertyId,
                role: 'resi',
                favorite: false,
                suspended: false,
                validated: false,
                active: true,
                type: 'member',
                photo_id: null,
                plates: [],
                vehicles: []
            };

            yield call(addDoc, accessKeysRef, accessKeyData);
        } else {
            for (const doc of accessKeysSnapshot.docs) {
                const accessKeyRef = doc.ref;
                const updatedAccessKeyData = {
                    first_name: memberData.first_name,
                    last_name: memberData.last_name,
                    phone: memberData.phone
                };
                yield call(updateDoc, accessKeyRef, updatedAccessKeyData);
            }
        }

        yield put(updateFamilyMemberSuccess());
    } catch (error) {
        console.error(error);
        yield put(updateFamilyMemberFailure(error.message || error));
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////// Remove Registration ///////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

function* removingRegistration({ payload }) {
    const { member, orgId } = payload;
    console.log('payload', payload);
    try {
        const memberRef = ref(rtdb, `registration/${orgId}/${member.id}`);
        yield call(() => remove(memberRef));

        yield put(removeRegistrationSuccess());
    } catch (error) {
        console.error('Error removing registration:', error);
        yield put(removeRegistrationFailure(error));
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Action Creators For Root Saga ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* addingOccupantRegistration() {
    yield takeLatest(ADD_NEW_OCCUPANT_REGISTRATION, addOccupantRegistration);
}

export function* addingRegistration() {
    yield takeLatest(ADD_NEW_REGISTRATION, addRegistration);
}

export function* editingRegistration() {
    yield takeLatest(EDIT_REGISTRATION, editRegistration);
}

export function* getRegistrationsForProperty() {
    yield takeLatest(GET_REGISTRATIONS_BY_PROPERTY_ID, getRegistrationsByPropertyId);
}

export function* removeRegistration() {
    yield takeLatest(REMOVE_REGISTRATION, removingRegistration);
}

export function* updateFamilyMemberCollection() {
    yield takeLatest(UPDATE_FAMILY_MEMBER, updateFamilyMember);
}

export default function* rootSaga() {
    yield all([
        fork(getRegistrationsForProperty),
        fork(addingOccupantRegistration),
        fork(editingRegistration),
        fork(addingRegistration),
        fork(updateFamilyMemberCollection),
        fork(removeRegistration)
    ]);
}
