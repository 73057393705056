import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// project import
import { connect } from 'react-redux';
import { userTypes } from 'utils/constants';

const GuestGuard = ({ children, authUser, userData, managerIsChangingPassword }) => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (authUser && userData) {
            if (
                managerIsChangingPassword &&
                location?.pathname === '/reset-password-authenticated'
            ) {
                navigate('reset-password-authenticated', { replace: true });
            } else {
                if (userData.type === userTypes.SUPER_ADMIN) {
                    navigate(location?.state?.from ? location?.state?.from : 'orgs', {
                        state: {
                            from: ''
                        },
                        replace: true
                    });
                } else if (userData.role === 'resi') {
                    navigate(
                        location?.state?.from ? location?.state?.from : 'manage-guests',
                        {
                            state: {
                                from: ''
                            },
                            replace: true
                        }
                    );
                } else {
                    navigate(
                        location?.state?.from ? location?.state?.from : 'dashboard',
                        {
                            state: {
                                from: ''
                            },
                            replace: true
                        }
                    );
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authUser, userData, navigate, location?.pathname, managerIsChangingPassword]);

    return children;
};

GuestGuard.propTypes = {
    children: PropTypes.node,
    authUser: PropTypes.object
};

const mapStateToProps = ({ auth, user }) => {
    const { authUser, managerIsChangingPassword } = auth;
    const { userData } = user;
    return { authUser, managerIsChangingPassword, userData };
};

export default connect(mapStateToProps, {})(GuestGuard);
