import {
    GET_DEVICE_OPERATIONS,
    GET_DEVICE_OPERATIONS_SUCCESS,
    GET_DEVICE_OPERATIONS_FAILURE
} from '../actions/types';

const INITIAL_STATE = {
    operations: [],
    loading: true,
    error: null
};

const deviceOperationsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_DEVICE_OPERATIONS:
            return {
                ...state,
                loading: true,
                error: null
            };
        case GET_DEVICE_OPERATIONS_SUCCESS:
            return {
                ...state,
                operations: action.payload,
                loading: false,
                error: null
            };
        case GET_DEVICE_OPERATIONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
};

export default deviceOperationsReducer;
