import { all, fork, takeLatest, put, call } from 'redux-saga/effects';

import { GENERATE_LIVEKIT_TOKEN } from '../actions/types';

import { func } from 'config/firebase';

import { httpsCallable } from 'firebase/functions';
import {
    generateLiveKitTokenFailure,
    generateLiveKitTokenSuccess
} from 'store/actions/LiveKit';

const generateLiveKitAccessToken = httpsCallable(func, 'generateLiveKitAccessToken');

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////// Generate LiveKit Token ///////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const getLiveKitTokenRequest = async payload => {
    const { roomName, participantName } = payload;

    const request = await generateLiveKitAccessToken({ roomName, participantName });
    return { token: request.data.token };
};

function* getLiveKitToken({ payload }) {
    const { token, error } = yield call(getLiveKitTokenRequest, payload);
    if (token) {
        yield put(generateLiveKitTokenSuccess(token));
    } else {
        console.log('LiveKitToken error', error);
        yield put(generateLiveKitTokenFailure());
    }
}

///////////// Action Creators For Root Saga //////////////////

export function* generateLiveKitToken() {
    yield takeLatest(GENERATE_LIVEKIT_TOKEN, getLiveKitToken);
}

export default function* rootSaga() {
    yield all([fork(generateLiveKitToken)]);
}
