// React
import React, { useState, useEffect } from 'react';

// Assets
import { CloseCircleOutlined } from '@ant-design/icons';
// import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
// import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

// Firebase
import { fsTimeStampNow } from 'config/firebase';

// Packages
// import { Controlled as ControlledZoom } from 'react-medium-image-zoom';
import { PatternFormat } from 'react-number-format';
import {
    Button,
    IconButton,
    Stack,
    Typography,
    List,
    ListItem,
    ListItemText,
    OutlinedInput
} from '@mui/material';

// Styles
import '../../styles/image-zoom.css';

// Utils
import { visitorSchema, reasons } from 'utils/constants';

const NewVisitor = ({
    activePanel,
    setActivePanel,
    accessConfirm,
    setAccessConfirm,
    denyConfirm,
    setDenyConfirm,
    members,
    orgs,
    panels,
    propertyId,
    setVisitor,
    userData,
    handleGateAccess,
    handleDisallowGateAccess
}) => {
    // const [zoomedId, setZoomedId] = useState(false);
    // const [zoomedVehicle, setZoomedVehicle] = useState(false);
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [option, setOption] = useState(null);
    const [options, setOptions] = useState(null);
    const [reason, setReason] = useState(null);
    const [orgId, setOrgId] = useState(null);

    useEffect(() => {
        if (members && members.length) {
            const residents = members.filter(
                member =>
                    member?.properties &&
                    member?.properties[propertyId]?.role === 'resi' &&
                    !member?.super_suspend
            );
            const memberOptions = residents.map(resident => {
                return {
                    id: resident.uid,
                    name: `${resident.first_name} ${resident.last_name}`,
                    phone: { number: resident.phone?.number, xt: null }
                };
            });
            if (!orgId) setOrgId(residents[0]?.properties[propertyId]?.org_id);

            setOptions([...memberOptions]);
        } else {
            if (orgs && orgs?.length && activePanel) {
                const org = orgs.filter(org => org.org_id === activePanel.orgId)[0];
                const orgPhone = org?.phone?.primary;
                const orgXt = org?.phone?.primary_ext;

                setOptions([
                    {
                        id: 1,
                        name: 'Grounds Maintenance',
                        phone: { number: orgPhone, xt: orgXt }
                    },
                    {
                        id: 2,
                        name: 'Clubhouse/Office',
                        phone: { number: orgPhone, xt: orgXt }
                    },
                    { id: 3, name: 'Other', phone: { number: orgPhone, xt: orgXt } }
                ]);
                if (!orgId) setOrgId(activePanel.orgId);
            }
        }
    }, [
        members,
        setOptions,
        orgs,
        activePanel,
        propertyId,
        orgId,
        setOrgId,
        setDenyConfirm,
        setAccessConfirm
    ]);

    const panelList = activePanel
        ? panels[activePanel.orgId]
        : orgId
        ? panels[orgId]
        : [];

    const confirmOptions = accessConfirm ? panelList : reasons;

    // const getAccess = useCallback((keys, member) => {
    //     if (keys && keys.length) {
    //         return keys.some(key => {
    //             const isValid = key => {
    //                 const today = new Date();
    //                 const startTime = new Date();
    //                 const endTime = new Date();
    //                 const nowMs = timeStampNowSeconds() * 1000;
    //                 const nowSec = timeStampNowSeconds();
    //                 const day = today.toLocaleString('en-US', { weekday: 'short' });
    //                 const dayStr = day.toLowerCase();
    //                 const beginSec = key.access_begins ? key.access_begins.seconds : null;
    //                 const expireSec = key.access_expires
    //                     ? key.access_expires.seconds
    //                     : null;

    //                 /**** Valid Day ****/
    //                 const includesDay = key?.access_days?.length
    //                     ? key.access_days.includes(dayStr)
    //                     : true;

    //                 /**** Valid Time Window by Day ****/
    //                 const start = key.access_start_time
    //                     ? startTime.setHours(
    //                           key.access_start_time.hours,
    //                           key.access_start_time.minutes,
    //                           0,
    //                           0
    //                       )
    //                     : key.access_start_time;

    //                 const end = key.access_end_time
    //                     ? endTime.setHours(
    //                           key.access_end_time.hours,
    //                           key.access_end_time.minutes,
    //                           0,
    //                           0
    //                       )
    //                     : key.access_end_time;

    //                 const inTimeWindow =
    //                     !start && !end ? true : nowMs >= start && nowMs <= end;

    //                 /**** Valid Time Window by Total Duration ****/
    //                 const inExpireWindow = !beginSec
    //                     ? !expireSec
    //                         ? true
    //                         : expireSec >= nowSec
    //                     : beginSec <= nowSec
    //                     ? expireSec
    //                         ? expireSec >= nowSec
    //                         : true
    //                     : false;

    //                 /**** Valid by Suspension ****/
    //                 const suspended = key.suspended;
    //                 return includesDay && inTimeWindow && inExpireWindow && !suspended;
    //             };
    //             const hasAccess = isValid(key) && !member.super_suspend;
    //             setShowAccess(true);
    //             setAccess(hasAccess);
    //             return hasAccess;
    //         });
    //     }
    //     setAccess(false);
    //     setShowAccess(true);
    //     return false;
    // }, []);

    // useEffect(() => {
    //     if (member) {
    //         if (member.invited_at) {
    //             setShowAccess(true);
    //         }
    //         if (memberKeys) {
    //             getAccess(memberKeys, member);
    //         }
    //     }
    // }, [memberKeys, member, getAccess, setShowAccess]);

    // useEffect(() => {
    //     if (showAccess) {
    //         setOverride(!access);
    //     }
    // }, [access, showAccess, setOverride]);

    // const handleZoom = useCallback(
    //     (type, state) => {
    //         setZoomActive(true);
    //         setZoomedId(type === 'id' ? state : false);
    //         setZoomedVehicle(type === 'id' ? false : state);
    //     },
    //     [setZoomActive]
    // );

    const handleConfirmAccess = () => {
        if (accessConfirm) {
            const visitor = Object.assign(visitorSchema, {
                approval: {
                    approved_by: option.id.length > 1 ? option.id : userData.uid,
                    name:
                        option.id.length > 1
                            ? option.name
                            : `${userData.first_name} ${userData.last_name}`,
                    type: option.id.length > 1 ? 'resi' : option.name
                }, // { approved_by: resi or guard UID, name: name of resi or guard, type: resi, Grounds Maintenance, Clubhouse/Office, Other},
                denial: null, // { denied_by: guard UID, name: resi or guard, type: Resident Refusal, Not on List - No Answer, No Identification}
                access: true,
                company_name: company.trim() !== '' ? company.trim().toLowerCase() : null,
                created_at: fsTimeStampNow(),
                id: null,
                name: name.trim().toLowerCase(),
                photo_id: null,
                property_id: propertyId,
                vehicle: null
            });
            setVisitor(visitor);
        } else {
            setAccessConfirm(true);
        }
    };

    const handleDenyAccess = () => {
        if (denyConfirm) {
            const visitor = Object.assign(visitorSchema, {
                approval: null, // { approved_by: resi or guard UID, name: name of resi or guard, type: resi, Grounds Maintenance, Clubhouse/Office, Other},
                denial: {
                    denied_by: option.id.length > 1 ? option.id : userData.uid,
                    name:
                        option.id.length > 1
                            ? option.name
                            : `${userData.first_name} ${userData.last_name}`,
                    type: reason.name
                }, // { denied_by: guard UID, name: resi or guard, type: Resident Refusal, Not on List - No Answer, No Identification}
                access: false,
                company_name: company.trim() !== '' ? company.trim().toLowerCase() : null,
                created_at: fsTimeStampNow(),
                id: null,
                name: name.trim().toLowerCase(),
                photo_id: null,
                property_id: propertyId,
                vehicle: null
            });
            setVisitor(visitor);
        } else {
            setDenyConfirm(true);
        }
    };

    const handlePanelSelect = panel => {
        const panelData = {
            orgId: panel.orgId ? panel.orgId : orgId,
            id: panel.id,
            name: panel.name
        };
        setActivePanel({
            ...panelData
        });
    };

    const handleReasonSelect = reason => {
        setReason({
            ...reason
        });
    };

    return (
        <Stack sx={{ pt: 1, pl: 3, pr: 3 }}>
            <Stack
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    height: '10vh',
                    width: '100%',
                    marginBottom: '2vh'
                }}
            >
                <List
                    sx={{
                        width: '100%',
                        overflowY: 'auto',
                        maxHeight: '10vh'
                    }}
                >
                    {options &&
                        options.map(op => (
                            <ListItem
                                key={`${op.name}${op.phone.number}`}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '2.5vh',
                                    marginBottom: '.5vh',
                                    backgroundColor:
                                        option?.id === op.id
                                            ? 'rgba(129, 250, 185, 1)'
                                            : 'transparent'
                                }}
                                button={true}
                                disableGutters
                                onClick={() => setOption(op)}
                            >
                                <ListItemText
                                    sx={{
                                        left: '4%'
                                    }}
                                    primary={
                                        <Stack
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'flex-start',
                                                alignItems: 'center',
                                                width: '100%'
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    width: '50%'
                                                }}
                                                component="span"
                                                variant="body1"
                                                color="text.primary"
                                                textTransform="capitalize"
                                            >
                                                {op.name}
                                            </Typography>
                                            <Stack
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                    width: '50%'
                                                }}
                                            >
                                                {op.phone.number && (
                                                    <Typography
                                                        sx={{
                                                            marginRight: '.5vw'
                                                        }}
                                                        component="span"
                                                        variant="body1"
                                                        color="text.primary"
                                                    >
                                                        <PatternFormat
                                                            value={op.phone.number}
                                                            displayType={'text'}
                                                            format="###-###-####"
                                                            mask="_"
                                                        />
                                                    </Typography>
                                                )}
                                                {op.phone.xt && (
                                                    <Typography
                                                        component="span"
                                                        variant="body1"
                                                        color="text.primary"
                                                    >
                                                        {`XT - ${op.phone.xt}`}
                                                    </Typography>
                                                )}
                                            </Stack>
                                        </Stack>
                                    }
                                />
                            </ListItem>
                        ))}
                </List>
            </Stack>
            <Stack
                sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <OutlinedInput
                    sx={{ marginBottom: '2vh' }}
                    inputProps={{
                        sx: {
                            height: '3vh',
                            width: '100%',
                            backgroundColor: 'rgba(242, 242, 242, 1)',
                            borderRadius: '4px',
                            border: '1px solid rgba(239, 239, 239, 1)'
                        }
                    }}
                    type="text"
                    placeholder="Name"
                    onChange={e => setName(e.target.value)}
                    value={name}
                />
                <OutlinedInput
                    inputProps={{
                        sx: {
                            height: '3vh',
                            width: '100%',
                            backgroundColor: 'rgba(242, 242, 242, 1)',
                            borderRadius: '4px',
                            border: '1px solid rgba(239, 239, 239, 1)'
                        }
                    }}
                    type="text"
                    placeholder="Company"
                    onChange={e => setCompany(e.target.value)}
                    value={company}
                />
            </Stack>
            <Stack
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    height: '21vh',
                    width: '100%',
                    marginTop: '1.5vh'
                }}
            >
                <Typography
                    sx={{
                        fontSize: '12px',
                        marginBottom: '1vh',
                        fontWeight: '700'
                    }}
                    component="span"
                    variant="body1"
                >
                    Identification:
                </Typography>
                <Stack
                    sx={{
                        height: '16.5vh',
                        width: '100%',
                        borderRadius: '2em'
                    }}
                >
                    {/* {member?.photo_id ? (
                        <ControlledZoom isZoomed={zoomedId} onZoomChange={handleZoom}>
                            <img
                                style={{
                                    height: '18.5vh',
                                    width: '100%',
                                    borderRadius: '2em',
                                    objectFit: 'fill'
                                }}
                                src={member.photo_id}
                                loading="lazy"
                                alt={'identification'}
                            />
                            <div
                                style={{
                                    position: 'absolute',
                                    bottom: '1.5vh',
                                    right: '.75vw',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <IconButton
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '2.8vh',
                                        height: '2.8vh',
                                        backgroundColor: 'rgba(0, 0, 0, 0.36)',
                                        borderRadius: '8px',
                                        padding: 0,
                                        margin: 0
                                    }}
                                    onClick={() => handleZoom('id', !zoomedId)}
                                >
                                    {!zoomedId ? (
                                        <ZoomOutMapIcon
                                            style={{
                                                width: '75%',
                                                color: 'rgba(255,255,255,1)',
                                                padding: 0,
                                                margin: 0
                                            }}
                                        />
                                    ) : (
                                        <ZoomInMapIcon
                                            style={{
                                                width: '75%',
                                                color: 'rgba(255,255,255,1)',
                                                padding: 0,
                                                margin: 0
                                            }}
                                        />
                                    )}
                                </IconButton>
                            </div>
                        </ControlledZoom>
                     ) : ( */}
                    <Button
                        sx={{
                            height: '100%',
                            width: '100%',
                            backgroundColor: 'rgba(208, 208, 208, 1)',
                            borderRadius: '2em',
                            '&:active::after': {
                                borderRadius: '2em'
                            },
                            '&:hover': {
                                backgroundColor: 'rgba(200, 200, 200, 1)'
                            }
                        }}
                    >
                        <Stack
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                                width: '100%'
                            }}
                        >
                            <AddAPhotoIcon
                                style={{
                                    color: 'rgba(255,255,255,1)',
                                    fontSize: '7vw'
                                }}
                            />
                            <Typography
                                sx={{
                                    fontSize: '24px',
                                    width: '100%',
                                    textAlign: 'center',
                                    color: 'rgba(255,255,255,1)'
                                }}
                                component="span"
                                variant="body1"
                                textTransform="capitalize"
                            >
                                Capture Photo ID
                            </Typography>
                        </Stack>
                    </Button>
                    {/* )} */}
                </Stack>
            </Stack>
            <Stack
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    height: '21vh',
                    width: '100%',
                    marginTop: '1.5vh'
                }}
            >
                <Typography
                    sx={{
                        fontSize: '12px',
                        marginBottom: '1vh',
                        fontWeight: 700
                    }}
                    component="span"
                    variant="body1"
                >
                    Vehicle:
                </Typography>
                <Stack
                    sx={{
                        height: '16.5vh',
                        width: '100%',
                        borderRadius: '2em'
                    }}
                >
                    {/* {vehicle?.photo_id ? (
                        <>
                            <ControlledZoom
                                isZoomed={zoomedVehicle}
                                onZoomChange={handleZoom}
                            >
                                <img
                                    style={{
                                        height: '18.5vh',
                                        width: '100%',
                                        borderRadius: '2em',
                                        objectFit: 'fill'
                                    }}
                                    src={vehicle.photo_id}
                                    loading="lazy"
                                    alt={'vehicle'}
                                />
                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: '1.5vh',
                                        right: '.75vw',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <IconButton
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '2.8vh',
                                            height: '2.8vh',
                                            backgroundColor: 'rgba(0, 0, 0, 0.36)',
                                            borderRadius: '8px',
                                            padding: 0,
                                            margin: 0
                                        }}
                                        onClick={() =>
                                            handleZoom('vehicle', !zoomedVehicle)
                                        }
                                    >
                                        {!zoomedId ? (
                                            <ZoomOutMapIcon
                                                style={{
                                                    width: '75%',
                                                    color: 'rgba(255,255,255,1)',
                                                    padding: 0,
                                                    margin: 0
                                                }}
                                            />
                                        ) : (
                                            <ZoomInMapIcon
                                                style={{
                                                    width: '75%',
                                                    color: 'rgba(255,255,255,1)',
                                                    padding: 0,
                                                    margin: 0
                                                }}
                                            />
                                        )}
                                    </IconButton>
                                </div>
                            </ControlledZoom>
                            <Stack style={{ marginTop: '1.5vh', marginBottom: '1vh' }}>
                                <Stack
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        alignItems: 'flex-start'
                                    }}
                                >
                                    <Stack
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start',
                                            width: '50%'
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: '700',
                                                width: '25%'
                                            }}
                                            component="span"
                                            variant="body1"
                                            textTransform="capitalize"
                                        >
                                            {`Make: `}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                            }}
                                            component="span"
                                            variant="body1"
                                            textTransform="capitalize"
                                        >
                                            {vehicle.make}
                                        </Typography>
                                    </Stack>
                                    <Stack
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start',
                                            width: '50%'
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: '700',
                                                width: '25%'
                                            }}
                                            component="span"
                                            variant="body1"
                                            textTransform="capitalize"
                                        >
                                            {`Model: `}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: '12px'
                                            }}
                                            component="span"
                                            variant="body1"
                                            textTransform="capitalize"
                                        >
                                            {vehicle.model}
                                        </Typography>
                                    </Stack>
                                </Stack>
                                <Stack
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        alignItems: 'flex-start',
                                        marginTop: '.5vh'
                                    }}
                                >
                                    <Stack
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start',
                                            width: '50%'
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: '700',
                                                width: '25%'
                                            }}
                                            component="span"
                                            variant="body1"
                                            textTransform="capitalize"
                                        >
                                            {`Plate: `}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: '12px'
                                            }}
                                            component="span"
                                            variant="body1"
                                            textTransform="capitalize"
                                        >
                                            {`${vehicle.tag}/${vehicle.state}`}
                                        </Typography>
                                    </Stack>
                                    <Stack
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start',
                                            width: '50%'
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: '700',
                                                width: '25%'
                                            }}
                                            component="span"
                                            variant="body1"
                                            textTransform="capitalize"
                                        >
                                            {`Color: `}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                width: '50%'
                                            }}
                                            component="span"
                                            variant="body1"
                                            textTransform="capitalize"
                                        >
                                            {vehicle.color}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </>
                    ) : ( */}
                    <Button
                        sx={{
                            height: '100%',
                            width: '100%',
                            backgroundColor: 'rgba(208, 208, 208, 1)',
                            borderRadius: '2em',
                            '&:active::after': {
                                borderRadius: '2em'
                            },
                            '&:hover': {
                                backgroundColor: 'rgba(200, 200, 200, 1)'
                            }
                        }}
                    >
                        <Stack
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                                width: '100%'
                            }}
                        >
                            <AddAPhotoIcon
                                style={{
                                    color: 'rgba(255,255,255,1)',
                                    fontSize: '7vw'
                                }}
                            />
                            <Typography
                                sx={{
                                    fontSize: '24px',
                                    width: '100%',
                                    textAlign: 'center',
                                    color: 'rgba(255,255,255,1)'
                                }}
                                component="span"
                                variant="body1"
                                textTransform="capitalize"
                            >
                                Capture Vehicle
                            </Typography>
                        </Stack>
                    </Button>
                    {/* )} */}
                </Stack>
            </Stack>
            {(accessConfirm || denyConfirm) && (
                <Stack
                    sx={{
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        marginTop: '1vh',
                        backgroundColor: accessConfirm
                            ? 'rgba(58, 230, 137, 0.14)'
                            : 'rgba(230, 58, 58, 0.13)',
                        height: '15vh',
                        width: '100%',
                        borderRadius: '9px',
                        border: `1px solid ${
                            accessConfirm
                                ? 'rgba(58, 230, 137, 1)'
                                : 'rgba(230, 58, 58, 1)'
                        }`
                    }}
                >
                    <Stack
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            backgroundColor: accessConfirm
                                ? 'rgba(58, 230, 137, 1)'
                                : 'rgba(230, 58, 58, 1)',
                            height: '23%',
                            width: '100%',
                            borderTopLeftRadius: '9px',
                            borderTopRightRadius: '9px',
                            border: `1px solid ${
                                accessConfirm
                                    ? 'rgba(58, 230, 137, 1)'
                                    : 'rgba(230, 58, 58, 0.51)'
                            }`,
                            margin: 0,
                            padding: 0
                        }}
                    >
                        <Typography
                            sx={{
                                marginLeft: '4%',
                                fontSize: '12px',
                                fontWeight: '700',
                                width: '80%'
                            }}
                            component="span"
                            variant="body1"
                            textTransform="capitalize"
                        >
                            {accessConfirm ? 'Select Gate' : 'Select Reason'}
                        </Typography>
                        <IconButton
                            onClick={() => {
                                if (accessConfirm) {
                                    setAccessConfirm(false);
                                } else {
                                    setDenyConfirm(false);
                                }
                            }}
                            aria-label="close"
                        >
                            <CloseCircleOutlined
                                style={{ color: 'rgba(255,255,255,1)' }}
                            />
                        </IconButton>
                    </Stack>
                    <List
                        sx={{
                            width: '100%',
                            overflowY: 'auto',
                            marginTop: '.5vh',
                            maxHeight: '70%'
                        }}
                    >
                        {confirmOptions?.map(option => (
                            <ListItem
                                key={option.id}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    height: '2.5vh',
                                    width: '95%',
                                    backgroundColor:
                                        accessConfirm && activePanel?.id === option.id
                                            ? 'rgba(129, 250, 185, 1)'
                                            : denyConfirm && reason?.id === option.id
                                            ? 'rgba(252, 169, 169, 1)'
                                            : 'transparent',
                                    borderRadius: '16px',
                                    border:
                                        accessConfirm && activePanel?.id !== option.id
                                            ? '1px solid rgba(129, 250, 185, 1)'
                                            : denyConfirm && reason?.id !== option.id
                                            ? '1px solid rgba(252, 169, 169, 1)'
                                            : 'none',
                                    paddingX: '4%',
                                    marginLeft: '2.5%',
                                    marginBottom: '.5vh'
                                }}
                                button={true}
                                disableGutters
                                onClick={() => {
                                    accessConfirm
                                        ? handlePanelSelect(option)
                                        : handleReasonSelect(option);
                                }}
                            >
                                <ListItemText
                                    sx={{
                                        left: '4%'
                                    }}
                                    primary={
                                        <Typography
                                            sx={{
                                                display: 'block'
                                            }}
                                            component="span"
                                            variant="body1"
                                            color="text.primary"
                                        >
                                            {option.name}
                                        </Typography>
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>
                </Stack>
            )}
            <Stack
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    position: 'absolute',
                    bottom: '2vh',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    width: '100%'
                }}
            >
                <Button
                    sx={{
                        width: '45%',
                        height: '4.5vh',
                        borderRadius: '26.5px',
                        '&:active::after': {
                            borderRadius: '26.5px'
                        },
                        fontSize: '0.9375rem',
                        fontWeight: '600',
                        backgroundColor: 'rgba(58, 230, 137, 1)',
                        '&:disabled': {
                            backgroundColor: 'rgba(58, 230, 137, .7)'
                        },
                        marginRight: '2.5%'
                    }}
                    disabled={
                        name.trim() === '' ||
                        !option ||
                        denyConfirm ||
                        (accessConfirm && !activePanel)
                    }
                    onClick={handleConfirmAccess}
                    variant="contained"
                >
                    {accessConfirm ? 'Confirm' : 'Grant Access'}
                </Button>
                <Button
                    sx={{
                        width: '45%',
                        height: '4.5vh',
                        borderRadius: '26.5px',
                        '&:active::after': {
                            borderRadius: '26.5px'
                        },
                        fontSize: '0.9375rem',
                        fontWeight: '600',
                        backgroundColor: 'rgba(230, 58, 58, 1)',
                        '&:disabled': {
                            backgroundColor: 'rgba(230, 58, 58, .7)'
                        },
                        '&:hover': {
                            backgroundColor: 'rgba(215, 43, 43, 1)'
                        }
                    }}
                    disabled={
                        name.trim() === '' ||
                        !option ||
                        accessConfirm ||
                        (denyConfirm && !reason)
                    }
                    onClick={handleDenyAccess}
                    variant="contained"
                >
                    {denyConfirm ? 'Confirm' : 'Deny Access'}
                </Button>
            </Stack>
        </Stack>
    );
};
export default NewVisitor;
