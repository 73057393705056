// React
import React, { useState } from 'react';

// Assets
import { CloseOutlined } from '@ant-design/icons';

// Packages
import { PatternFormat } from 'react-number-format';
import {
    Stack,
    Typography,
    List,
    ListItem,
    ListItemText,
    Tabs,
    Tab,
    Button,
    IconButton
} from '@mui/material';

// Utils
import { drawerTypes } from 'utils/constants';

const PropertyDetails = ({
    members,
    propertyId,
    propertyAddress,
    getMemberKeys,
    resetPropertyMembers,
    setMember,
    setConsumerId,
    setDrawerType,
    setDrawerTitle
}) => {
    const [activeTab, setActiveTab] = useState(0);

    const occupantMembers = members.filter(
        member =>
            (member?.invited_at && member?.role === 'resi') ||
            (member?.properties && member?.properties[propertyId]?.role === 'resi')
    );

    const occupants = occupantMembers.map(occupant => {
        return {
            ...occupant,
            status: occupant.invited_at
                ? 'Invited'
                : occupant?.super_suspend
                ? 'Suspended'
                : 'Active'
        };
    });

    const guestMembers = members.filter(
        member =>
            (member?.invited_at && member?.role === 'guest') ||
            (member?.properties && member?.properties[propertyId]?.role === 'guest')
    );

    const guests = guestMembers.map(guest => {
        return {
            ...guest,
            status: guest.invited_at
                ? 'Invited'
                : guest?.super_suspend
                ? 'Suspended'
                : 'Active'
        };
    });

    const handleChange = tab => {
        if (tab.includes('1')) {
            setActiveTab(1);
        } else {
            setActiveTab(0);
        }
    };

    const handleMemberDetails = member => {
        setMember(member);
        setDrawerTitle(`${member?.first_name} ${member.last_name}`);
        setDrawerType(drawerTypes.member.type);
        if (!member.invited_at) {
            setConsumerId(member.uid);
            getMemberKeys({ propertyId, memberId: member.uid });
        }
    };

    const handleLogNewVisitor = () => {
        setDrawerTitle(drawerTypes.visitor.title);
        setDrawerType(drawerTypes.visitor.type);
    };

    const GuestIcon = () => (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '25px',
                width: '25px',
                backgroundColor: 'rgba(45, 143, 87, .1)',
                borderRadius: '5px',
                marginLeft: '5px'
            }}
        >
            <Typography
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '13px',
                    fontWeight: '500'
                }}
                component="span"
                variant="body1"
                color="rgba(45, 143, 87, 1)"
            >
                {guests.length}
            </Typography>
        </div>
    );

    return (
        <Stack
            sx={{
                height: '77vh',
                marginTop: '2vh'
            }}
        >
            <Stack
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: '1.5vh',
                    height: '1.8vh',
                    width: '100%'
                }}
            >
                <Typography
                    sx={{ display: 'block', fontSize: '16px', fontWeight: '500' }}
                    component="span"
                    variant="body1"
                >
                    {propertyAddress}
                </Typography>
                <IconButton onClick={resetPropertyMembers}>
                    <CloseOutlined style={{ width: '16px' }} />
                </IconButton>
            </Stack>
            <Stack
                sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <Stack
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        height: '5.4vh',
                        width: '100%',
                        border: '1px solid rgba(0, 0, 0, 0.06)'
                    }}
                >
                    <Typography
                        sx={{
                            display: 'block',
                            fontSize: '13px',
                            fontWeight: '600',
                            paddingLeft: '3%'
                        }}
                        component="span"
                        variant="body1"
                        color="text.primary"
                    >
                        Occupants
                    </Typography>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '24px',
                            width: '24px',
                            backgroundColor: 'rgba(243,243,243,1)',
                            borderRadius: '5px',
                            marginLeft: '5px'
                        }}
                    >
                        <Typography
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: '13px',
                                fontWeight: '500'
                            }}
                            component="span"
                            variant="body1"
                            color="rgba(109, 109, 109, 1)"
                        >
                            {occupants.length}
                        </Typography>
                    </div>
                </Stack>
                <List
                    sx={{
                        width: '100%',
                        overflowY: 'auto',
                        maxHeight: '25vh',
                        borderLeft: '1px solid rgba(243, 243, 243, 1)',
                        borderRight: '1px solid rgba(243, 243, 243, 1)',
                        borderBottom: '1px solid rgba(243, 243, 243, 1)',
                        padding: 0,
                        margin: 0
                    }}
                >
                    {occupants.map(occupant => (
                        <ListItem
                            key={occupant.uid || occupant.invite_id}
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                height: '4vh',
                                width: '100%',
                                backgroundColor: '#ffffff',
                                borderBottom: '1px solid rgba(243, 243, 243, 1)',
                                paddingLeft: '3%'
                            }}
                            button={true}
                            disableGutters
                            onClick={() => {
                                handleMemberDetails(occupant);
                            }}
                        >
                            <>
                                <ListItemText
                                    sx={{
                                        maxWidth: '40%!important',
                                        left: '4%'
                                    }}
                                    primary={
                                        <Typography
                                            sx={{
                                                fontSize: '13px',
                                                textTransform: 'capitalize'
                                            }}
                                            component="span"
                                            variant="body1"
                                            color="text.primary"
                                        >
                                            {`${occupant?.first_name} ${occupant?.last_name}`}
                                        </Typography>
                                    }
                                />
                                <ListItemText
                                    sx={{
                                        maxWidth: '40%!important'
                                    }}
                                    primary={
                                        <Typography
                                            sx={{ fontSize: '13px' }}
                                            component="span"
                                            variant="body1"
                                            color="text.primary"
                                        >
                                            <PatternFormat
                                                value={
                                                    occupant?.phone?.number
                                                        ? occupant.phone.number
                                                        : ''
                                                }
                                                displayType={'text'}
                                                format="###-###-####"
                                                mask="_"
                                            />
                                        </Typography>
                                    }
                                />
                                {occupant.status === 'Invited' && (
                                    <ListItemText
                                        sx={{
                                            display: 'flex',
                                            position: 'absolute',
                                            right: '3%',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '15%'
                                        }}
                                        primary={
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    height: '80%',

                                                    backgroundColor:
                                                        occupant.status === 'Active'
                                                            ? 'rgba(45, 143, 87, .1)'
                                                            : occupant.status ===
                                                              'Invited'
                                                            ? 'rgba(255, 192, 0, .1)'
                                                            : 'rgba(255, 0, 0, .1)',
                                                    borderRadius: '5px'
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: '13px',
                                                        marginX: '10px'
                                                    }}
                                                    component="span"
                                                    variant="body1"
                                                    color={
                                                        occupant.status === 'Active'
                                                            ? 'rgba(45, 143, 87, 1)'
                                                            : occupant.status ===
                                                              'Invited'
                                                            ? 'rgba(255, 192, 0, 1)'
                                                            : 'rgba(255, 0, 0, 1)'
                                                    }
                                                >
                                                    {`${occupant?.status}`}
                                                </Typography>
                                            </div>
                                        }
                                    />
                                )}
                            </>
                        </ListItem>
                    ))}
                </List>
            </Stack>
            <Stack
                sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <Stack
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        marginTop: '2vh',
                        alignItems: 'center',
                        height: '5.4vh',
                        width: '100%',
                        border: '1px solid rgba(0, 0, 0, 0.06)'
                    }}
                >
                    <Tabs value={activeTab} onChange={e => handleChange(e.target.id)}>
                        <Tab
                            icon={<GuestIcon />}
                            iconPosition="end"
                            label="Guests"
                            id="simple-tab-0"
                        />
                        <Tab label="Access Log" id="simple-tab-1" />
                    </Tabs>
                </Stack>
                <div
                    role="tabpanel"
                    hidden={activeTab !== 0}
                    id={`simple-tabpanel-guests`}
                    aria-labelledby={`simple-tab-guests`}
                    value={'guests'}
                >
                    <List
                        sx={{
                            width: '100%',
                            overflowY: 'auto',
                            maxHeight: '35vh',
                            borderLeft: '1px solid rgba(243, 243, 243, 1)',
                            borderRight: '1px solid rgba(243, 243, 243, 1)',
                            borderBottom: '1px solid rgba(243, 243, 243, 1)',
                            padding: 0,
                            margin: 0
                        }}
                    >
                        {guests.map(guest => (
                            <ListItem
                                key={guest.uid || guest.invite_id}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    height: '4vh',
                                    width: '100%',
                                    backgroundColor: '#ffffff',
                                    borderBottom: '1px solid rgba(243, 243, 243, 1)',
                                    paddingLeft: '3%'
                                }}
                                button={true}
                                disableGutters
                                onClick={() => {
                                    handleMemberDetails(guest);
                                }}
                            >
                                <>
                                    <ListItemText
                                        sx={{
                                            maxWidth: '40%!important',
                                            left: '4%'
                                        }}
                                        primary={
                                            <Typography
                                                sx={{
                                                    fontSize: '13px',
                                                    textTransform: 'capitalize'
                                                }}
                                                component="span"
                                                variant="body1"
                                                color="text.primary"
                                            >
                                                {`${guest?.first_name} ${guest?.last_name}`}
                                            </Typography>
                                        }
                                    />
                                    <ListItemText
                                        sx={{
                                            maxWidth: '40%!important'
                                        }}
                                        primary={
                                            <Typography
                                                sx={{ fontSize: '13px' }}
                                                component="span"
                                                variant="body1"
                                                color="text.primary"
                                            >
                                                <PatternFormat
                                                    value={
                                                        guest?.phone?.number
                                                            ? guest.phone.number
                                                            : ''
                                                    }
                                                    displayType={'text'}
                                                    format="###-###-####"
                                                    mask="_"
                                                />
                                            </Typography>
                                        }
                                    />
                                    <ListItemText
                                        sx={{
                                            display: 'flex',
                                            position: 'absolute',
                                            right: '3%',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '15%'
                                        }}
                                        primary={
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    height: '80%',

                                                    backgroundColor:
                                                        guest.status === 'Active'
                                                            ? 'rgba(45, 143, 87, .1)'
                                                            : guest.status === 'Invited'
                                                            ? 'rgba(255, 192, 0, .1)'
                                                            : 'rgba(255, 0, 0, .1)',
                                                    borderRadius: '5px'
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: '13px',
                                                        marginX: '10px'
                                                    }}
                                                    component="span"
                                                    variant="body1"
                                                    color={
                                                        guest.status === 'Active'
                                                            ? 'rgba(45, 143, 87, 1)'
                                                            : guest.status === 'Invited'
                                                            ? 'rgba(255, 192, 0, 1)'
                                                            : 'rgba(255, 0, 0, 1)'
                                                    }
                                                >
                                                    {`${guest?.status}`}
                                                </Typography>
                                            </div>
                                        }
                                    />
                                </>
                            </ListItem>
                        ))}
                    </List>
                </div>
                <div
                    role="tabpanel"
                    hidden={activeTab !== 1}
                    id={`simple-tabpanel-1`}
                    aria-labelledby={`simple-tab-1`}
                >
                    Access Log
                </div>
            </Stack>
            <Stack
                sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'blue',
                    width: '100%'
                }}
            >
                <Button
                    sx={{
                        position: 'absolute',
                        bottom: '2vh',
                        width: '40%',
                        height: '4.5vh',
                        borderRadius: '26.5px',
                        '&:active::after': {
                            borderRadius: '26.5px'
                        },
                        fontSize: '0.9375rem',
                        fontWeight: '600'
                    }}
                    onClick={handleLogNewVisitor}
                    variant="contained"
                >
                    Log New Visitor
                </Button>
            </Stack>
        </Stack>
    );
};

export default PropertyDetails;
