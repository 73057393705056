import { combineReducers } from 'redux';

import AuthReducer from './Auth';
import InitialReducer from './Initial';
import MenuReducer from './menu';
import SnackbarReducer from './snackbar';
import UserReducer from './User';
import ManagerReducer from './Manager';
import OrgReducer from './Org';
import PropertiesReducer from './Properties';
import InvitesReducer from './Invites';
import AccessLogReducer from './AccessLog';
import GuestsReducer from './Guests';
import CallsReducer from './Calls';
import ComsReducer from './Intercom';
import PanelReducer from './Panels';
import VirtualGuardReducer from './VirtualGuard';
import RegistrationReducer from './Registration';
import MembersReducer from './Members';
import OtcReducer from './Otc';
import LiveKitReducer from './LiveKit';
import ReservationsReducer from './Reservation';
import AccessKeysReducer from './AccessKeys';
import LprReducer from './Lpr';
import DeviceOperationsReducer from './DeviceOperations';

import { routerReducer } from '../../history';

import { LOGOUT_USER } from '../actions/types';

const appReducer = combineReducers({
    router: routerReducer,
    auth: AuthReducer,
    init: InitialReducer,
    menu: MenuReducer,
    snack: SnackbarReducer,
    user: UserReducer,
    manager: ManagerReducer,
    access: AccessLogReducer,
    accessKey: AccessKeysReducer,
    invites: InvitesReducer,
    org: OrgReducer,
    members: MembersReducer,
    properties: PropertiesReducer,
    reservations: ReservationsReducer,
    guests: GuestsReducer,
    calls: CallsReducer,
    coms: ComsReducer,
    panel: PanelReducer,
    vg: VirtualGuardReducer,
    register: RegistrationReducer,
    otc: OtcReducer,
    livekit: LiveKitReducer,
    lpr: LprReducer,
    device: DeviceOperationsReducer
});

const rootReducer = (state, action) => {
    if (action.type === LOGOUT_USER) {
        const { init } = state;
        state = { init };
    }
    return appReducer(state, action);
};

export default rootReducer;
