import { all, fork, takeLatest, put, call } from 'redux-saga/effects';

import {
    GET_DEVICE_TYPES,
    UPDATE_ORG_DEVICE_DETAILS,
    GET_ORG_DEVICES_RTDB,
    GET_ORG_PANELS,
    ADD_PANEL,
    UPDATE_PANEL,
    REMOVE_PANEL
} from '../actions/types';

import { db, func, rtdb, storage } from 'config/firebase';

import {
    deleteObject,
    getDownloadURL,
    listAll,
    ref as sRef,
    uploadBytesResumable
} from 'firebase/storage';

import { ref, get, remove, set } from 'firebase/database';

// Loggers
import { log } from 'utils/Loggers';
import {
    addNewPanelSuccess,
    getDeviceTypesFailure,
    getDeviceTypesSuccess,
    getOrgDevicesRTDBFailure,
    getOrgDevicesRTDBSuccess,
    getOrgPanelsFailure,
    getOrgPanelsSuccess,
    removePanelFailure,
    removePanelSuccess,
    storePanelDevicesData,
    updateOrgDeviceDetailsFailure,
    updateOrgDeviceDetailsSuccess
} from '../actions/Panels';

import {
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    query,
    setDoc,
    updateDoc,
    where
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';

import { lprCollectionWatch } from './Lpr';
import { addAuthUser } from './Auth';

import { openSnackbar } from 'store/actions/Snackbar';

const deleteAuthUser = httpsCallable(func, 'deleteAuthUser');

const panelsCollectionRef = collection(db, 'panels');
const deviceTypesRef = doc(panelsCollectionRef, 'devices');

export const getRtdbOrgPanelsRef = orgId => ref(rtdb, `orgs/${orgId}/panels`);

export function* updateDeviceDetails({ payload }) {
    try {
        const { deviceInfo, orgId } = payload;

        const deviceTypesRef = doc(collection(db, `orgs/${orgId}/panels`), 'devices');
        yield updateDoc(deviceTypesRef, deviceInfo);

        const orgPanelsRef = getRtdbOrgPanelsRef(orgId);
        yield ref(orgPanelsRef, deviceInfo.id).update(deviceInfo);

        yield put(updateOrgDeviceDetailsSuccess());
    } catch (error) {
        yield put(updateOrgDeviceDetailsFailure(error));
    }
}

export function* getDeviceTypes() {
    try {
        const docSnapshot = yield getDoc(deviceTypesRef);
        const types = docSnapshot.data();

        yield put(getDeviceTypesSuccess(types));
    } catch (error) {
        yield put(getDeviceTypesFailure(error));
    }
}

const getRtdbOrgDevicesRequest = ({ orgs, active_org_id }) => {
    const processDevices = async ({ orgPanelsRef, orgId, activeOrgId }) => {
        const snapshot = await get(ref(rtdb, `orgs/${orgId}/panels`)).catch(
            error => error
        );

        return new Promise(resolve => {
            const devicesArr = [];
            const ids = [];

            if (snapshot.exists()) {
                const devices = snapshot.val();
                for (const key in devices) {
                    devicesArr.push(devices[key]);
                    if (devices[key].lpr_id && orgId === activeOrgId) {
                        ids.push(`${orgId}_${devices[key].lpr_id}`);
                    }
                }
            }
            resolve({ devicesArr, ids });
        });
    };

    return new Promise((resolve, reject) => {
        const lprIds = [];
        const orgKeys = Object.keys(orgs);
        const devicesByOrg = {};

        for (const orgId in orgs) {
            const org = orgs[orgId];
            const orgPanelsRef = getRtdbOrgPanelsRef(org.org_id);
            processDevices({
                orgPanelsRef,
                orgId,
                activeOrgId: active_org_id
            })
                .then(({ devicesArr, ids }) => {
                    devicesByOrg[org.org_id] = devicesArr;
                    lprIds.push(...ids);
                    if (orgKeys[orgKeys.length - 1] === orgId) {
                        resolve({ res: { devicesByOrg, lprIds } });
                    }
                })
                .catch(error => reject({ error }));
        }
    });
};

export function* getRtdbOrgDevices(user) {
    const { orgs, active_org_id } = user;
    const { res, error } = yield call(() =>
        getRtdbOrgDevicesRequest({ orgs, active_org_id })
    );
    if (res) {
        yield put(getOrgDevicesRTDBSuccess(res.devicesByOrg));
        if (res.lprIds && res.lprIds.length)
            yield fork(lprCollectionWatch, {
                ids: res.lprIds
            });
    } else {
        yield put(getOrgDevicesRTDBFailure(res.devicesByOrg));
        log('Org Devices Error: getting devices by user org(s) data (RTDB)', {
            error,
            orgs,
            active_org_id,
            user
        });
    }
}

/*********** Not sure we need saga commented below ***********/

// export function* panelCollectionWatch(user) {
//     let unsubscribeUserPanelData;
//     const panelCollectionChannel = eventChannel(emit => {
//         const unsubscribe = onSnapshot(
//             query(
//                 collection(db, 'panels'),
//                 where('id', '==', user.type === 'super_admin' ? user.type : null)
//             ),
//             querySnapshot => {
//                 if (!querySnapshot.empty) {
//                     let panel = null;
//                     querySnapshot.forEach(doc => {
//                         panel = doc.data();
//                     });
//                     emit(panel);
//                 } else {
//                     const doc = { exists: false };
//                     emit({ doc });
//                 }
//             }
//         );
//         unsubscribeUserPanelData = () => unsubscribe();
//         return unsubscribeUserPanelData;
//     });
//     try {
//         while (true) {
//             const { userSignOut, panelData } = yield race({
//                 userSignOut: take(LOGOUT_USER),
//                 panelData: take(panelCollectionChannel)
//             });

//             if (userSignOut) {
//                 panelCollectionChannel.close(); // Detach saga event emitter
//             } else {
//                 console.log(panelData, 'storing panel data using org store func????');
//                 yield put(storeOrgData(panelData));
//             }
//         }
//     } catch (error) {
//         log('Watching Panels: getting panels collection data (FS)', {
//             error,
//             user
//         });
//     } finally {
//         unsubscribeUserPanelData(); // Detach firebase listener
//         if (yield cancelled()) {
//             panelCollectionChannel.close(); // Detach saga event emitter
//             unsubscribeUserPanelData(); // Detach firebase listener
//         }
//     }
// }

export function* getPanelDevices() {
    try {
        const panelDevicesRef = doc(db, 'panels', 'devices');
        const panelDevicesSnapshot = yield call(getDoc, panelDevicesRef);
        const panelDevicesData = panelDevicesSnapshot.data();

        if (panelDevicesData) {
            const panelDevicesArray = Object.entries(panelDevicesData).map(
                ([id, data]) => ({
                    id,
                    ...data
                })
            );

            yield put(storePanelDevicesData(panelDevicesArray));
        } else {
            console.error('No panel devices data found');
        }
    } catch (error) {
        console.error('Error fetching panel devices data:', error);
    }
}

export function* getOrgPanelUsers({ payload }) {
    const orgId = payload.org_id;
    // const combinedData = [];

    try {
        const panelRef = ref(rtdb, `orgs/${orgId}/panels`);
        const panelSnapshot = yield call(get, panelRef);
        const panels = panelSnapshot.val();
        const panelIds = Object.keys(panels);

        const usersRef = collection(db, 'users');
        const q = query(usersRef, where('uid', 'in', panelIds));
        const userSnapshots = yield call(getDocs, q);

        const panelUsers = userSnapshots.docs.map(doc => doc.data());
        yield put(getOrgPanelsSuccess({ panels, panelUsers }));
    } catch (error) {
        openSnackbar({
            open: true,
            message: `Error fetching panel or user data: ${error}`,
            variant: 'alert',
            alert: {
                color: 'error'
            }
        });
        console.error('Error fetching panel or user data:', error);
        yield put(getOrgPanelsFailure(error.message));
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////// Panel users //////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* addPanel({ payload }) {
    const { panel, orgId, index } = payload;

    const userId = yield call(() => createPanelUserRequest(panel, orgId, index));

    if (userId) {
        yield put(addNewPanelSuccess());
        yield put(
            openSnackbar({
                open: true,
                message: 'Success: Panel has been added!',
                variant: 'alert',
                alert: {
                    color: 'success'
                }
            })
        );
    } else {
        yield put(
            openSnackbar({
                open: true,
                message: `Failed: Panel was not added successfully.`,
                variant: 'alert',
                alert: {
                    color: 'error'
                }
            })
        );
    }
}

export function* createPanelUserRequest(station, orgId, index) {
    try {
        const newAuthUserId = yield call(addAuthUser, {
            email: station.email,
            password: process.env.REACT_APP_PANEL_TEMP_PASS
        });

        const userData = {
            active: true,
            active_org_id: orgId,
            admin_pin: station.admin_pin,
            device_type: station.device_type,
            devices: {},
            door_num: station.door_num,
            email: station.email,
            first_name: null,
            ip_address: '',
            last_name: null,
            name: station.name,
            org_ids: [],
            orgs: null,
            primary_org: orgId,
            property_ids: [],
            role: 'panel',
            type: 'community',
            uid: newAuthUserId
        };

        yield call(setDoc, doc(db, 'users', newAuthUserId), userData);

        const panelData = {
            active: true,
            channel_id: '',
            device_type: station.device_type,
            door_num: station.door_num,
            id: newAuthUserId,
            index: index + 1,
            lpr_id: station.lpr_id,
            name: station.name
        };

        const panelRef = ref(rtdb, `orgs/${orgId}/panels/${newAuthUserId}`);
        yield call(set, panelRef, panelData);

        const rootRef = sRef(storage, 'panel_updates/');
        const listResult = yield call(listAll, rootRef);

        const apkFileRef = listResult.items[0];
        if (apkFileRef) {
            const apkFileName = apkFileRef._location.path_.split('/').pop();
            const apkFileURL = yield call(getDownloadURL, apkFileRef);

            const response = yield fetch(apkFileURL);
            const blob = yield response.blob();

            const panelUpdateRef = sRef(
                storage,
                `panel_updates/${newAuthUserId}/${apkFileName}`
            );
            yield call(uploadBytesResumable, panelUpdateRef, blob);
        } else {
            throw new Error('APK file not found in root storage');
        }
        return newAuthUserId;
    } catch (error) {
        console.error('Error creating user for station:', error);
        return null;
    }
}

export function* updatingPanel({ payload }) {
    const { panel, orgId, panelId } = payload;
    const { admin_pin, device_type, door_num, email, name, lpr_id } = panel;

    try {
        const userData = {
            admin_pin,
            device_type,
            door_num,
            email,
            name
        };

        const userRef = doc(db, 'users', panelId);
        yield call(updateDoc, userRef, userData);

        const panelData = {
            device_type,
            door_num,
            lpr_id,
            name
        };

        const panelRef = ref(rtdb, `orgs/${orgId}/panels/${panelId}`);
        yield call(set, panelRef, panelData);

        yield put(addNewPanelSuccess());
        yield put(
            openSnackbar({
                open: true,
                message: 'Success: Panel has been updated!',
                variant: 'alert',
                alert: {
                    color: 'success'
                }
            })
        );
    } catch (error) {
        console.error('Error updating user for station:', error);
        yield put(
            openSnackbar({
                open: true,
                message: `Failed: Panel was not updated successfully.`,
                variant: 'alert',
                alert: {
                    color: 'error'
                }
            })
        );
    }
}

export function* deletePanel({ payload }) {
    const { panelId, orgId } = payload;
    try {
        const userDocRef = doc(db, 'users', panelId);
        yield call(deleteDoc, userDocRef);

        const panelRef = ref(rtdb, `orgs/${orgId}/panels/${panelId}`);
        yield call(remove, panelRef);

        const uid = panelId;
        yield call(deleteAuthUser, { uid });

        const panelUpdateRef = sRef(storage, `panel_updates/${panelId}`);
        const listResult = yield call(listAll, panelUpdateRef);
        const deletePromises = listResult.items.map(item => call(deleteObject, item));
        yield all(deletePromises);

        yield put(removePanelSuccess());
        yield put(
            openSnackbar({
                open: true,
                message: 'Success: Panel has been removed!',
                variant: 'alert',
                alert: {
                    color: 'success'
                }
            })
        );
    } catch (error) {
        yield put(removePanelFailure(error));
        console.error('Error deleting user for station:', error);
        yield put(
            openSnackbar({
                open: true,
                message: `Failed: Panel was not removed successfully.`,
                variant: 'alert',
                alert: {
                    color: 'error'
                }
            })
        );
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Action Creators For Root Saga ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* updateDetails() {
    yield takeLatest(UPDATE_ORG_DEVICE_DETAILS, updateDeviceDetails);
}

export function* getOrgPanels() {
    yield takeLatest(GET_ORG_DEVICES_RTDB, getRtdbOrgDevices);
}

export function* getTypes() {
    yield takeLatest(GET_DEVICE_TYPES, getDeviceTypes);
}

// export function* getPanelCollection() {
//     yield takeLatest(GET_PANEL_DATA, panelCollectionWatch);
// }

export function* getOrgPanelUsersCollections() {
    yield takeLatest(GET_ORG_PANELS, getOrgPanelUsers);
}

export function* addNewPanel() {
    yield takeLatest(ADD_PANEL, addPanel);
}

export function* updatePanel() {
    yield takeLatest(UPDATE_PANEL, updatingPanel);
}

export function* removePanel() {
    yield takeLatest(REMOVE_PANEL, deletePanel);
}

export default function* rootSaga() {
    yield all([
        // fork(getPanelCollection),
        fork(getTypes),
        fork(getOrgPanels),
        fork(updateDetails),
        fork(getOrgPanelUsersCollections),
        fork(addNewPanel),
        fork(updatePanel),
        fork(removePanel)
    ]);
}
