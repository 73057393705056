import React, { useLayoutEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Typography, useMediaQuery } from '@mui/material';

import { HORIZONTAL_MAX_ITEM, MenuOrientation } from 'config';

import useConfig from 'hooks/useConfig';
import { connect } from 'react-redux';

// project import
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { userTypes } from 'utils/constants';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = ({ drawerOpen, activeOrg, activePermissions, userData }) => {
    const theme = useTheme();

    const downLG = useMediaQuery(theme.breakpoints.down('lg'));

    const { menuOrientation } = useConfig();
    const [selectedItems, setSelectedItems] = useState('');
    const [selectedLevel, setSelectedLevel] = useState(0);
    const [filteredMenuItems, setFilteredMenuItems] = useState({ items: [] });

    useLayoutEffect(() => {
        if (userData?.type === userTypes.SUPER_ADMIN) {
            setFilteredMenuItems({
                items: menuItem.items.filter(item => item.id === 'super_admin')
            });
        }
        if (activeOrg && menuItem && activePermissions?.length > 0) {
            const orgModules = activeOrg?.modules || [];
            const filteredItems = menuItem.items.filter(item => {
                return (
                    orgModules.includes(item.id) && activePermissions.includes(item.id)
                );
            });

            setFilteredMenuItems({ items: filteredItems });
        }
    }, [activeOrg, activePermissions, userData]);

    const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downLG;

    const lastItem = isHorizontal ? HORIZONTAL_MAX_ITEM : null;
    let lastItemIndex = filteredMenuItems.items.length - 1;
    let remItems = [];
    let lastItemId;

    //  first it checks menu item is more than giving HORIZONTAL_MAX_ITEM after that get lastItemid by giving horizontal max
    // item and it sets horizontal menu by giving horizontal max item lastly slice menuItem from array and set into remItems

    if (lastItem && lastItem < filteredMenuItems.items.length) {
        lastItemId = filteredMenuItems.items[lastItem - 1].id;
        lastItemIndex = lastItem - 1;
        remItems = filteredMenuItems.items
            .slice(lastItem - 1, filteredMenuItems.items.length)
            .map(item => ({
                title: item.title,
                elements: item.children,
                icon: item.icon
            }));
    }

    const navGroups = filteredMenuItems.items.slice(0, lastItemIndex + 1).map(item => {
        switch (item.type) {
            case 'group':
                return (
                    <NavGroup
                        key={item.id}
                        setSelectedItems={setSelectedItems}
                        setSelectedLevel={setSelectedLevel}
                        selectedLevel={selectedLevel}
                        selectedItems={selectedItems}
                        lastItem={lastItem}
                        remItems={remItems}
                        lastItemId={lastItemId}
                        item={item}
                    />
                );
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Fix - Navigation Group
                    </Typography>
                );
        }
    });
    return (
        <Box
            sx={{
                pt: drawerOpen ? (isHorizontal ? 0 : 2) : 0,
                '& > ul:first-of-type': { mt: 0 },
                display: isHorizontal ? { xs: 'block', lg: 'flex' } : 'block'
            }}
        >
            {navGroups}
        </Box>
    );
};

const mapStateToProps = ({ menu, user }) => {
    const { drawerOpen } = menu;
    const { activeOrg, userData, activePermissions } = user;

    return { drawerOpen, activeOrg, userData, activePermissions };
};

export default connect(mapStateToProps, {})(Navigation);
