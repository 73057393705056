import {
    SET_ACTIVE_ITEM,
    SET_ACTIVE_ID,
    SET_ACTIVE_COMPONENT,
    SET_OPEN_DRAWER,
    SET_OPEN_COMPONENT_DRAWER,
    SET_MENU_ERROR
} from '../actions/types';

const INITIAL_STATE = {
    openItem: ['dashboard'],
    openComponent: 'buttons',
    selectedID: null,
    drawerOpen: false,
    componentDrawerOpen: true,
    menu: {},
    error: null
};

const menuReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_ACTIVE_ITEM:
            return {
                ...state,
                openItem: action.payload.openItem
            };
        case SET_ACTIVE_ID:
            return {
                ...state,
                selectedID: action.payload
            };
        case SET_ACTIVE_COMPONENT:
            return {
                ...state,
                openComponent: action.payload.openComponent
            };
        case SET_OPEN_DRAWER:
            return {
                ...state,
                drawerOpen: action.payload
            };
        case SET_OPEN_COMPONENT_DRAWER:
            return {
                ...state,
                componentDrawerOpen: action.payload.componentDrawerOpen
            };
        case SET_MENU_ERROR:
            return {
                ...state,
                error: action.payload
            };

        default:
            return state;
    }
};

export default menuReducer;
