// React
import React, { useRef, useState, useEffect } from 'react';

// Assets
import { LogoutOutlined, UserOutlined, GlobalOutlined } from '@ant-design/icons';

// Components
import ProfileTab from './ProfileTab';
import OrgsTab from './OrgsTab';
import { MainCard } from 'components';
import Transitions from 'components/@extended/Transitions';
import IconButton from 'components/@extended/IconButton';

// Hooks
import { useNavigate } from 'react-router';
import { useTheme } from '@mui/material/styles';

// Packages
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Box,
    ButtonBase,
    CardContent,
    ClickAwayListener,
    Grid,
    Paper,
    Popper,
    Stack,
    Tab,
    Tabs,
    Tooltip,
    Typography
} from '@mui/material';

// Redux - Actions, Reducers, Sagas
import { logoutUser } from 'store/actions/Auth';

// Styles
import { ThemeMode } from 'config';

/* tab panel wrapper */
const TabPanel = ({ children, value, index, ...other }) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`profile-tabpanel-${index}`}
            aria-labelledby={`profile-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

const a11yProps = index => {
    return {
        id: `profile-tab-${index}`,
        'aria-controls': `profile-tabpanel-${index}`
    };
};

const Profile = ({ userData, changingFocus, activeCalls, activeComs, logoutUser }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const handleLogout = () => {
        logoutUser();
        navigate(`/login`, {
            state: {
                from: ''
            }
        });
    };

    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const iconBackColorOpen =
        theme.palette.mode === ThemeMode.DARK ? 'grey.200' : 'grey.300';

    const userType = {
        super_admin: 'Super Admin',
        admin: 'Admin',
        manager: 'Manager',
        operator: 'Operator'
    };

    useEffect(() => {
        if (open && changingFocus) {
            setOpen(false);
        }
    }, [changingFocus, open, setOpen]);

    const checkActive = () => {
        var onCall =
            activeCalls.filter(call => call.fielded_by === userData.uid).length > 0 ||
            activeComs.filter(call => call.connected_by === userData.uid).length > 0;
        if (onCall) return true;
        return false;
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 0.75 }}>
            <ButtonBase
                sx={{
                    p: 0.25,
                    bgcolor: open ? iconBackColorOpen : 'transparent',
                    borderRadius: 1,
                    '&:hover': {
                        bgcolor:
                            theme.palette.mode === ThemeMode.DARK
                                ? 'secondary.light'
                                : 'secondary.lighter'
                    },
                    '&:focus-visible': {
                        outline: `2px solid ${theme.palette.secondary.dark}`,
                        outlineOffset: 2
                    }
                }}
                aria-label="open profile"
                ref={anchorRef}
                aria-controls={open ? 'profile-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
                    <Typography
                        variant="subtitle1"
                        sx={{ textTransform: 'capitalize' }}
                    >{`${userData?.first_name} ${userData?.last_name}`}</Typography>
                </Stack>
            </ButtonBase>
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 9]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions
                        type="grow"
                        position="top-right"
                        in={open}
                        {...TransitionProps}
                    >
                        <Paper
                            sx={{
                                boxShadow: theme.customShadows.z1,
                                width: 290,
                                minWidth: 240,
                                maxWidth: 290,
                                [theme.breakpoints.down('md')]: {
                                    maxWidth: 250
                                }
                            }}
                        >
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard elevation={0} border={false} content={false}>
                                    <CardContent sx={{ px: 2.5, pt: 3 }}>
                                        <Grid
                                            container
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <Grid item>
                                                <Stack
                                                    direction="row"
                                                    spacing={1.25}
                                                    alignItems="center"
                                                >
                                                    <Stack>
                                                        <Typography
                                                            variant="h6"
                                                            sx={{
                                                                textTransform:
                                                                    'capitalize'
                                                            }}
                                                        >{`${userData?.first_name} ${userData?.last_name}`}</Typography>
                                                        <Typography
                                                            variant="body2"
                                                            color="textSecondary"
                                                        >
                                                            {userType[userData?.type]}
                                                        </Typography>
                                                    </Stack>
                                                </Stack>
                                            </Grid>
                                            <Grid item>
                                                <Tooltip title="Logout">
                                                    <IconButton
                                                        size="large"
                                                        sx={{ color: 'text.primary' }}
                                                        onClick={handleLogout}
                                                    >
                                                        <LogoutOutlined />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </CardContent>

                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs
                                            variant="fullWidth"
                                            value={value}
                                            onChange={handleChange}
                                            aria-label="profile tabs"
                                        >
                                            <Tab
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    textTransform: 'capitalize'
                                                }}
                                                icon={
                                                    <GlobalOutlined
                                                        style={{
                                                            marginBottom: 0,
                                                            marginRight: '10px'
                                                        }}
                                                    />
                                                }
                                                label="Organizations"
                                                {...a11yProps(1)}
                                            />
                                            <Tab
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    textTransform: 'capitalize'
                                                }}
                                                icon={
                                                    <UserOutlined
                                                        style={{
                                                            marginBottom: 0,
                                                            marginRight: '10px'
                                                        }}
                                                    />
                                                }
                                                label="Profile"
                                                {...a11yProps(0)}
                                            />
                                        </Tabs>
                                    </Box>
                                    <TabPanel
                                        value={value}
                                        index={0}
                                        dir={theme.direction}
                                    >
                                        <OrgsTab isActive={checkActive} />
                                    </TabPanel>
                                    <TabPanel
                                        value={value}
                                        index={1}
                                        dir={theme.direction}
                                    >
                                        <ProfileTab handleLogout={handleLogout} />
                                    </TabPanel>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </Box>
    );
};

const mapStateToProps = ({ user, calls, coms }) => {
    const { userData, changingFocus } = user;
    const { active: activeCalls } = calls;
    const { active: activeComs } = coms;
    return { userData, changingFocus, activeCalls, activeComs };
};

export default connect(mapStateToProps, { logoutUser })(Profile);
