import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { baseConfig } from '../utils/constants';
import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers from '../store/reducers';
import rootSaga from '../store/sagas';
import { routerMiddleware } from '../history';

export default function configureStore() {
    // Sentry error reporting init config
    Sentry.init({
        dsn: baseConfig.SENTRY.dsn,
        integrations: [new Integrations.BrowserTracing()],
        environment: process.env.NODE_ENV,
        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0
    });
    Sentry.setTag('app-type', `sage-web-prod-${process.env.NODE_ENV}`);

    // Redux saga config
    const sagaMiddleware = createSagaMiddleware();

    const sentryReduxEnhancer = Sentry.createReduxEnhancer({
        //     // I can pass options here
    });

    // const enhancers =
    //     !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    //         ? composeWithDevTools(
    //               applyMiddleware(sagaMiddleware, routerMiddleware(history))
    //           )
    //         : compose(applyMiddleware(sagaMiddleware, routerMiddleware(history)));

    const store = createStore(
        reducers,
        {},
        compose(applyMiddleware(sagaMiddleware, routerMiddleware), sentryReduxEnhancer)
    );

    sagaMiddleware.run(rootSaga);

    return store;
}
