import React from 'react';
// material-ui
import { Alert, Button, Fade, Grow, Slide } from '@mui/material';
import MuiSnackbar from '@mui/material/Snackbar';

// project-import
import { connect } from 'react-redux';
import IconButton from './IconButton';
import { closeSnackbar } from 'store/actions/Snackbar';
import CallSound from 'components/common/CallSound';

// assets
import { CloseOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router';
import { VIRTUAL_GUARD } from 'utils/route-constants';

// animation function
function TransitionSlideLeft(props) {
    return <Slide {...props} direction="left" />;
}

function TransitionSlideUp(props) {
    return <Slide {...props} direction="up" />;
}

function TransitionSlideRight(props) {
    return <Slide {...props} direction="right" />;
}

function TransitionSlideDown(props) {
    return <Slide {...props} direction="down" />;
}

function GrowTransition(props) {
    return <Grow {...props} />;
}

// animation options
const animation = {
    SlideLeft: TransitionSlideLeft,
    SlideUp: TransitionSlideUp,
    SlideRight: TransitionSlideRight,
    SlideDown: TransitionSlideDown,
    Grow: GrowTransition,
    Fade
};

// ==============================|| SNACKBAR ||============================== //

const Snackbar = ({
    actionButton,
    anchorOrigin,
    alert,
    close,
    message,
    open,
    transition,
    variant,
    closeSnackbar,
    isGuardAlert,
    autoHideDuration
}) => {
    const navigate = useNavigate();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        closeSnackbar();
    };

    const handleVirtualGuardRedirect = () => {
        handleClose();
        navigate(VIRTUAL_GUARD);
    };

    return (
        <>
            <CallSound playChime={isGuardAlert && open} />
            {/* default snackbar */}
            {variant === 'default' && (
                <MuiSnackbar
                    anchorOrigin={anchorOrigin}
                    open={open}
                    autoHideDuration={autoHideDuration}
                    onClose={handleClose}
                    message={message}
                    TransitionComponent={animation[transition]}
                    action={
                        <>
                            <Button color="secondary" size="small" onClick={handleClose}>
                                UNDO
                            </Button>
                            <IconButton
                                size="small"
                                aria-label="close"
                                color="inherit"
                                onClick={handleClose}
                                sx={{ mt: 0.25 }}
                            >
                                <CloseOutlined />
                            </IconButton>
                        </>
                    }
                />
            )}

            {/* alert snackbar */}
            {variant === 'alert' && (
                <MuiSnackbar
                    TransitionComponent={animation[transition]}
                    anchorOrigin={anchorOrigin}
                    open={open}
                    autoHideDuration={autoHideDuration}
                    onClose={handleClose}
                >
                    <Alert
                        variant={alert.variant}
                        color={alert.color}
                        action={
                            <>
                                {isGuardAlert !== false && (
                                    <Button
                                        color={alert.color}
                                        size="small"
                                        onClick={handleVirtualGuardRedirect}
                                    >
                                        Answer
                                    </Button>
                                )}
                                {actionButton !== false && (
                                    <Button
                                        color={alert.color}
                                        size="small"
                                        onClick={handleClose}
                                    >
                                        UNDO
                                    </Button>
                                )}
                                {close !== false && (
                                    <IconButton
                                        sx={{ mt: 0.25 }}
                                        size="small"
                                        aria-label="close"
                                        variant="contained"
                                        color={alert.color}
                                        onClick={handleClose}
                                    >
                                        <CloseOutlined />
                                    </IconButton>
                                )}
                            </>
                        }
                        sx={{
                            ...(alert.variant === 'outlined' && {
                                bgcolor: 'grey.0'
                            })
                        }}
                    >
                        <div dangerouslySetInnerHTML={{ __html: message }} />
                    </Alert>
                </MuiSnackbar>
            )}
        </>
    );
};

const mapStateToProps = ({ snack }) => {
    const {
        actionButton,
        anchorOrigin,
        alert,
        close,
        message,
        open,
        transition,
        variant,
        isGuardAlert,
        autoHideDuration
    } = snack;
    return {
        actionButton,
        anchorOrigin,
        alert,
        close,
        message,
        open,
        transition,
        variant,
        isGuardAlert,
        autoHideDuration
    };
};

export default connect(mapStateToProps, { closeSnackbar })(Snackbar);
