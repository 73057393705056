import PropTypes from 'prop-types';
import React, { forwardRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';

import {
    Avatar,
    Chip,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
    useMediaQuery
} from '@mui/material';

// project import
import Dot from 'components/@extended/Dot';
import { MenuOrientation, ThemeMode } from 'config';
import useConfig from 'hooks/useConfig';
import { connect } from 'react-redux';
import { activeItem, openDrawer } from 'store/actions/Menu';

// ==============================|| NAVIGATION - LIST ITEM ||============================== //

const NavItem = ({ item, level, drawerOpen, openItem, openDrawer, activeItem }) => {
    const theme = useTheme();

    const { menuOrientation } = useConfig();

    const downLG = useMediaQuery(theme.breakpoints.down('lg'));

    let itemTarget = '_self';
    if (item.target) {
        itemTarget = '_blank';
    }

    const ListItemLink = forwardRef((props, ref) => (
        <Link {...props} to={item.url} target={itemTarget} ref={ref} />
    ));

    ListItemLink.displayName = 'ListItemLink'; // Add the displayName property

    let listItemProps = { component: ListItemLink };

    if (item?.external) {
        listItemProps = { component: 'a', href: item.url, target: itemTarget };
    }

    const Icon = item.icon;
    const itemIcon = item.icon ? (
        <Icon style={{ fontSize: drawerOpen ? '1rem' : '1.25rem' }} />
    ) : (
        false
    );

    const isSelected = openItem.findIndex(id => id === item.id) > -1;

    const { pathname } = useLocation();

    // active menu item on page load
    useEffect(() => {
        if (pathname && pathname.includes('product-details')) {
            if (item.url && item.url.includes('product-details')) {
                activeItem({ openItem: [item.id] });
            }
        }

        if (pathname && pathname.includes('kanban')) {
            if (item.url && item.url.includes('kanban')) {
                activeItem({ openItem: [item.id] });
            }
        }

        if (pathname.includes(item.url)) {
            activeItem({ openItem: [item.id] });
        }
        // eslint-disable-next-line
    }, [pathname]);

    const textColor = theme.palette.mode === ThemeMode.DARK ? 'grey.400' : 'text.primary';
    const iconSelectedColor =
        theme.palette.mode === ThemeMode.DARK && drawerOpen
            ? 'text.primary'
            : 'primary.main';

    return (
        <>
            {menuOrientation === MenuOrientation.VERTICAL || downLG ? (
                <ListItemButton
                    {...listItemProps}
                    disabled={item.disabled}
                    selected={isSelected}
                    sx={{
                        zIndex: 1201,
                        pl: drawerOpen ? `${level * 28}px` : 1.5,
                        py: !drawerOpen && level === 1 ? 1.25 : 1,
                        ...(drawerOpen && {
                            '&:hover': {
                                bgcolor:
                                    theme.palette.mode === ThemeMode.DARK
                                        ? 'divider'
                                        : 'primary.lighter'
                            },
                            '&.Mui-selected': {
                                bgcolor:
                                    theme.palette.mode === ThemeMode.DARK
                                        ? 'divider'
                                        : 'primary.lighter',
                                borderRight: `2px solid ${theme.palette.primary.main}`,
                                color: iconSelectedColor,
                                '&:hover': {
                                    color: iconSelectedColor,
                                    bgcolor:
                                        theme.palette.mode === ThemeMode.DARK
                                            ? 'divider'
                                            : 'primary.lighter'
                                }
                            }
                        }),
                        ...(!drawerOpen && {
                            '&:hover': {
                                bgcolor: 'transparent'
                            },
                            '&.Mui-selected': {
                                '&:hover': {
                                    bgcolor: 'transparent'
                                },
                                bgcolor: 'transparent'
                            }
                        })
                    }}
                    {...(downLG && {
                        onClick: () => openDrawer(false)
                    })}
                >
                    {itemIcon && (
                        <ListItemIcon
                            sx={{
                                minWidth: 28,
                                color: isSelected ? iconSelectedColor : textColor,
                                ...(!drawerOpen && {
                                    borderRadius: 1.5,
                                    width: 36,
                                    height: 36,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    '&:hover': {
                                        bgcolor:
                                            theme.palette.mode === ThemeMode.DARK
                                                ? 'secondary.light'
                                                : 'secondary.lighter'
                                    }
                                }),
                                ...(!drawerOpen &&
                                    isSelected && {
                                        bgcolor:
                                            theme.palette.mode === ThemeMode.DARK
                                                ? 'primary.900'
                                                : 'primary.lighter',
                                        '&:hover': {
                                            bgcolor:
                                                theme.palette.mode === ThemeMode.DARK
                                                    ? 'primary.darker'
                                                    : 'primary.lighter'
                                        }
                                    })
                            }}
                        >
                            {itemIcon}
                        </ListItemIcon>
                    )}
                    {(drawerOpen || (!drawerOpen && level !== 1)) && (
                        <ListItemText
                            primary={
                                <Typography
                                    variant="h6"
                                    sx={{
                                        color: isSelected ? iconSelectedColor : textColor
                                    }}
                                >
                                    {item.title}
                                </Typography>
                            }
                        />
                    )}
                    {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
                        <Chip
                            color={item.chip.color}
                            variant={item.chip.variant}
                            size={item.chip.size}
                            label={item.chip.label}
                            avatar={
                                item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>
                            }
                        />
                    )}
                </ListItemButton>
            ) : (
                <ListItemButton
                    {...listItemProps}
                    disabled={item.disabled}
                    selected={isSelected}
                    sx={{
                        zIndex: 1201,
                        ...(drawerOpen && {
                            '&:hover': {
                                bgcolor: 'transparent'
                            },
                            '&.Mui-selected': {
                                bgcolor: 'transparent',
                                color: iconSelectedColor,
                                '&:hover': {
                                    color: iconSelectedColor,
                                    bgcolor: 'transparent'
                                }
                            }
                        }),
                        ...(!drawerOpen && {
                            '&:hover': {
                                bgcolor: 'transparent'
                            },
                            '&.Mui-selected': {
                                '&:hover': {
                                    bgcolor: 'transparent'
                                },
                                bgcolor: 'transparent'
                            }
                        })
                    }}
                >
                    {itemIcon && (
                        <ListItemIcon
                            sx={{
                                minWidth: 36,
                                ...(!drawerOpen && {
                                    borderRadius: 1.5,
                                    width: 36,
                                    height: 36,
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    '&:hover': {
                                        bgcolor: 'transparent'
                                    }
                                }),
                                ...(!drawerOpen &&
                                    isSelected && {
                                        bgcolor: 'transparent',
                                        '&:hover': {
                                            bgcolor: 'transparent'
                                        }
                                    })
                            }}
                        >
                            {itemIcon}
                        </ListItemIcon>
                    )}

                    {!itemIcon && (
                        <ListItemIcon
                            sx={{
                                color: isSelected ? 'primary.main' : 'secondary.main',
                                ...(!drawerOpen && {
                                    borderRadius: 1.5,
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    '&:hover': {
                                        bgcolor: 'transparent'
                                    }
                                }),
                                ...(!drawerOpen &&
                                    isSelected && {
                                        bgcolor: 'transparent',
                                        '&:hover': {
                                            bgcolor: 'transparent'
                                        }
                                    })
                            }}
                        >
                            <Dot size={4} color={isSelected ? 'primary' : 'secondary'} />
                        </ListItemIcon>
                    )}
                    <ListItemText
                        primary={
                            <Typography variant="h6" color="inherit">
                                {item.title}
                            </Typography>
                        }
                    />
                    {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
                        <Chip
                            color={item.chip.color}
                            variant={item.chip.variant}
                            size={item.chip.size}
                            label={item.chip.label}
                            avatar={
                                item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>
                            }
                        />
                    )}
                </ListItemButton>
            )}
        </>
    );
};

NavItem.propTypes = {
    item: PropTypes.object,
    level: PropTypes.number
};

const mapStateToProps = ({ menu }) => {
    const { drawerOpen, openItem } = menu;
    return { drawerOpen, openItem };
};

export default connect(mapStateToProps, { activeItem, openDrawer })(NavItem);
