// React
import React, { forwardRef } from 'react';

// Assets
import { CloseCircleOutlined } from '@ant-design/icons';

// Packages
import { AppBar, Dialog, IconButton, Slide, Toolbar, Typography } from '@mui/material';

// Utils
import { drawerTypes } from 'utils/constants';

// ==============================|| SIDE - MODAL||============================== //

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

export default function DrawerModal({
    title,
    open,
    onClose,
    children,
    drawerType,
    setDrawerType,
    setDrawerTitle,
    setMember,
    resetMemberKeys,
    setConsumerId,
    setOverride,
    setEmergency,
    setRegister,
    setVisitor,
    setAccessConfirm,
    setDenyConfirm,
    setActivePanel,
    disableEnforceFocus,
    hideBackdrop
}) {
    return (
        <Dialog
            sx={{ width: '23vw', left: 'auto' }}
            fullScreen
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
            TransitionProps={{
                onExited: () => {
                    if (drawerType === drawerTypes.emergency.type) setActivePanel(null);
                    setDrawerType(null);
                    setDrawerTitle(null);
                    setMember(null);
                    setConsumerId(null);
                    setOverride(false);
                    setEmergency(false);
                    setRegister(null);
                    setVisitor(null);
                    setAccessConfirm(null);
                    setDenyConfirm(null);
                    resetMemberKeys();
                }
            }}
            disableEnforceFocus={disableEnforceFocus}
            disableEscapeKeyDown
            hideBackdrop={hideBackdrop}
        >
            <AppBar
                sx={{
                    position: 'relative',
                    boxShadow: 'none',
                    flexDirection: 'flex-end',
                    backgroundColor:
                        drawerType === drawerTypes.emergency.type
                            ? 'rgba(230, 58, 58, 1)'
                            : ''
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{ ml: 2, flex: 1, textTransform: 'capitalize' }}
                        variant="h3"
                        fontWeight={700}
                        component="div"
                    >
                        {title}
                    </Typography>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                    >
                        <CloseCircleOutlined />
                    </IconButton>
                </Toolbar>
            </AppBar>
            {children}
        </Dialog>
    );
}
