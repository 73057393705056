import {
    SET_OPEN_SNACKBAR,
    SET_CLOSE_SNACKBAR,
    SET_HANDLER_INCREASE,
    SET_HANDLER_DENSE,
    SET_HANDLER_ICON_VARIANTS
} from './types';

export const openSnackbar = snackbar => {
    return {
        type: SET_OPEN_SNACKBAR,
        payload: snackbar
    };
};

export const closeSnackbar = () => {
    return {
        type: SET_CLOSE_SNACKBAR
    };
};

export const handlerIncrease = increase => {
    return {
        type: SET_HANDLER_INCREASE,
        payload: increase
    };
};

export const handlerDense = dense => {
    return {
        type: SET_HANDLER_DENSE,
        payload: dense
    };
};

export const handlerIconVariants = variants => {
    return {
        type: SET_HANDLER_ICON_VARIANTS,
        payload: variants
    };
};
