import {
    all,
    fork,
    take,
    cancelled,
    takeLatest,
    race,
    put,
    call
} from 'redux-saga/effects';

import { eventChannel } from 'redux-saga';

import {
    COMPLETE_DEVICE_OPERATION,
    GET_DEVICE_OPERATIONS,
    LOGOUT_USER
} from '../actions/types';

import { rtdb, db } from 'config/firebase';
import {
    onValue,
    orderByChild,
    query as rtdbQuery,
    ref as rtdbRef,
    set
} from 'firebase/database';

import { collection, doc, updateDoc, getDoc } from 'firebase/firestore';

import {
    getDeviceOperationsSuccess,
    getDeviceOperationsFailure
} from '../actions/DeviceOperations';
import { openSnackbar } from 'store/actions/Snackbar';

// Loggers
import { log } from '../../utils/Loggers';

const propertiesCollectionRef = collection(db, 'properties');

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////// Watch Device Operations //////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* deviceOperationsWatch() {
    const activeOperations = rtdbRef(rtdb, `device_operations`);

    const DeviceOperationsChannel = eventChannel(emit => {
        const query = rtdbQuery(activeOperations, orderByChild('created_at'));
        const unsubscribeDeviceOperationsData = onValue(query, snapshot => {
            const operations = [];
            if (snapshot.exists()) {
                snapshot.forEach(childSnapshot => {
                    operations.push(childSnapshot.val());
                });
                emit(operations);
            } else {
                emit([]);
            }
        });
        return () => {
            unsubscribeDeviceOperationsData();
        };
    });

    const detachSagaEmitters = () => {
        DeviceOperationsChannel.close();
    };

    try {
        while (true) {
            const { userSignOut, operationsData } = yield race({
                userSignOut: take(LOGOUT_USER),
                operationsData: take(DeviceOperationsChannel)
            });

            if (userSignOut) {
                detachSagaEmitters();
            } else if (operationsData) {
                yield put(getDeviceOperationsSuccess(operationsData));
            }
        }
    } catch (error) {
        yield put(getDeviceOperationsFailure(error));
        //TODO: Error Handling
        log('Device Operations Error: getting operations data (RTDB)', {
            error
        });
    } finally {
        // detachFBListeners(); // Detaching firebase listeners
        if (yield cancelled()) {
            detachSagaEmitters(); // Detaching saga event emitter
            // detachFBListeners(); // Detaching firebase listeners
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////// Complete Device Operation /////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const completeDeviceOperationRequest = async ({ operation, type }) => {
    const propertyDocRef = doc(propertiesCollectionRef, operation.property_id);
    const rtdbOperationRef = rtdbRef(rtdb, `/device_operations/${operation.device_id}`);

    try {
        if (type === 'add') {
            const propertyDoc = await getDoc(propertyDocRef);
            if (propertyDoc.exists()) {
                const property = propertyDoc.data();
                const devices = property.devices.map(device => {
                    if (operation.device_id === device.id) {
                        return {
                            ...device,
                            status:
                                device.status === 'pending' ? 'enabled' : device.status
                        };
                    } else {
                        return { ...device };
                    }
                });

                if (devices) {
                    await updateDoc(propertyDocRef, {
                        devices
                    });
                }
            }
        }
        await set(rtdbOperationRef, null);
        return { res: true };
    } catch (error) {
        throw new Error(error);
    }
};

export function* completeDeviceOperation({ payload }) {
    const { operation } = payload;
    const { type } = operation;
    const { res, error } = yield call(() =>
        completeDeviceOperationRequest({
            operation,
            type
        })
    );
    if (res) {
        yield put(
            openSnackbar({
                open: true,
                message: `Success: ${type.toUpperCase()} Device Operation has been completed`,
                variant: 'alert',
                alert: {
                    color: 'success'
                }
            })
        );
    } else {
        yield put(
            openSnackbar({
                open: true,
                message: `Failed: '${type}' Device Operation was not completed successfully.`,
                variant: 'alert',
                alert: {
                    color: 'error'
                }
            })
        );

        log(`Device Operation Error: completing member device operation (FS/RTDB)`, {
            error,
            operation
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Action Creators For Root Saga ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* getDeviceOperations() {
    yield takeLatest(GET_DEVICE_OPERATIONS, deviceOperationsWatch);
}

export function* completingDeviceOperation() {
    yield takeLatest(COMPLETE_DEVICE_OPERATION, completeDeviceOperation);
}

export default function* rootSaga() {
    yield all([fork(getDeviceOperations), fork(completingDeviceOperation)]);
}
