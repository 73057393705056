import {
    GET_ORG_PROPERITES_COLLECTION,
    GET_ORG_PROPERITES_COLLECTION_SUCCESS,
    GET_ORG_PROPERITES_COLLECTION_FAILURE,
    GET_ORG_PROPERITES_BY_SHORT_CODE,
    GET_ORG_PROPERITES_BY_SHORT_CODE_SUCCESS,
    GET_ORG_PROPERITES_BY_SHORT_CODE_FAILURE,
    ADD_ORG_PROPERITES,
    ADD_ORG_PROPERITES_SUCCESS,
    ADD_ORG_PROPERITES_FAILURE,
    GET_PROPERTY_BY_ID,
    GET_PROPERTY_BY_ID_SUCCESS,
    GET_PROPERTY_BY_ID_FAILURE,
    DELETE_PROPERITY,
    DELETE_PROPERITY_SUCCESS,
    DELETE_PROPERITY_FAILURE,
    SELECT_PROPERTY,
    SET_PAGE_INDEX,
    SET_ROW_COUNT,
    SET_ACTIVE_USER_ORG
} from '../actions/types';

const INITIAL_STATE = {
    orgProperties: null,
    loadingOrgProperties: false,
    orgPropertiesError: null,
    addedProperties: null,
    loadingAddProperties: false,
    addPropertiesError: null,
    loading: false,
    propertyError: null,
    deletedPropertyId: null,
    loadingDeleteProperty: false,
    deletePropertyError: null,
    selectedProperty: null,
    registrationOrgProperties: null,
    loadingRegistrationOrgProperties: false,
    registrationOrgPropertiesError: null,
    propertyPage: 0,
    propertyRow: 10
};

const orgReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SELECT_PROPERTY:
            return {
                ...state,
                selectedProperty: action.payload
            };
        case GET_ORG_PROPERITES_COLLECTION:
            return {
                ...state,
                orgProperties: null,
                loadingOrgProperties: true,
                orgPropertiesError: null
            };
        case GET_ORG_PROPERITES_COLLECTION_SUCCESS:
            return {
                ...state,
                orgProperties: action.payload,
                loadingOrgProperties: false,
                orgPropertiesError: null
            };
        case GET_ORG_PROPERITES_COLLECTION_FAILURE:
            return {
                ...state,
                orgProperties: null,
                loadingOrgProperties: false,
                orgPropertiesError: action.payload
            };
        case GET_ORG_PROPERITES_BY_SHORT_CODE:
            return {
                ...state,
                registrationOrgProperties: null,
                loadingRegistrationOrgProperties: true,
                registrationOrgPropertiesError: null
            };
        case GET_ORG_PROPERITES_BY_SHORT_CODE_SUCCESS:
            return {
                ...state,
                registrationOrgProperties: action.payload,
                loadingRegistrationOrgProperties: false,
                registrationOrgPropertiesError: null
            };
        case GET_ORG_PROPERITES_BY_SHORT_CODE_FAILURE:
            return {
                ...state,
                registrationOrgProperties: null,
                loadingRegistrationOrgProperties: false,
                registrationOrgPropertiesError: action.payload
            };
        case ADD_ORG_PROPERITES:
            return {
                ...state,
                addedProperties: null,
                loadingAddProperties: true,
                addPropertiesError: null
            };
        case ADD_ORG_PROPERITES_SUCCESS:
            return {
                ...state,
                addedProperties: action.payload,
                loadingAddProperties: false,
                addPropertiesError: null
            };
        case ADD_ORG_PROPERITES_FAILURE:
            return {
                ...state,
                addedProperties: null,
                loadingAddProperties: false,
                addPropertiesError: action.payload
            };
        case GET_PROPERTY_BY_ID:
            return {
                ...state,
                property: null,
                loading: true
            };
        case GET_PROPERTY_BY_ID_SUCCESS:
            return {
                ...state,
                property: action.payload,
                loading: false,
                propertyError: null
            };
        case GET_PROPERTY_BY_ID_FAILURE:
            return {
                ...state,
                property: null,
                loading: false,
                propertyError: action.payload
            };
        case DELETE_PROPERITY:
            return {
                ...state,
                deletedPropertyId: null,
                loadingDeleteProperty: true,
                deletePropertyError: null
            };
        case DELETE_PROPERITY_SUCCESS:
            return {
                ...state,
                deletedPropertyId: action.payload,
                loadingDeleteProperty: false,
                deletePropertyError: null
            };
        case DELETE_PROPERITY_FAILURE:
            return {
                ...state,
                deletedPropertyId: null,
                loadingDeleteProperty: false,
                deletePropertyError: action.payload
            };
        case SET_PAGE_INDEX:
            return {
                ...state,
                propertyPage:
                    action.payload.type === 'units'
                        ? action.payload.value
                        : state.propertyPage
            };
        case SET_ROW_COUNT:
            return {
                ...state,
                propertyRow:
                    action.payload.type === 'units'
                        ? action.payload.value
                        : state.propertyRow
            };
        case SET_ACTIVE_USER_ORG:
            return {
                ...state,
                propertyPage: INITIAL_STATE.propertyPage,
                propertyRow: INITIAL_STATE.propertyRow
            };
        default:
            return state;
    }
};

export default orgReducer;
