import React from 'react';
// material-ui
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    InputLabel,
    Select,
    Stack,
    TextField,
    MenuItem
} from '@mui/material';

// assets
import 'react-widgets/scss/styles.scss';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { errorMessage, nameRegExp, streetAddressRegExp } from 'utils/constants';
import { db } from 'config/firebase';
import { collection, doc } from 'firebase/firestore';

// ==============================|| CUSTOMER ADD / EDIT / DELETE ||============================== //

const CreateUpdatePropertyModal = ({ onClose, onCreateOrUpdate, property }) => {
    const validationSchema = Yup.object().shape({
        address_1: Yup.string()
            .trim()
            .required(errorMessage.address.required)
            .matches(streetAddressRegExp, errorMessage.address.valid),
        address_2: Yup.string().trim(),
        city: Yup.string().trim().matches(nameRegExp.format, errorMessage.city.valid),
        state: Yup.string().trim(),
        status: Yup.string().trim(),
        zip: Yup.string(),
        latitude: Yup.number().nullable(),
        longitude: Yup.number().nullable()
    });

    const submitForm = formValues => {
        onCreateOrUpdate(formValues);
        onClose();
    };

    return (
        <>
            <DialogTitle>{property ? 'Edit Property' : 'Create Property'}</DialogTitle>
            <Divider />
            <Formik
                initialValues={{
                    id: property?.id || doc(collection(db, 'properties')).id,
                    address_1: property?.address_1 || '',
                    address_2: property?.address_2 || '',
                    city: property?.city || '',
                    state: property?.state || '',
                    zip: property?.zip || '',
                    status: property?.status || 'active',
                    latitude: property?.latitude || null,
                    longitude: property?.longitude || null
                }}
                onSubmit={submitForm}
                validationSchema={validationSchema}
                validateOnChange
            >
                {({
                    handleSubmit,
                    handleChange,
                    touched,
                    handleBlur,
                    errors,
                    values
                }) => (
                    <>
                        <DialogContent sx={{ p: 2.5, minHeight: 360 }}>
                            <Grid container spacing={3}>
                                <Grid item xs={8}>
                                    <Stack spacing={0.5}>
                                        <InputLabel>Main Address</InputLabel>
                                        <TextField
                                            required
                                            id="address_1Basic"
                                            name="address_1"
                                            placeholder="Address line 1"
                                            fullWidth
                                            autoComplete="shipping address-line1"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                touched.address_1 && !!errors.address_1
                                            }
                                            helperText={
                                                touched.address_1 && errors.address_1
                                            }
                                            value={values.address_1}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={4}>
                                    <Stack spacing={0.5}>
                                        <InputLabel>Unit/Suite</InputLabel>
                                        <TextField
                                            id="address_2Basic"
                                            name="address_2"
                                            placeholder="Address line 2"
                                            fullWidth
                                            autoComplete="shipping address-line2"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                touched.address_2 && !!errors.address_2
                                            }
                                            helperText={
                                                touched.address_2 && errors.address_2
                                            }
                                            value={values.address_2}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <Stack spacing={0.5}>
                                        <InputLabel>City</InputLabel>
                                        <TextField
                                            required
                                            id="cityBasic"
                                            name="city"
                                            placeholder="City"
                                            fullWidth
                                            autoComplete="shipping address-level2"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={touched.city && !!errors.city}
                                            helperText={touched.city && errors.city}
                                            value={values.city}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <Stack spacing={0.5}>
                                        <InputLabel>State</InputLabel>
                                        <TextField
                                            id="stateBasic"
                                            name="state"
                                            placeholder="State/Province/Region"
                                            fullWidth
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={touched.state && !!errors.state}
                                            helperText={touched.state && errors.state}
                                            value={values.state}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Stack spacing={0.5}>
                                        <InputLabel>Zip</InputLabel>
                                        <TextField
                                            required
                                            id="zipBasic"
                                            name="zip"
                                            placeholder="Zip / Postal code"
                                            fullWidth
                                            autoComplete="shipping postal-code"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            inputProps={{
                                                inputMode: 'numeric',
                                                pattern: '[0-9]*'
                                            }}
                                            error={touched.zip && !!errors.zip}
                                            helperText={touched.zip && errors.zip}
                                            value={values.zip}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <Stack spacing={0.5}>
                                        <InputLabel>Latitude</InputLabel>
                                        <TextField
                                            required
                                            id="latitude"
                                            name="latitude"
                                            placeholder="Latitude"
                                            type="number"
                                            fullWidth
                                            autoComplete="number"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={touched.latitude && !!errors.latitude}
                                            helperText={
                                                touched.latitude && errors.latitude
                                            }
                                            value={values.latitude}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <Stack spacing={0.5}>
                                        <InputLabel>Longitude</InputLabel>
                                        <TextField
                                            required
                                            id="longitude"
                                            name="longitude"
                                            placeholder="Longitude"
                                            type="number"
                                            fullWidth
                                            autoComplete="number"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                touched.longitude && !!errors.longitude
                                            }
                                            helperText={
                                                touched.longitude && errors.longitude
                                            }
                                            value={values.longitude}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <Stack spacing={0.5}>
                                        <InputLabel>Status</InputLabel>
                                        <Select
                                            id="status"
                                            name="status"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.status}
                                            label="Status"
                                        >
                                            <MenuItem key="active" value="active">
                                                Active
                                            </MenuItem>
                                            <MenuItem key="inactive" value="inactive">
                                                Inactive
                                            </MenuItem>
                                        </Select>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <Divider />
                        <DialogActions sx={{ p: 2.5 }}>
                            <Grid container justifyContent="right" alignItems="center">
                                <Grid item>
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                        alignItems="center"
                                    >
                                        <Button color="error" onClick={onClose}>
                                            Cancel
                                        </Button>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            onClick={handleSubmit}
                                        >
                                            {property ? 'Edit' : 'Add'}
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </>
                )}
            </Formik>
        </>
    );
};

export default CreateUpdatePropertyModal;
