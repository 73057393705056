import {
    GET_ORG_PROPERITES_COLLECTION,
    GET_ORG_PROPERITES_COLLECTION_SUCCESS,
    GET_ORG_PROPERITES_COLLECTION_FAILURE,
    GET_ORG_PROPERITES_BY_SHORT_CODE,
    GET_ORG_PROPERITES_BY_SHORT_CODE_SUCCESS,
    GET_ORG_PROPERITES_BY_SHORT_CODE_FAILURE,
    ADD_ORG_PROPERITES,
    ADD_ORG_PROPERITES_SUCCESS,
    ADD_ORG_PROPERITES_FAILURE,
    GET_PROPERTY_BY_ID,
    GET_PROPERTY_BY_ID_FAILURE,
    GET_PROPERTY_BY_ID_SUCCESS,
    DELETE_PROPERITY,
    DELETE_PROPERITY_SUCCESS,
    DELETE_PROPERITY_FAILURE,
    SELECT_PROPERTY
} from './types';

export const selectProperty = property => {
    return {
        type: SELECT_PROPERTY,
        payload: property
    };
};

export const getOrgPropertiesCollection = org => {
    return {
        type: GET_ORG_PROPERITES_COLLECTION,
        payload: org
    };
};

export const getOrgPropertiesCollectionFailure = error => {
    return {
        type: GET_ORG_PROPERITES_COLLECTION_FAILURE,
        payload: error
    };
};

export const getOrgPropertiesCollectionSuccess = data => {
    return {
        type: GET_ORG_PROPERITES_COLLECTION_SUCCESS,
        payload: data
    };
};

export const getOrgPropertiesByShortCode = shortCode => {
    return {
        type: GET_ORG_PROPERITES_BY_SHORT_CODE,
        payload: shortCode
    };
};

export const getOrgPropertiesByShortCodeFailure = error => {
    return {
        type: GET_ORG_PROPERITES_BY_SHORT_CODE_FAILURE,
        payload: error
    };
};

export const getOrgPropertiesByShortCodeSuccess = data => {
    return {
        type: GET_ORG_PROPERITES_BY_SHORT_CODE_SUCCESS,
        payload: data
    };
};

export const addOrgProperties = payload => {
    return {
        type: ADD_ORG_PROPERITES,
        payload: payload
    };
};

export const addOrgPropertiesFailure = error => {
    return {
        type: ADD_ORG_PROPERITES_FAILURE,
        payload: error
    };
};

export const addOrgPropertiesSuccess = data => {
    return {
        type: ADD_ORG_PROPERITES_SUCCESS,
        payload: data
    };
};

export const deleteProperty = payload => {
    return {
        type: DELETE_PROPERITY,
        payload: payload
    };
};

export const deletePropertyFailure = error => {
    return {
        type: DELETE_PROPERITY_FAILURE,
        payload: error
    };
};

export const deletePropertySuccess = propertyId => {
    return {
        type: DELETE_PROPERITY_SUCCESS,
        payload: propertyId
    };
};

export const getPropertyByIdCollection = propertyId => {
    return {
        type: GET_PROPERTY_BY_ID,
        propertyId: propertyId
    };
};

export const getPropertyByIdCollectionFailure = error => {
    return {
        type: GET_PROPERTY_BY_ID_FAILURE,
        payload: error
    };
};

export const getPropertyByIdCollectionSuccess = data => {
    return {
        type: GET_PROPERTY_BY_ID_SUCCESS,
        payload: data
    };
};
