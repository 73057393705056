import {
    all,
    fork,
    take,
    cancelled,
    takeLatest,
    race,
    put,
    select
} from 'redux-saga/effects';

import { eventChannel } from 'redux-saga';

import {
    GET_LPR_COLLECTION,
    LOGOUT_USER,
    SET_ACTIVE_USER_ORG,
    SET_LPR_QUERY
} from '../actions/types';

import { db, fsTools } from 'config/firebase';
import { getLprCollectionSuccess, getLprCollectionFailure } from '../actions/Lpr';
import * as selectors from './Selectors';
import { openSnackbar } from 'store/actions/Snackbar';

// Loggers
import { log } from '../../utils/Loggers';

// Tools
const { collection, query, onSnapshot, where, orderBy } = fsTools;

// Ref
const lprCollectionRef = collection(db, 'lpr');

////////////// Watch Lpr Collection ////////////////

export function* lprCollectionWatch({ ids }) {
    let unsubscribeLprCollectionData;
    const fromDate = yield select(selectors._fromDate);
    const toDate = yield select(selectors._toDate);
    const lprIds = ids ? ids : yield select(selectors._lprIds);

    const lprCollectionChannel = eventChannel(emit => {
        unsubscribeLprCollectionData = onSnapshot(
            query(
                lprCollectionRef,
                where('anpr.survision_sensor_name', 'in', lprIds),
                where('captured_at', '>=', fromDate),
                where('captured_at', '<=', toDate),
                orderBy('captured_at', 'desc')
            ),
            querySnapshot => {
                const lprDocs = [];
                if (querySnapshot.empty) {
                    emit([]);
                }

                querySnapshot.forEach(doc => {
                    lprDocs.push(doc.data());
                });
                emit(lprDocs);
            }
        );
        return unsubscribeLprCollectionData;
    });

    try {
        while (true) {
            const { userSignOut, resettingOrg, queryLpr, lprCollectionData } = yield race(
                {
                    userSignOut: take(LOGOUT_USER),
                    resettingOrg: take(SET_ACTIVE_USER_ORG),
                    queryLpr: take(SET_LPR_QUERY),
                    lprCollectionData: take(lprCollectionChannel)
                }
            );

            const initialized = yield select(selectors._appInitialized);

            if (userSignOut) {
                lprCollectionChannel.close();
            } else if (resettingOrg || queryLpr) {
                if (initialized) {
                    lprCollectionChannel.close();
                }
            } else {
                yield put(
                    getLprCollectionSuccess({ docs: lprCollectionData, ids: lprIds })
                );
            }
        }
    } catch (error) {
        yield put(getLprCollectionFailure({ error }));
        yield put(
            openSnackbar({
                open: true,
                message: `Failed: Getting plates data.`,
                variant: 'alert',
                alert: {
                    color: 'error'
                }
            })
        );
        log('LPR Docs: Error fetching LPR collection data (FS)', {
            error,
            lprIds
        });
    } finally {
        unsubscribeLprCollectionData();
        if (yield cancelled()) {
            lprCollectionChannel.close();
            unsubscribeLprCollectionData();
        }
    }
}

///////////// Action Creators For Root Saga //////////////////

export function* getLprCollection() {
    yield takeLatest(GET_LPR_COLLECTION, lprCollectionWatch);
}

export default function* rootSaga() {
    yield all([fork(getLprCollection)]);
}
