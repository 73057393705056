import React from 'react';
// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { SafetyCertificateOutlined, IssuesCloseOutlined } from '@ant-design/icons';

// icons
const icons = {
    SafetyCertificateOutlined,
    IssuesCloseOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const virtualGuard = {
    id: 'virtual_guard',
    title: <FormattedMessage id="virtualGuard" />,
    type: 'group',
    children: [
        {
            id: 'virtualGuard',
            title: <FormattedMessage id="virtualGuard" />,
            type: 'item',
            url: '/virtual-guard',
            icon: icons.SafetyCertificateOutlined
        },
        {
            id: 'queue',
            title: <FormattedMessage id="queue" />,
            type: 'item',
            url: '/queue',
            icon: icons.IssuesCloseOutlined
        }
    ]
};

export default virtualGuard;
