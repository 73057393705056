import {
    GENERATE_LIVEKIT_TOKEN,
    GENERATE_LIVEKIT_TOKEN_SUCCESS,
    GENERATE_LIVEKIT_TOKEN_FAILURE,
    RESET_LIVEKIT_TOKEN
} from '../actions/types';

const INITIAL_STATE = {
    loading: null,
    error: null,
    token: null
};

const otcReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GENERATE_LIVEKIT_TOKEN:
            return {
                ...state,
                loading: true,
                error: false
            };
        case GENERATE_LIVEKIT_TOKEN_SUCCESS:
            return {
                ...state,
                token: action.payload,
                loading: false,
                error: false
            };
        case GENERATE_LIVEKIT_TOKEN_FAILURE:
            return {
                ...state,
                token: null,
                loading: false,
                error: action.payload
            };
        case RESET_LIVEKIT_TOKEN:
            return {
                ...state,
                token: null,
                loading: false,
                error: null
            };
        default:
            return state;
    }
};

export default otcReducer;
