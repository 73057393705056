import {
    SET_USER_ORG,
    GET_DEVICE_TYPES,
    GET_DEVICE_TYPES_SUCCESS,
    GET_DEVICE_TYPES_FAILURE,
    GET_ORG_DEVICES_RTDB,
    GET_ORG_DEVICES_RTDB_SUCCESS,
    GET_ORG_DEVICES_RTDB_FAILURE,
    UPDATE_ORG_DEVICE_DETAILS,
    UPDATE_ORG_DEVICE_DETAILS_SUCCESS,
    UPDATE_ORG_DEVICE_DETAILS_FAILURE,
    UPDATE_ORG_DEVICE_DETAILS_RESET,
    GET_ORG_PANELS,
    GET_ORG_PANELS_SUCCESS,
    GET_ORG_PANELS_FAILURE,
    STORE_PANEL_DEVICES_DATA,
    ADD_PANEL,
    ADD_PANEL_FAILURE,
    ADD_PANEL_SUCCESS,
    UPDATE_PANEL,
    UPDATE_PANEL_FAILURE,
    UPDATE_PANEL_SUCCESS,
    REMOVE_PANEL,
    REMOVE_PANEL_FAILURE,
    REMOVE_PANEL_SUCCESS
} from '../actions/types';

const INITIAL_STATE = {
    deviceInfoUpdating: false,
    deviceInfoUpdatingSuccess: false,
    deviceInfoUpdatingError: null,
    deviceTypes: null,
    loadingDeviceTypes: false,
    loadingDeviceTypesError: null,
    orgDevices: null,
    loadingOrgDevices: false,
    loadingOrgDevicesError: null,
    aggregatePanel: null,
    selectedPanel: null,
    managerOrgsDevices: null,
    loadingManagerOrgsDevices: false,
    loadingManagerOrgsDevicesError: null,
    loadingOrgPanels: false,
    loadingOrgPanelsError: null,
    orgPanels: null,
    success: false,
    panelDevicesData: null,
    panelUsers: null,
    createdPanel: null
};

const metricSummation = data => {
    return {
        online_panels: 227,
        offline_panels: 2,
        property_count: data.length,
        active_units: data.reduce((n, { active_units }) => n + active_units, 0),
        active_credentials: data.reduce((n, { credentials }) => n + credentials, 0)
    };
};

const orgReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_USER_ORG:
            return {
                ...state,
                aggregatePanel: metricSummation(action.payload)
            };
        case GET_DEVICE_TYPES:
            return {
                ...state,
                loadingDeviceTypes: true,
                loadingDeviceTypesError: null
            };
        case GET_DEVICE_TYPES_SUCCESS:
            return {
                ...state,
                loadingDeviceTypes: false,
                deviceTypes: action.payload
            };
        case GET_DEVICE_TYPES_FAILURE:
            return {
                ...state,
                loadingDeviceTypes: false,
                loadingDeviceTypesError: action.payload
            };
        case GET_ORG_DEVICES_RTDB:
            return {
                ...state,
                loadingManagerOrgsDevices: true,
                loadingManagerOrgsDevicesError: null
            };
        case GET_ORG_DEVICES_RTDB_SUCCESS:
            return {
                ...state,
                managerOrgsDevices: action.payload,
                loadingManagerOrgsDevices: false
            };
        case GET_ORG_DEVICES_RTDB_FAILURE:
            return {
                ...state,
                loadingManagerOrgsDevicesError: action.payload,
                loadingManagerOrgsDevices: false
            };
        case UPDATE_ORG_DEVICE_DETAILS:
            return {
                ...state,
                deviceInfoUpdating: true,
                deviceInfoUpdatingError: null
            };
        case UPDATE_ORG_DEVICE_DETAILS_SUCCESS:
            return {
                ...state,
                deviceInfoUpdating: false,
                deviceInfoUpdatingSuccess: true
            };
        case UPDATE_ORG_DEVICE_DETAILS_FAILURE:
            return {
                ...state,
                deviceInfoUpdating: false,
                deviceInfoUpdatingError: action.payload
            };
        case UPDATE_ORG_DEVICE_DETAILS_RESET:
            return {
                ...state,
                deviceInfoUpdating: false,
                deviceInfoUpdatingSuccess: false,
                deviceInfoUpdatingError: null
            };
        case GET_ORG_PANELS:
            return {
                ...state,
                loadingOrgPanels: true,
                loadingOrgPanelsError: null,
                orgPanels: null,
                panelUsers: null
            };
        case GET_ORG_PANELS_SUCCESS:
            return {
                ...state,
                orgPanels: action.payload.panels,
                panelUsers: action.payload.panelUsers,
                loadingOrgPanelsError: null,
                loadingOrgPanels: false
            };
        case GET_ORG_PANELS_FAILURE:
            return {
                ...state,
                loadingOrgPanelsError: action.payload,
                loadingOrgPanels: false,
                orgPanels: null,
                panelUsers: null
            };
        case STORE_PANEL_DEVICES_DATA:
            return {
                ...state,
                panelDevicesData: action.payload
            };
        case ADD_PANEL:
            return {
                ...state,
                loading: true,
                success: false
            };
        case ADD_PANEL_SUCCESS:
            return {
                ...state,
                loading: null,
                error: null,
                success: true
            };
        case ADD_PANEL_FAILURE:
            return {
                ...state,
                loading: null,
                error: action.payload
            };
        case UPDATE_PANEL:
            return {
                ...state,
                loading: true,
                success: false
            };
        case UPDATE_PANEL_SUCCESS:
            return {
                ...state,
                loading: null,
                error: null,
                success: true
            };
        case UPDATE_PANEL_FAILURE:
            return {
                ...state,
                loading: null,
                error: action.payload
            };
        case REMOVE_PANEL:
            return {
                ...state,
                loading: true,
                success: false
            };
        case REMOVE_PANEL_SUCCESS:
            return {
                ...state,
                loading: null,
                error: null,
                success: true
            };
        case REMOVE_PANEL_FAILURE:
            return {
                ...state,
                loading: null,
                error: action.payload
            };
        default:
            return state;
    }
};

export default orgReducer;
