// project import
import dashboard from './dashboard';
import virtualGuard from './virtualGuard';
import rentalManager from './rentalManager';
import cloudVideo from './cloudVideo';
import settings from './settings';
import superAdmin from './superAdmin';
import lpr from './lpr';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, virtualGuard, cloudVideo, rentalManager, lpr, superAdmin, settings]
};

export default menuItems;
