import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import configureStore from '../store';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import ThemeCustomization from 'themes';
import Locales from 'components/Locales';
import ScrollTop from 'components/ScrollTop';
import { AuthProvider } from './AuthContext';
import { createReduxHistory } from '../history';

const store = configureStore();
const history = createReduxHistory(store);

const AppProviders = ({ children }) => {
    return (
        <ReduxProvider store={store}>
            <Router history={history}>
                <ThemeCustomization>
                    <Locales>
                        <ScrollTop>
                            <AuthProvider>{children}</AuthProvider>
                        </ScrollTop>
                    </Locales>
                </ThemeCustomization>
            </Router>
        </ReduxProvider>
    );
};

export default AppProviders;
