import React from 'react';
// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { SettingOutlined } from '@ant-design/icons';

// icons
const icons = {
    SettingOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const settings = {
    id: 'settings',
    title: <FormattedMessage id="settings" />,
    type: 'group',
    children: [
        {
            id: 'settingsItem',
            title: <FormattedMessage id="settings" />,
            type: 'item',
            url: '/settings',
            icon: icons.SettingOutlined
        }
    ]
};

export default settings;
