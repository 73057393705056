import React from 'react';
import PropTypes from 'prop-types';

//material-ui
import { styled } from '@mui/material/styles';

// third-party
import { connect } from 'react-redux';
import { SnackbarProvider } from 'notistack';

// assets
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    InfoCircleOutlined,
    WarningOutlined
} from '@ant-design/icons';

// custom styles
const StyledSnackbarProvider = styled(SnackbarProvider)(({ theme }) => ({
    '&.notistack-MuiContent-default': {
        backgroundColor: theme.palette.primary.main
    },
    '&.notistack-MuiContent-error': {
        backgroundColor: theme.palette.error.main
    },
    '&.notistack-MuiContent-success': {
        backgroundColor: theme.palette.success.main
    },
    '&.notistack-MuiContent-info': {
        backgroundColor: theme.palette.info.main
    },
    '&.notistack-MuiContent-warning': {
        backgroundColor: theme.palette.warning.main
    }
}));

const Notistack = ({ children, maxStack, dense, iconVariant }) => {
    const iconSX = { marginRight: 8, fontSize: '1.15rem' };

    return (
        <StyledSnackbarProvider
            maxSnack={maxStack}
            dense={dense}
            iconVariant={
                iconVariant === 'useemojis'
                    ? {
                          success: <CheckCircleOutlined style={iconSX} />,
                          error: <CloseCircleOutlined style={iconSX} />,
                          warning: <WarningOutlined style={iconSX} />,
                          info: <InfoCircleOutlined style={iconSX} />
                      }
                    : undefined
            }
            hideIconVariant={iconVariant === 'hide' ? true : false}
        >
            {children}
        </StyledSnackbarProvider>
    );
};

Notistack.propTypes = {
    children: PropTypes.node
};

const mapStateToProps = ({ snack }) => {
    const { maxStack, dense, iconVariant } = snack;
    return { maxStack, dense, iconVariant };
};

export default connect(mapStateToProps, {})(Notistack);
