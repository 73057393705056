// React
import React from 'react';

// Packages
import { Button, List, Stack, Typography } from '@mui/material';

const Emergency = ({ orgs, panels, activePanel, setActivePanel, setEmergency }) => {
    const handlePanelSelect = (panel, orgId) => {
        const panelData = {
            orgId,
            id: panel.id,
            name: panel.name
        };
        setActivePanel({
            ...panelData
        });
    };

    const handleEmergencyAccess = () => {
        setEmergency(true);
    };

    return (
        <Stack sx={{ p: 3 }}>
            <List key="panels-list">
                {orgs
                    .filter(org =>
                        Object.prototype.hasOwnProperty.call(panels, org.org_id)
                    )
                    .map(org => {
                        const orgId = org.org_id;
                        const orgName = org.org_name;
                        const orgPanels = panels[orgId] || [];

                        return (
                            <Stack key={orgId}>
                                <Typography
                                    variant="h5"
                                    color="secondary"
                                    marginY={2}
                                    fontWeight={700}
                                >
                                    {orgName}
                                </Typography>
                                {orgPanels.map(panel => (
                                    <Button
                                        key={panel.id}
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        sx={{
                                            marginBottom: 2,
                                            backgroundColor:
                                                activePanel && activePanel.id === panel.id
                                                    ? 'rgba(230, 58, 58, 1)'
                                                    : '',
                                            '&:hover': {
                                                backgroundColor: 'rgba(230, 58, 58, 1)'
                                            }
                                        }}
                                        size="large"
                                        onClick={() => handlePanelSelect(panel, orgId)}
                                    >
                                        {panel.name}
                                    </Button>
                                ))}
                            </Stack>
                        );
                    })}
            </List>
            <Stack
                sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%'
                }}
            >
                <Button
                    sx={{
                        position: 'absolute',
                        bottom: '3vh',
                        width: '75%',
                        height: '4.5vh',
                        borderRadius: '26.5px',
                        '&:active::after': {
                            borderRadius: '26.5px'
                        },
                        fontSize: '0.9375rem',
                        fontWeight: '600',
                        backgroundColor: 'rgba(230, 58, 58, 1)',
                        '&:hover': {
                            backgroundColor: 'rgba(215, 43, 43, 1)'
                        },
                        '&:disabled': {
                            backgroundColor: 'rgba(230, 58, 58, .7)'
                        }
                    }}
                    disabled={!activePanel}
                    onClick={handleEmergencyAccess}
                    variant="contained"
                >
                    Grant Emergency Access
                </Button>
            </Stack>
        </Stack>
    );
};

export default Emergency;
