import {
    all,
    fork,
    takeLatest,
    take,
    cancelled,
    race,
    put,
    call,
    select
} from 'redux-saga/effects';

import { eventChannel } from 'redux-saga';

import { db } from 'config/firebase';
import {
    collection,
    where,
    query,
    getDocs,
    serverTimestamp,
    doc,
    setDoc,
    updateDoc,
    onSnapshot
} from 'firebase/firestore';

import * as selectors from './Selectors';
import {
    GET_INTERCOM_COLLECTION,
    CREATE_PANEL_INTERCOM,
    LOGOUT_USER,
    END_PANEL_INTERCOM
} from 'store/actions/types';
import {
    storeIntercomCollection,
    createPanelIntercomSuccess,
    createPanelIntercomFailure,
    endPanelIntercomSuccess,
    endPanelIntercomFailure
} from 'store/actions/Intercom';
import { log } from 'utils/Loggers';
import { openSnackbar } from 'store/actions/Snackbar';

const callsCollectionRef = collection(db, 'calls');
const intercomCollectionRef = collection(db, 'intercom');

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////// Intercom Collection Watch //////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* intercomCollectionWatch(user) {
    let unsubscribeIntercomCollectionData;
    const intercomCollectionQuery = query(
        intercomCollectionRef,
        where('org_id', '==', user.active_org_id),
        where('connected_by', '==', user.uid),
        where('status', 'in', ['pending', 'active'])
    );

    const intercomCollectionChannel = eventChannel(emit => {
        unsubscribeIntercomCollectionData = onSnapshot(
            intercomCollectionQuery,
            querySnapshot => {
                const pending = [];
                const active = [];
                var coms = {};

                querySnapshot.forEach(doc => {
                    const data = doc.data();
                    if (data.status === 'pending') {
                        pending.push(data);
                    } else {
                        active.push(data);
                    }
                });

                coms = { active, pending };
                emit(coms);
            }
        );

        return unsubscribeIntercomCollectionData;
    });

    try {
        while (true) {
            const { userSignOut, intercomCollectionData } = yield race({
                userSignOut: take(LOGOUT_USER),
                intercomCollectionData: take(intercomCollectionChannel)
            });

            if (userSignOut) {
                intercomCollectionChannel.close();
            } else {
                const intercom = yield select(selectors._getActiveIntercom);

                if (
                    intercom &&
                    intercomCollectionData.active.length === 0 &&
                    intercomCollectionData.pending.length === 0
                ) {
                    yield put(endPanelIntercomSuccess());
                }
                yield put(storeIntercomCollection(intercomCollectionData));
            }
        }
    } catch (error) {
        log('Watching Intercom: error fetching Intercom collection data (FS)', {
            error,
            user
        });
    } finally {
        unsubscribeIntercomCollectionData();
        if (yield cancelled()) {
            intercomCollectionChannel.close();
            unsubscribeIntercomCollectionData();
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////// Create Panel Intercom //////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const createIntercomRequest = async ({ com }) => {
    try {
        const intercomId = doc(collection(db, 'intercom')).id;
        const intercomDocRef = doc(intercomCollectionRef, intercomId);
        const data = {
            ...com,
            created_at: serverTimestamp(),
            id: intercomId
        };
        await setDoc(intercomDocRef, data);

        return { res: data };
    } catch (error) {
        return { error };
    }
};

export function* createIntercom({ payload }) {
    const { com } = payload;

    const intercomQuery = yield call(() =>
        getDocs(
            query(
                intercomCollectionRef,
                where('panel_id', '==', com.panel_id),
                where('status', 'in', ['pending', 'active'])
            )
        )
    );

    const callsQuery = yield call(() =>
        getDocs(
            query(
                callsCollectionRef,
                where('panel_id', '==', com.panel_id),
                where('status', 'in', ['pending', 'active'])
            )
        )
    );

    if (!intercomQuery.empty || !callsQuery.empty) {
        yield put(
            openSnackbar({
                open: true,
                message:
                    'Intercom creation aborted. The panel is currently used for another call.',
                variant: 'alert',
                alert: {
                    color: 'error',
                    variant: 'outlined'
                },
                close: false
            })
        );
        yield put(createPanelIntercomFailure());
        console.log(
            'Intercom creation aborted. The panel is currently used for another call.'
        );
        log(
            'Intercom Creation: Aborted due to existing pending or active intercoms or calls',
            { com }
        );
    } else {
        const { res, error } = yield call(() => createIntercomRequest({ com }));
        if (res) {
            yield put(createPanelIntercomSuccess(res));
        } else {
            yield put(createPanelIntercomFailure());
            console.log(error, 'intercom creation error');
            log('Intercom Creation: Error fetching Intercom collection data (FS)', {
                error,
                com
            });
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////// Ending Panel Intercom //////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const endingPanelIntercomRequest = async ({ panelIntercom, userData }) => {
    try {
        const docRef = doc(intercomCollectionRef, panelIntercom.id);
        await updateDoc(docRef, {
            connection_end: serverTimestamp(),
            status: 'completed'
        });

        return { res: true };
    } catch (error) {
        console.log('error', error);

        return { error };
    }
};

export function* endingPanelIntercom({ payload }) {
    const { panelIntercom, userData } = payload;
    const { res, error } = yield call(() =>
        endingPanelIntercomRequest({ panelIntercom, userData })
    );

    if (res) {
        yield put(endPanelIntercomSuccess());
    } else {
        yield put(endPanelIntercomFailure(error));
        log(
            'Ending Intercom: error updating intercom data on ending panel intercom (FS)',
            {
                error,
                panelIntercom,
                userData
            }
        );
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Action Creators For Root Saga ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* getIntercomCollection() {
    yield takeLatest(GET_INTERCOM_COLLECTION, intercomCollectionWatch);
}

export function* creatingPanelIntercom() {
    yield takeLatest(CREATE_PANEL_INTERCOM, createIntercom);
}

export function* endPanelIntercom() {
    yield takeLatest(END_PANEL_INTERCOM, endingPanelIntercom);
}

export default function* rootSaga() {
    yield all([
        fork(getIntercomCollection),
        fork(creatingPanelIntercom),
        fork(endPanelIntercom)
    ]);
}
