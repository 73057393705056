import axios from 'axios';

const oldBaseURL = 'https://api.sagesystems.app/api'; // we will change this when we move everything from jankins
const newBaseURL = `${process.env.REACT_APP_HOST}/api`;

export const apiPost = (url, data, isNew) =>
    axios
        .post(`${isNew ? newBaseURL : oldBaseURL}${url}`, data)
        .then(response => ({ data: response.data }))
        .catch(error => ({ error }));

export const apiPatch = (url, data) =>
    axios
        .patch(`${oldBaseURL}${url}`, data)
        .then(response => {
            return { data: response.data };
        })
        .catch(error => ({ error }));
