import {
    GET_DEVICE_TYPES,
    GET_DEVICE_TYPES_SUCCESS,
    GET_DEVICE_TYPES_FAILURE,
    GET_ORG_DEVICES_RTDB,
    GET_ORG_DEVICES_RTDB_SUCCESS,
    GET_ORG_DEVICES_RTDB_FAILURE,
    UPDATE_ORG_DEVICE_DETAILS,
    UPDATE_ORG_DEVICE_DETAILS_SUCCESS,
    UPDATE_ORG_DEVICE_DETAILS_FAILURE,
    UPDATE_ORG_DEVICE_DETAILS_RESET,
    GET_ORG_PANELS,
    GET_ORG_PANELS_SUCCESS,
    GET_ORG_PANELS_FAILURE,
    STORE_PANEL_DEVICES_DATA,
    ADD_PANEL,
    ADD_PANEL_FAILURE,
    ADD_PANEL_SUCCESS,
    UPDATE_PANEL,
    UPDATE_PANEL_FAILURE,
    UPDATE_PANEL_SUCCESS,
    REMOVE_PANEL,
    REMOVE_PANEL_FAILURE,
    REMOVE_PANEL_SUCCESS
} from './types';

export const getDeviceTypes = () => {
    return {
        type: GET_DEVICE_TYPES
    };
};

export const getDeviceTypesSuccess = types => {
    return {
        type: GET_DEVICE_TYPES_SUCCESS,
        payload: types
    };
};
export const getDeviceTypesFailure = error => {
    return {
        type: GET_DEVICE_TYPES_FAILURE,
        payload: error
    };
};

export const getOrgDevicesRTDB = orgId => {
    return {
        type: GET_ORG_DEVICES_RTDB,
        payload: orgId
    };
};

export const getOrgDevicesRTDBSuccess = devices => {
    return {
        type: GET_ORG_DEVICES_RTDB_SUCCESS,
        payload: devices
    };
};
export const getOrgDevicesRTDBFailure = error => {
    return {
        type: GET_ORG_DEVICES_RTDB_FAILURE,
        payload: error
    };
};

export const updateOrgDeviceDetails = data => {
    return {
        type: UPDATE_ORG_DEVICE_DETAILS,
        payload: data
    };
};

export const updateOrgDeviceDetailsSuccess = () => {
    return {
        type: UPDATE_ORG_DEVICE_DETAILS_SUCCESS
    };
};

export const updateOrgDeviceDetailsFailure = error => {
    return {
        type: UPDATE_ORG_DEVICE_DETAILS_FAILURE,
        payload: error
    };
};

export const updateOrgDeviceDetailsResetState = error => {
    return {
        type: UPDATE_ORG_DEVICE_DETAILS_RESET,
        payload: error
    };
};

export const getOrgPanels = org => {
    return {
        type: GET_ORG_PANELS,
        payload: org
    };
};

export const getOrgPanelsSuccess = panels => {
    return {
        type: GET_ORG_PANELS_SUCCESS,
        payload: panels
    };
};
export const getOrgPanelsFailure = error => {
    return {
        type: GET_ORG_PANELS_FAILURE,
        payload: error
    };
};

export const storePanelDevicesData = panelDevices => {
    return {
        type: STORE_PANEL_DEVICES_DATA,
        payload: panelDevices
    };
};

export const addNewPanel = panelInfo => {
    return {
        type: ADD_PANEL,
        payload: panelInfo
    };
};

export const addNewPanelSuccess = panel => {
    return {
        type: ADD_PANEL_SUCCESS,
        payload: panel
    };
};

export const addNewPanelFailure = error => {
    return {
        type: ADD_PANEL_FAILURE,
        payload: error
    };
};

export const updatePanel = panelInfo => {
    return {
        type: UPDATE_PANEL,
        payload: panelInfo
    };
};

export const updatePanelSuccess = panel => {
    return {
        type: UPDATE_PANEL_SUCCESS,
        payload: panel
    };
};

export const updatePanelFailure = error => {
    return {
        type: UPDATE_PANEL_FAILURE,
        payload: error
    };
};

export const removePanel = panel => {
    return {
        type: REMOVE_PANEL,
        payload: panel
    };
};

export const removePanelSuccess = panel => {
    return {
        type: REMOVE_PANEL_SUCCESS,
        payload: panel
    };
};

export const removePanelFailure = error => {
    return {
        type: REMOVE_PANEL_FAILURE,
        payload: error
    };
};
