import {
    SET_USER_COLLECTION,
    SET_ACTIVE_USER_ORG,
    SET_ACTIVE_USER_ORG_PERMISSIONS
} from './types';

export const storeUserCollection = user => {
    return {
        type: SET_USER_COLLECTION,
        payload: user
    };
};

export const setActiveManagerOrg = orgId => {
    return {
        type: SET_ACTIVE_USER_ORG,
        payload: orgId
    };
};

export const settingActivePermissions = perimissions => {
    return {
        type: SET_ACTIVE_USER_ORG_PERMISSIONS,
        payload: perimissions
    };
};
