// React
import React from 'react';

// Assets
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';

// Packages
import {
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Stack,
    Typography
} from '@mui/material';
import dayjs from 'dayjs';

// Styles
import 'react-widgets/scss/styles.scss';

const LprDetailsModal = ({ onClose, lpr }) => {
    const isToday = ts => {
        const lprDate = ts.toDate();
        const today = dayjs().isSame(lprDate, 'day');
        return today ? `Today, ` : ``;
    };

    return (
        <>
            <DialogTitle>{lpr?.plate?.trim().length ? lpr.plate : 'N/A'}</DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5, height: 500 }}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Stack spacing={0.5}>
                            <>
                                <Typography align="left" variant="subtitle1">
                                    Entry:
                                </Typography>
                                <Typography
                                    align="left"
                                    variant="subtitle1"
                                    color="text.secondary"
                                >
                                    {`${isToday(lpr?.captured_at)} ${lpr?.date_string}`}
                                </Typography>
                            </>
                            <>
                                <Typography align="left" variant="subtitle1">
                                    Make:
                                </Typography>
                                <Typography
                                    align="left"
                                    variant="subtitle1"
                                    color="text.secondary"
                                >
                                    {lpr?.make?.trim().length ? lpr.make : 'N/A'}
                                </Typography>
                            </>
                            <>
                                <Typography align="left" variant="subtitle1">
                                    Model:
                                </Typography>
                                <Typography
                                    align="left"
                                    variant="subtitle1"
                                    color="text.secondary"
                                >
                                    {lpr?.model?.trim().length ? lpr.model : 'N/A'}
                                </Typography>
                            </>
                            <>
                                <Typography align="left" variant="subtitle1">
                                    Year:
                                </Typography>
                                <Typography
                                    align="left"
                                    variant="subtitle1"
                                    color="text.secondary"
                                >
                                    {lpr?.year?.trim().length ? lpr.year : 'N/A'}
                                </Typography>
                            </>
                            <>
                                <Typography align="left" variant="subtitle1">
                                    Color:
                                </Typography>
                                <Typography
                                    align="left"
                                    variant="subtitle1"
                                    color="text.secondary"
                                >
                                    {lpr?.color?.trim().length
                                        ? lpr.color.toLowerCase() === 'unknown'
                                            ? 'N/A'
                                            : lpr.color
                                        : 'N/A'}
                                </Typography>
                            </>
                            <>
                                <Typography align="left" variant="subtitle1">
                                    Country/State:
                                </Typography>
                                <Typography
                                    align="left"
                                    variant="subtitle1"
                                    color="text.secondary"
                                >
                                    {lpr?.state?.trim().length ? lpr.state : 'N/A'}
                                </Typography>
                            </>
                        </Stack>
                    </Grid>
                    <Grid item xs={6}>
                        <Stack spacing={0.5}>
                            <Stack
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                    width: '100%'
                                }}
                            >
                                {lpr?.img ? (
                                    <img
                                        style={{
                                            width: '100%',
                                            objectFit: 'fill'
                                        }}
                                        src={`data:image/png;base64, ${lpr.img}`}
                                        loading="lazy"
                                        alt={'lpr capture'}
                                    />
                                ) : (
                                    <>
                                        <DirectionsCarIcon
                                            style={{
                                                color: '#8c8c8c',
                                                fontSize: '25vw'
                                            }}
                                        />
                                        <Typography
                                            align="left"
                                            variant="h3"
                                            color="text.secondary"
                                        >
                                            No Image Data
                                        </Typography>
                                    </>
                                )}
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
            </DialogContent>
        </>
    );
};

export default LprDetailsModal;
