import {
    GET_PROPERTY_MEMBERS,
    GET_PROPERTY_MEMBERS_SUCCESS,
    GET_PROPERTY_MEMBERS_FAILURE,
    GET_INVITED_PROPERTY_MEMBERS_SUCCESS,
    GET_INVITED_PROPERTY_MEMBERS_FAILURE,
    GET_MEMBER_KEYS,
    GET_MEMBER_KEYS_SUCCESS,
    GET_MEMBER_KEYS_FAILURE,
    RESET_PROPERTY_MEMBERS,
    RESET_MEMBER_KEYS,
    ALLOW_GATE_ACCESS,
    ALLOW_GATE_ACCESS_SUCCESS,
    ALLOW_GATE_ACCESS_FAILURE,
    DISALLOW_GATE_ACCESS,
    DISALLOW_GATE_ACCESS_SUCCESS,
    DISALLOW_GATE_ACCESS_FAILURE
} from './types';

export const getPropertyMembers = id => {
    return {
        type: GET_PROPERTY_MEMBERS,
        payload: id
    };
};

export const getPropertyMembersSuccess = members => {
    return {
        type: GET_PROPERTY_MEMBERS_SUCCESS,
        payload: members
    };
};

export const getPropertyMembersFailure = error => {
    return {
        type: GET_PROPERTY_MEMBERS_FAILURE,
        payload: error
    };
};

export const getInvitedPropertyMembersSuccess = members => {
    return {
        type: GET_INVITED_PROPERTY_MEMBERS_SUCCESS,
        payload: members
    };
};

export const getInvitedPropertyMembersFailure = error => {
    return {
        type: GET_INVITED_PROPERTY_MEMBERS_FAILURE,
        payload: error
    };
};

export const getMemberKeys = data => {
    return {
        type: GET_MEMBER_KEYS,
        payload: data
    };
};

export const getMemberKeysSuccess = keys => {
    return {
        type: GET_MEMBER_KEYS_SUCCESS,
        payload: keys
    };
};

export const getMemberKeysFailure = error => {
    return {
        type: GET_MEMBER_KEYS_FAILURE,
        payload: error
    };
};

export const resetPropertyMembers = () => {
    return {
        type: RESET_PROPERTY_MEMBERS
    };
};

export const resetMemberKeys = () => {
    return {
        type: RESET_MEMBER_KEYS
    };
};

export const allowGateAccess = data => {
    return {
        type: ALLOW_GATE_ACCESS,
        payload: data
    };
};

export const allowGateAccessSuccess = () => {
    return {
        type: ALLOW_GATE_ACCESS_SUCCESS
    };
};

export const allowGateAccessFailure = error => {
    return {
        type: ALLOW_GATE_ACCESS_FAILURE,
        payload: error
    };
};

export const disallowGateAccess = data => {
    return {
        type: DISALLOW_GATE_ACCESS,
        payload: data
    };
};

export const disallowGateAccessSuccess = () => {
    return {
        type: DISALLOW_GATE_ACCESS_SUCCESS
    };
};

export const disallowGateAccessFailure = error => {
    return {
        type: DISALLOW_GATE_ACCESS_FAILURE,
        payload: error
    };
};
