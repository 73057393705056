import React, { useEffect, useState } from 'react';

// material-ui
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

// assets
import { GlobalOutlined } from '@ant-design/icons';
import { connect, useDispatch } from 'react-redux';
import { setActiveManagerOrg } from 'store/actions/User';
import { userTypes } from 'utils/constants';

// ==============================|| HEADER PROFILE - SETTING TAB ||============================== //

const OrgsTab = ({ orgs, userData, activeOrg, isActive }) => {
    const dispatch = useDispatch();
    const [showOrgs, setShowOrgs] = useState([]);

    useEffect(() => {
        if (userData.type === userTypes.SUPER_ADMIN && orgs) {
            const primOrg = orgs.find(org => org.org_id === userData.active_org_id);
            setShowOrgs([primOrg]);
        } else {
            setShowOrgs(orgs);
        }
    }, [orgs, userData]);

    return (
        <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
            {showOrgs.map(org => (
                <ListItemButton
                    key={org.org_id}
                    selected={activeOrg?.org_id === org.org_id}
                    disabled={isActive()}
                    onClick={() => {
                        if (activeOrg?.org_id !== org.org_id)
                            dispatch(setActiveManagerOrg(org));
                    }}
                >
                    <ListItemIcon>
                        <GlobalOutlined />
                    </ListItemIcon>
                    <ListItemText primary={org.org_name} />
                </ListItemButton>
            ))}
        </List>
    );
};

const mapStateToProps = ({ org, user }) => {
    const { orgs } = org;
    const { userData, activeOrg } = user;
    return { orgs, userData, activeOrg };
};

export default connect(mapStateToProps, {})(OrgsTab);
