import React from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';

import logoIconDark from 'assets/images/logo.svg';
import logoIcon from 'assets/images/logo-icon.svg';
import { ThemeMode } from 'config';

// ==============================|| LOGO ICON SVG ||============================== //

const LogoIcon = () => {
    const theme = useTheme();

    return (
        <img
            src={theme.palette.mode === ThemeMode.DARK ? logoIconDark : logoIcon}
            alt="SAGE"
            width="100"
        />
    );
};

export default LogoIcon;
