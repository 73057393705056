// React
import React, { useState, useEffect } from 'react';

// Packages
import { Button, List, Stack, Typography } from '@mui/material';

const Intercom = ({
    orgs,
    panels,
    userData,
    creatingPanelIntercom,
    handleClose,
    setActiveManagerOrg,
    changingFocus
}) => {
    const [preppingIntercomState, setPreppingIntercomState] = useState(false);
    const [com, setCom] = useState(null);

    const handleCreatingIntercom = com => {
        if (userData.active_org_id !== com.org_id) {
            const org = orgs.find(org => org.org_id === com.org_id);
            setActiveManagerOrg(org);
            setCom(com);
            setPreppingIntercomState(true);
        } else {
            setPreppingIntercomState(true);
            setCom(com);
        }
    };

    useEffect(() => {
        if (preppingIntercomState) {
            if (com && com.org_id === userData.active_org_id && !changingFocus) {
                creatingPanelIntercom({ com });
                handleClose();
                setPreppingIntercomState(false);
                setCom(null);
            }
        }
    }, [
        preppingIntercomState,
        userData,
        com,
        changingFocus,
        creatingPanelIntercom,
        setPreppingIntercomState,
        setCom,
        handleClose
    ]);

    return (
        <Stack sx={{ p: 3 }}>
            <List key="panels-list">
                {orgs
                    .filter(org =>
                        Object.prototype.hasOwnProperty.call(panels, org.org_id)
                    )
                    .map(org => {
                        const orgId = org.org_id;
                        const orgName = org.org_name;
                        const orgPanels = panels[orgId] || [];

                        return (
                            <Stack key={orgId}>
                                <Typography
                                    variant="h5"
                                    color="secondary"
                                    marginY={2}
                                    fontWeight={700}
                                >
                                    {orgName}
                                </Typography>
                                {orgPanels.map(panel => (
                                    <Button
                                        key={panel.id}
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        sx={{ marginBottom: 2 }}
                                        size="large"
                                        onClick={() => {
                                            handleCreatingIntercom({
                                                connected_by: userData.uid,
                                                connection_end: null,
                                                connection_start: null,
                                                org_id: orgId,
                                                panel_id: panel.id,
                                                panel_name: panel.name,
                                                status: 'pending'
                                            });
                                        }}
                                    >
                                        {panel.name}
                                    </Button>
                                ))}
                            </Stack>
                        );
                    })}
            </List>
        </Stack>
    );
};

export default Intercom;
