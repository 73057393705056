import React from 'react';

import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import LogoIcon from 'components/logo/LogoIcon';

const ResiLayout = () => {
    return (
        <Box
            height="100vh"
            display="flex"
            flexDirection="column"
            alignItems="center"
            bgcolor="#252c33"
            padding="2rem 0"
        >
            <Box marginTop={2} marginBottom={3}>
                <LogoIcon />
            </Box>
            <Outlet />
        </Box>
    );
};

export default ResiLayout;
