import { call, put } from 'redux-saga/effects';
import { db, func } from 'config/firebase';
import {
    collection,
    doc,
    getDocs,
    query,
    where,
    setDoc,
    getDoc
} from 'firebase/firestore';

import { httpsCallable } from 'firebase/functions';
import { getOrgById, updateOrgManagers } from './Org';
import { addNewMemberFailure, addNewMemberSuccess } from 'store/actions/Managers';

const usersCollectionRef = collection(db, 'users');

const createNewAccess = httpsCallable(func, 'createNewManagerRequest');

////////////// Manager saga ///////////////////////

export function* createNewAuthUsers(newUsers) {
    try {
        const newAuthUsers = yield call(createNewAccess, { members: newUsers });
        return newAuthUsers?.data?.processed;
    } catch (err) {
        console.error(err);
    }
}

function* checkUserExists(userObject) {
    try {
        const q = query(usersCollectionRef, where('email', '==', userObject.email));
        const querySnapshot = yield call(getDocs, q);

        let userExists = false;
        const existingUserData = [];

        if (querySnapshot.size) {
            userExists = true;
            querySnapshot.forEach(doc => {
                existingUserData.push(doc.data());
            });
        }
        return { userExists, existingUserData };
    } catch (error) {
        console.log('Error getting documents: ', error);
    }
}

export function* getExistingOrNewUser(userObject) {
    const { userExists, existingUserData } = yield call(checkUserExists, userObject);

    if (userExists) {
        return { existingUser: existingUserData[0] };
    } else {
        return { newUser: userObject };
    }
}

export async function getExistingAndNewUsers(userObjects, db) {
    try {
        const newUsers = [];
        const queryPromises = userObjects.map(manager => {
            const q = query(usersCollectionRef, where('email', '==', manager.email));

            return getDocs(q)
                .then(querySnapshot => {
                    if (querySnapshot.empty) {
                        newUsers.push(manager);
                    }

                    return querySnapshot.docs.map(doc => doc.data());
                })
                .catch(error => {
                    console.log('Error getting documents: ', error);
                });
        });

        const existingUsers = await Promise.all(queryPromises).then(res => {
            return res.flat();
        });

        return { newUsers, existingUsers };
    } catch (err) {
        console.error(err);
    }
}

export function* addNewOrgManagerDocument({ payload }) {
    try {
        const { memberInfo, org_id } = payload;
        const { newUsers, existingUsers } = yield getExistingAndNewUsers([memberInfo]);

        if (newUsers.length) {
            const processed = yield createNewAuthUsers(newUsers);
            const orgData = yield getOrgById(org_id);

            if (processed) {
                const usersToAdd = newUsers.map(user => {
                    const matchingAuthUser = processed.find(
                        authUser => authUser.email === user.email
                    );

                    const newUserDocument = {
                        active_call: null,
                        voip_token: null,
                        phone: user.mobilePhone || null,
                        email: matchingAuthUser.email,
                        type: user.type,
                        first_name: user.first_name || null,
                        last_name: user.last_name || null,
                        notifications: {
                            vendors: true,
                            org_vendors: true,
                            family: true,
                            guests: true,
                            news: true,
                            favorites: true
                        },
                        uid: matchingAuthUser.id,
                        role: 'operator',
                        primary_org: org_id,
                        avatar: null,
                        fcm_token: null,
                        org_ids: [org_id],
                        orgs: {
                            [org_id]: {
                                property_id: null,
                                notifications: true,
                                org_id,
                                address: orgData?.address
                            }
                        },
                        twilio: null
                    };

                    return newUserDocument;
                });

                usersToAdd.forEach(newUser => {
                    setDoc(doc(db, 'users', newUser.uid), newUser).catch(error => {
                        console.error(`Error creating user: ${error}`);
                    });

                    updateOrgManagers(org_id, newUser.uid, newUser);
                });
            }
        }

        if (existingUsers.length) {
            existingUsers.forEach(user => {
                updateManagerOrgs(user.uid, org_id);
                updateOrgManagers(org_id, user.uid, user);
            });
        }

        yield put(addNewMemberSuccess(memberInfo.email));
    } catch (err) {
        console.error(err);

        yield put(addNewMemberFailure(err));
    }
}

function* updateManagerOrgs({ userId, orgId }) {
    try {
        const userRef = doc(db, 'users', userId);

        const orgDataSnapshot = yield call(getDoc, doc(db, 'orgs', orgId));
        const orgData = orgDataSnapshot.data();

        const userDataSnapshot = yield call(getDoc, userRef);
        const userData = userDataSnapshot.data();

        const userOrgIds = userData?.org_ids || [];
        const alreadyManagerOfThisOrg = userData?.properties?.[orgId] !== undefined;

        if (!alreadyManagerOfThisOrg) {
            const updatedOrgs = {
                ...userData?.orgs,
                [orgId]: {
                    property_id: null,
                    notifications: true,
                    org_id: orgId,
                    address: orgData?.address
                }
            };

            yield call(
                setDoc,
                userRef,
                { orgs: updatedOrgs, org_ids: [...userOrgIds, orgId] },
                { merge: true }
            );
        }
    } catch (err) {
        console.error(err);
        // Optionally dispatch an action to handle the error, e.g.,:
        // yield put(yourErrorAction(err));
    }
}
