import { useEffect, useRef } from 'react';
import CallChime from '../../assets/sounds/ringtone.mp3';

const CallSound = ({ playChime }) => {
    const chimeEl = useRef(null);

    useEffect(() => {
        if (playChime) {
            playRingtone();
        } else {
            stopRingtone();
        }

        return () => {
            stopRingtone();
        };
    }, [playChime]);

    const playRingtone = () => {
        chimeEl.current = new Audio(CallChime);
        chimeEl.current.loop = true;
        chimeEl.current.play().catch(error => {
            console.error('Failed to play ringtone:', error);
        });
    };

    const stopRingtone = () => {
        if (chimeEl.current) {
            chimeEl.current.pause();
            chimeEl.current.currentTime = 0;
        }
    };

    return null;
};

export default CallSound;
