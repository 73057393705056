// React
import React, { useEffect, useState } from 'react';

// Assets
import { DeleteFilled, CloseCircleOutlined } from '@ant-design/icons';

// Components
import IconButton from 'components/@extended/IconButton';

// Firebase
import { fsTimeStampNow } from 'config/firebase';

// Packages
import { Loader } from 'components';
import {
    Box,
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Tooltip,
    Typography,
    FormHelperText,
    FormControl,
    FormControlLabel
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { Formik } from 'formik';
import * as Yup from 'yup';
import 'yup-phone-lite';

// Styles
import 'react-widgets/scss/styles.scss';

// Utils
import { errorMessage, unitedStates } from 'utils/constants';

const CreateUpdateDeviceModal = ({
    onClose,
    selectedDevice,
    members,
    property,
    type,
    deviceConfig,
    success,
    addEditingMemberDevice,
    removingMemberDevice,
    refreshPropertyData
}) => {
    const [selectedMember, setSelectedMember] = useState(null);
    const [addNewVehicle, setAddNewVehicle] = useState(false);
    const [vehicles, setVehicles] = useState([
        { key: 'addNew', value: '+ Add New Vehicle' }
    ]);

    const currentDateTime = new Date();

    const getDefDateTime = () => {
        const dateCopy = new Date(currentDateTime.getTime());
        dateCopy.setDate(dateCopy.getDate() + 1);
        dateCopy.setHours(0, 0, 0, 0);
        return dateCopy;
    };

    const getNoExpireTime = () => {
        const date = new Date('December 31, 2099');
        return date;
    };

    useEffect(() => {
        if (selectedMember && selectedMember.vehicles && selectedMember.vehicles.length) {
            const memberVehicles = selectedMember.vehicles.map(vehicle => {
                return {
                    key: vehicle.tag,
                    value: `${vehicle.year} ${vehicle.make} ${vehicle.model} ${vehicle.tag}`
                };
            });
            setVehicles([
                { key: 'addNew', value: '+ Add New Vehicle' },
                ...memberVehicles
            ]);
        } else {
            setVehicles([{ key: 'addNew', value: '+ Add New Vehicle' }]);
        }
    }, [selectedMember, setVehicles]);

    useEffect(() => {
        if (selectedDevice && selectedDevice.resident) {
            const selectMember = members.find(
                member =>
                    `${member.first_name} ${member.last_name}`.toLowerCase() ===
                    `${selectedDevice.resident.first_name} ${selectedDevice.resident.last_name}`.toLowerCase()
            );
            if (selectMember) {
                setSelectedMember(selectMember);
            }
        }
    }, [selectedDevice, members, setSelectedMember]);

    const submit = values => {
        const {
            access_group,
            // disabled,
            device_number,
            device_type,
            expires_at,
            facility_code,
            has_expire,
            resident,
            vehicle,
            vehicle_color,
            vehicle_make,
            vehicle_model,
            vehicle_state,
            vehicle_tag,
            vehicle_year
        } = values;

        const getResident = () => {
            const selectMember = members.find(
                member =>
                    `${member.first_name} ${member.last_name}`.trim().toLowerCase() ===
                    resident.trim().toLowerCase()
            );
            return selectMember;
        };

        const resi = getResident();

        const getVehicle = () => {
            if (device_type === 'Key Fob') {
                return null;
            } else if (addNewVehicle) {
                return {
                    color: vehicle_color,
                    country: 'USA',
                    make: vehicle_make,
                    model: vehicle_model,
                    primary: resi.vehicles.length ? false : true,
                    state: vehicle_state,
                    tag: vehicle_tag.toUpperCase(),
                    year: vehicle_year
                };
            } else {
                const foundVehicle = resi.vehicles.filter(
                    memberVehicle =>
                        `${memberVehicle.year} ${memberVehicle.make} ${memberVehicle.model} ${memberVehicle.tag}`
                            .trim()
                            .toLowerCase() === vehicle.trim().toLowerCase()
                )[0];
                return {
                    color: foundVehicle.color,
                    country: foundVehicle.country,
                    make: foundVehicle.make,
                    model: foundVehicle.model,
                    primary: foundVehicle.primary,
                    state: foundVehicle.state,
                    tag: foundVehicle.tag,
                    year: foundVehicle.year
                };
            }
        };

        const device = {
            access_group,
            created_at:
                selectedDevice &&
                selectedDevice.status &&
                selectedDevice.status !== 'pending'
                    ? selectedDevice.created_at
                    : fsTimeStampNow(),
            device_number,
            device_type,
            expires_at: has_expire ? expires_at : null,
            facility_code,
            group_name: deviceConfig.group_name,
            id: type === 'edit' ? selectedDevice.id : null,
            resident: {
                first_name: resi.first_name,
                last_name: resi.last_name,
                uid: resi.uid
            },
            // status: type === 'add' ? 'pending' : disabled ? 'disabled' : 'enabled',
            status: 'enabled',
            vehicle:
                (vehicle.trim() === '' && !addNewVehicle) ||
                (addNewVehicle && vehicle_tag.trim() === '')
                    ? null
                    : getVehicle()
        };

        addEditingMemberDevice({
            device,
            newVehicle:
                addNewVehicle && vehicle_tag.trim() !== '' ? addNewVehicle : false,
            property,
            type,
            vehicles: resi.vehicles,
            plates: resi.plates
        });
    };

    const handleRemove = () => {
        removingMemberDevice({
            device: selectedDevice,
            property,
            type: 'remove'
        });
    };

    useEffect(() => {
        if (success) {
            refreshPropertyData();
        }
    }, [success, refreshPropertyData]);

    const initialValues = {
        access_group: selectedDevice?.access_group || '',
        disabled:
            type === 'add' ? false : selectedDevice.status === 'disabled' ? true : false,
        device_number: selectedDevice?.device_number || '',
        device_type: selectedDevice?.device_type || '',
        expires_at: selectedDevice?.expires_at
            ? selectedDevice.expires_at.toDate()
            : getNoExpireTime(),
        facility_code: selectedDevice?.facility_code || '',
        has_expire: selectedDevice?.expires_at ? true : false,
        resident: selectedDevice?.resident
            ? `${selectedDevice.resident.first_name} ${selectedDevice.resident.last_name}`
            : '',
        vehicle: selectedDevice?.vehicle
            ? `${selectedDevice.vehicle.year} ${selectedDevice.vehicle.make} ${selectedDevice.vehicle.model} ${selectedDevice.vehicle.tag}`
            : '',
        vehicle_color: selectedDevice?.vehicle ? selectedDevice.vehicle.color : '',
        vehicle_make: selectedDevice?.vehicle ? selectedDevice.vehicle.make : '',
        vehicle_model: selectedDevice?.vehicle ? selectedDevice.vehicle.model : '',
        vehicle_state: selectedDevice?.vehicle ? selectedDevice.vehicle.state : '',
        vehicle_tag: selectedDevice?.vehicle ? selectedDevice.vehicle.tag : '',
        vehicle_year: selectedDevice?.vehicle ? selectedDevice.vehicle.year : ''
    };

    const validationSchema = Yup.object().shape(
        {
            access_group: Yup.string()
                .trim()
                .required(errorMessage.device.access_group.required),
            disabled: Yup.boolean(),
            device_number: Yup.string()
                .trim()
                .required(errorMessage.device.device_number.required),
            device_type: Yup.string()
                .trim()
                .required(errorMessage.device.device_type.required),
            expires_at: Yup.string()
                .trim()
                .required(errorMessage.device.expires_at.required),
            facility_code: Yup.string()
                .trim()
                .required(errorMessage.device.facility_code.required),
            has_expire: Yup.boolean(),
            resident: Yup.string()
                .trim()
                .lowercase()
                .required(errorMessage.device.resident.required),
            vehicle: Yup.string(),
            vehicle_color: Yup.string(),
            vehicle_make: Yup.string(),
            vehicle_model: Yup.string(),
            vehicle_state: Yup.string(),
            vehicle_tag: Yup.string(),
            vehicle_year: Yup.string()

            // vehicle: Yup.string()
            //     .trim()
            //     .lowercase()
            //     .when('device_type', {
            //         is: device_type =>
            //             device_type?.length !== 0 &&
            //             device_type !== 'Key Fob' &&
            //             !addNewVehicle,
            //         then: () =>
            //             Yup.string()
            //                 .trim()
            //                 .required(errorMessage.device.vehicle.required),
            //         otherwise: () => Yup.string()
            //     }),
            // vehicle_color: Yup.string()
            //     .trim()
            //     .lowercase()
            //     .when('device_type', {
            //         is: device_type =>
            //             (device_type?.length === 0 && device_type !== 'Key Fob') ||
            //             addNewVehicle,
            //         then: () =>
            //             Yup.string()
            //                 .trim()
            //                 .required(errorMessage.device.vehicle.color.required),
            //         otherwise: () => Yup.string()
            //     }),
            // vehicle_make: Yup.string()
            //     .trim()
            //     .lowercase()
            //     .when('device_type', {
            //         is: device_type =>
            //             (device_type?.length === 0 && device_type !== 'Key Fob') ||
            //             addNewVehicle,
            //         then: () =>
            //             Yup.string()
            //                 .trim()
            //                 .required(errorMessage.device.vehicle.make.required),
            //         otherwise: () => Yup.string()
            //     }),
            // vehicle_model: Yup.string()
            //     .trim()
            //     .lowercase()
            //     .when('device_type', {
            //         is: device_type =>
            //             (device_type?.length === 0 && device_type !== 'Key Fob') ||
            //             addNewVehicle,
            //         then: () =>
            //             Yup.string()
            //                 .trim()
            //                 .required(errorMessage.device.vehicle.model.required),
            //         otherwise: () => Yup.string()
            //     }),
            // vehicle_state: Yup.string()
            //     .trim()
            //     .lowercase()
            //     .when('device_type', {
            //         is: device_type =>
            //             (device_type?.length === 0 && device_type !== 'Key Fob') ||
            //             addNewVehicle,
            //         then: () =>
            //             Yup.string()
            //                 .trim()
            //                 .required(errorMessage.device.vehicle.state.required),
            //         otherwise: () => Yup.string()
            //     }),
            // vehicle_tag: Yup.string()
            //     .trim()
            //     .when('device_type', {
            //         is: device_type =>
            //             (device_type?.length === 0 && device_type !== 'Key Fob') ||
            //             addNewVehicle,
            //         then: () =>
            //             Yup.string()
            //                 .trim()
            //                 .required(errorMessage.device.vehicle.tag.required),
            //         otherwise: () => Yup.string()
            //     }),
            // vehicle_year: Yup.string().when('device_type', {
            //     is: device_type =>
            //         (device_type?.length === 0 && device_type !== 'Key Fob') ||
            //         addNewVehicle,
            //     then: () =>
            //         Yup.string()
            //             .matches(
            //                 fourDigitYear.format,
            //                 errorMessage.device.vehicle.year.format
            //             )
            //             .required(errorMessage.device.vehicle.year.required),
            //     otherwise: () => Yup.string()
            // })
        },
        [
            ['vehicle', 'device_type'],
            ['device_type', 'vehicle'],
            ['device_type', 'vehicle_color'],
            ['vehicle_color', 'device_type'],
            ['device_type', 'vehicle_make'],
            ['vehicle_make', 'device_type'],
            ['device_type', 'vehicle_model'],
            ['vehicle_model', 'device_type'],
            ['device_type', 'vehicle_state'],
            ['vehicle_state', 'device_type'],
            ['device_type', 'vehicle_tag'],
            ['vehicle_tag', 'device_type'],
            ['device_type', 'vehicle_year'],
            ['vehicle_year', 'device_type']
        ]
    );

    const handleFobDevice = setFieldValue => {
        setFieldValue('vehicle', '');
        setFieldValue('vehicle_color', '');
        setFieldValue('vehicle_make', '');
        setFieldValue('vehicle_model', '');
        setFieldValue('vehicle_state', '');
        setFieldValue('vehicle_tag', '');
        setFieldValue('vehicle_year', '');
        setAddNewVehicle(false);
    };

    if (!deviceConfig) return <Loader />;
    if (selectedDevice && selectedDevice.vehicle && vehicles.length === 1)
        return <Loader />;

    return (
        <>
            <DialogTitle>
                {type === 'edit' ? 'Edit Device' : 'Add New Device'}
            </DialogTitle>
            <Divider />
            <Formik
                initialValues={initialValues}
                onSubmit={submit}
                validationSchema={validationSchema}
                validateOnChange
            >
                {({
                    handleSubmit,
                    handleChange,
                    touched,
                    setFieldValue,
                    handleBlur,
                    errors,
                    values
                }) => (
                    <>
                        <DialogContent sx={{ p: 2.5, maxHeight: '70vh' }}>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        sx={{ marginBottom: '20px' }}
                                    >
                                        <Stack spacing={0.5}>
                                            <InputLabel htmlFor="resident">
                                                Resident
                                            </InputLabel>
                                            <Select
                                                required
                                                id="residentSelect"
                                                name="resident"
                                                sx={{
                                                    textTransform: 'capitalize'
                                                }}
                                                onChange={e => {
                                                    const selectMember = members.find(
                                                        member =>
                                                            `${member.first_name} ${member.last_name}` ===
                                                            e.target.value
                                                    );
                                                    if (selectMember) {
                                                        setFieldValue(
                                                            'resident',
                                                            `${selectMember.first_name} ${selectMember.last_name}`
                                                        );
                                                        setSelectedMember(selectMember);
                                                    }
                                                }}
                                                value={values.resident}
                                                label="Resident"
                                                error={Boolean(
                                                    touched.resident && errors.resident
                                                )}
                                            >
                                                {members.map(member => (
                                                    <MenuItem
                                                        key={member.uid}
                                                        value={`${member.first_name} ${member.last_name}`}
                                                        sx={{
                                                            textTransform: 'capitalize'
                                                        }}
                                                    >
                                                        {`${member.first_name} ${member.last_name}`}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {touched.resident && errors.resident ? (
                                                <FormHelperText
                                                    sx={{
                                                        color: '#F04134',
                                                        marginLeft: '16px !important'
                                                    }}
                                                >
                                                    {touched.resident && errors.resident}
                                                </FormHelperText>
                                            ) : null}
                                        </Stack>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        sx={{ marginBottom: '20px' }}
                                    >
                                        <Stack spacing={0.5}>
                                            <InputLabel htmlFor="facility_code">
                                                Facility Code
                                            </InputLabel>
                                            <Select
                                                required
                                                id="faciitySelect"
                                                name="facility_code"
                                                sx={{
                                                    textTransform: 'capitalize'
                                                }}
                                                onChange={e => {
                                                    setFieldValue(
                                                        'facility_code',
                                                        e.target.value
                                                    );
                                                }}
                                                value={values.facility_code}
                                                label="Facility Code"
                                                error={Boolean(
                                                    touched.facility_code &&
                                                        errors.facility_code
                                                )}
                                            >
                                                {deviceConfig.facility_codes.map(code => (
                                                    <MenuItem
                                                        key={code}
                                                        value={code}
                                                        sx={{
                                                            textTransform: 'capitalize'
                                                        }}
                                                    >
                                                        {code}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {touched.facility_code &&
                                            errors.facility_code ? (
                                                <FormHelperText
                                                    sx={{
                                                        color: '#F04134',
                                                        marginLeft: '16px !important'
                                                    }}
                                                >
                                                    {touched.facility_code &&
                                                        errors.facility_code}
                                                </FormHelperText>
                                            ) : null}
                                        </Stack>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        sx={{ marginBottom: '20px' }}
                                    >
                                        <Stack spacing={0.5}>
                                            <InputLabel>Device Number</InputLabel>
                                            <TextField
                                                required
                                                id="device_numberBasic"
                                                name="device_number"
                                                placeholder="Device Number"
                                                fullWidth
                                                autoComplete="given-name"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                error={
                                                    touched.device_number &&
                                                    !!errors.device_number
                                                }
                                                helperText={
                                                    touched.device_number &&
                                                    errors.device_number
                                                        ? errors.device_number
                                                        : ''
                                                }
                                                value={values.device_number}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        sx={{ marginBottom: '20px' }}
                                    >
                                        <Stack spacing={0.5}>
                                            <InputLabel htmlFor="facility_code">
                                                Access Group
                                            </InputLabel>
                                            <Select
                                                required
                                                id="access_groupSelect"
                                                name="access_group"
                                                sx={{
                                                    textTransform: 'capitalize'
                                                }}
                                                onChange={e => {
                                                    setFieldValue(
                                                        'access_group',
                                                        e.target.value
                                                    );
                                                }}
                                                value={values.access_group}
                                                label="Access Group"
                                                error={Boolean(
                                                    touched.access_group &&
                                                        errors.access_group
                                                )}
                                            >
                                                {deviceConfig.access_groups.map(group => (
                                                    <MenuItem
                                                        key={group}
                                                        value={group}
                                                        sx={{
                                                            textTransform: 'capitalize'
                                                        }}
                                                    >
                                                        {group}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {touched.access_group &&
                                            errors.access_group ? (
                                                <FormHelperText
                                                    sx={{
                                                        color: '#F04134',
                                                        marginLeft: '16px !important'
                                                    }}
                                                >
                                                    {touched.access_group &&
                                                        errors.access_group}
                                                </FormHelperText>
                                            ) : null}
                                        </Stack>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        sx={{ marginBottom: '20px' }}
                                    >
                                        <Stack spacing={0.5}>
                                            <InputLabel htmlFor="device_type">
                                                Device Type
                                            </InputLabel>
                                            <Select
                                                required
                                                id="deviceTypeSelect"
                                                name="device_type"
                                                sx={{
                                                    textTransform: 'capitalize'
                                                }}
                                                onChange={e => {
                                                    setFieldValue(
                                                        'device_type',
                                                        e.target.value
                                                    );
                                                    if (e.target.value === 'Key Fob')
                                                        handleFobDevice(setFieldValue);
                                                }}
                                                value={values.device_type}
                                                label="Device Type"
                                                error={Boolean(
                                                    touched.device_type &&
                                                        !!errors.device_type
                                                )}
                                            >
                                                {deviceConfig?.types.map(type => (
                                                    <MenuItem
                                                        key={type}
                                                        value={type}
                                                        sx={{
                                                            textTransform: 'capitalize'
                                                        }}
                                                    >
                                                        {type}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {touched.device_type && errors.device_type ? (
                                                <FormHelperText
                                                    sx={{
                                                        color: '#F04134',
                                                        marginLeft: '16px !important'
                                                    }}
                                                >
                                                    {touched.device_type &&
                                                        errors.device_type}
                                                </FormHelperText>
                                            ) : null}
                                        </Stack>
                                    </Grid>
                                    {values.device_type !== 'Key Fob' && (
                                        <>
                                            {!addNewVehicle ? (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    sx={{ marginBottom: '20px' }}
                                                >
                                                    <Stack spacing={0.5}>
                                                        <InputLabel htmlFor="vehicle">
                                                            Vehicle Assignment
                                                        </InputLabel>
                                                        <Select
                                                            required
                                                            id="vehicleSelect"
                                                            name="vehicle"
                                                            sx={{
                                                                textTransform:
                                                                    'capitalize'
                                                            }}
                                                            onChange={e => {
                                                                if (
                                                                    e.target.value ===
                                                                    '+ Add New Vehicle'
                                                                ) {
                                                                    setFieldValue(
                                                                        'vehicle',
                                                                        ''
                                                                    );
                                                                    setAddNewVehicle(
                                                                        true
                                                                    );
                                                                } else {
                                                                    setFieldValue(
                                                                        'vehicle',
                                                                        e.target.value
                                                                    );
                                                                }
                                                            }}
                                                            value={values.vehicle}
                                                            label="Vehicle"
                                                            error={Boolean(
                                                                touched.vehicle &&
                                                                    errors.vehicle
                                                            )}
                                                        >
                                                            {vehicles.map(vehicle => (
                                                                <MenuItem
                                                                    key={vehicle.key}
                                                                    value={vehicle.value}
                                                                    sx={{
                                                                        textTransform:
                                                                            'capitalize'
                                                                    }}
                                                                >
                                                                    {vehicle.value}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        {touched.vehicle &&
                                                        errors.vehicle ? (
                                                            <FormHelperText
                                                                sx={{
                                                                    color: '#F04134',
                                                                    marginLeft:
                                                                        '16px !important'
                                                                }}
                                                            >
                                                                {touched.vehicle &&
                                                                    errors.vehicle}
                                                            </FormHelperText>
                                                        ) : null}
                                                    </Stack>
                                                </Grid>
                                            ) : (
                                                <>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        sx={{ marginBottom: '20px' }}
                                                    >
                                                        <Button
                                                            size="medium"
                                                            color="secondary"
                                                            onClick={() => {
                                                                setFieldValue(
                                                                    'vehicle',
                                                                    ''
                                                                );
                                                                setAddNewVehicle(false);
                                                            }}
                                                        >
                                                            <CloseCircleOutlined />
                                                            <Typography
                                                                sx={{
                                                                    color: '#8c8c8c',
                                                                    marginLeft: '10px'
                                                                }}
                                                            >
                                                                Cancel vehicle
                                                            </Typography>
                                                        </Button>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        sm={6}
                                                        sx={{ marginBottom: '20px' }}
                                                    >
                                                        <Stack spacing={0.5}>
                                                            <InputLabel>Color</InputLabel>
                                                            <TextField
                                                                required
                                                                id="vehicleColorBasic"
                                                                name="vehicle_color"
                                                                placeholder="Color"
                                                                fullWidth
                                                                autoComplete="given-name"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                error={
                                                                    touched.vehicle_color &&
                                                                    !!errors.vehicle_color
                                                                }
                                                                helperText={
                                                                    touched.vehicle_color &&
                                                                    errors.vehicle_color
                                                                        ? errors.vehicle_color
                                                                        : ''
                                                                }
                                                                value={
                                                                    values.vehicle_color
                                                                }
                                                            />
                                                        </Stack>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        sm={6}
                                                        sx={{ marginBottom: '20px' }}
                                                    >
                                                        <Stack spacing={0.5}>
                                                            <InputLabel>Make</InputLabel>
                                                            <TextField
                                                                required
                                                                id="vehicleMakeBasic"
                                                                name="vehicle_make"
                                                                placeholder="Make"
                                                                fullWidth
                                                                autoComplete="given-name"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                error={
                                                                    touched.vehicle_make &&
                                                                    !!errors.vehicle_make
                                                                }
                                                                helperText={
                                                                    touched.vehicle_make &&
                                                                    errors.vehicle_make
                                                                        ? errors.vehicle_make
                                                                        : ''
                                                                }
                                                                value={
                                                                    values.vehicle_make
                                                                }
                                                            />
                                                        </Stack>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        sm={6}
                                                        sx={{ marginBottom: '20px' }}
                                                    >
                                                        <Stack spacing={0.5}>
                                                            <InputLabel>Model</InputLabel>
                                                            <TextField
                                                                required
                                                                id="vehicleModelBasic"
                                                                name="vehicle_model"
                                                                placeholder="Model"
                                                                fullWidth
                                                                autoComplete="given-name"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                error={
                                                                    touched.vehicle_model &&
                                                                    !!errors.vehicle_model
                                                                }
                                                                helperText={
                                                                    touched.vehicle_model &&
                                                                    errors.vehicle_model
                                                                        ? errors.vehicle_model
                                                                        : ''
                                                                }
                                                                value={
                                                                    values.vehicle_model
                                                                }
                                                            />
                                                        </Stack>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        sm={6}
                                                        sx={{ marginBottom: '20px' }}
                                                    >
                                                        <Stack spacing={0.5}>
                                                            <InputLabel htmlFor="device_type">
                                                                State
                                                            </InputLabel>
                                                            <Select
                                                                required
                                                                id="stateSelect"
                                                                name="vehicle_state"
                                                                sx={{
                                                                    textTransform:
                                                                        'capitalize'
                                                                }}
                                                                onChange={e => {
                                                                    setFieldValue(
                                                                        'vehicle_state',
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                value={
                                                                    values.vehicle_state
                                                                }
                                                                label="State"
                                                                error={Boolean(
                                                                    touched.vehicle_state &&
                                                                        !!errors.vehicle_state
                                                                )}
                                                            >
                                                                {unitedStates.map(
                                                                    state => (
                                                                        <MenuItem
                                                                            key={
                                                                                state.value
                                                                            }
                                                                            value={
                                                                                state.name
                                                                            }
                                                                            sx={{
                                                                                textTransform:
                                                                                    'capitalize'
                                                                            }}
                                                                        >
                                                                            {state.value}
                                                                        </MenuItem>
                                                                    )
                                                                )}
                                                            </Select>
                                                            {touched.vehicle_state &&
                                                            errors.vehicle_state ? (
                                                                <FormHelperText
                                                                    sx={{
                                                                        color: '#F04134',
                                                                        marginLeft:
                                                                            '16px !important'
                                                                    }}
                                                                >
                                                                    {touched.vehicle_state &&
                                                                        errors.vehicle_state}
                                                                </FormHelperText>
                                                            ) : null}
                                                        </Stack>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        sm={6}
                                                        sx={{ marginBottom: '20px' }}
                                                    >
                                                        <Stack spacing={0.5}>
                                                            <InputLabel>
                                                                Plate #
                                                            </InputLabel>
                                                            <TextField
                                                                required
                                                                id="vehicleTagBasic"
                                                                name="vehicle_tag"
                                                                placeholder="Plate/Tag #"
                                                                fullWidth
                                                                autoComplete="given-name"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                error={
                                                                    touched.vehicle_tag &&
                                                                    !!errors.vehicle_tag
                                                                }
                                                                helperText={
                                                                    touched.vehicle_tag &&
                                                                    errors.vehicle_tag
                                                                        ? errors.vehicle_tag
                                                                        : ''
                                                                }
                                                                value={values.vehicle_tag}
                                                                inputProps={{
                                                                    style: {
                                                                        textTransform:
                                                                            'uppercase'
                                                                    }
                                                                }}
                                                            />
                                                        </Stack>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        sm={6}
                                                        sx={{ marginBottom: '20px' }}
                                                    >
                                                        <Stack spacing={0.5}>
                                                            <InputLabel>Year</InputLabel>
                                                            <TextField
                                                                required
                                                                id="vehicleYearBasic"
                                                                name="vehicle_year"
                                                                placeholder="Year"
                                                                fullWidth
                                                                autoComplete="given-name"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                error={
                                                                    touched.vehicle_year &&
                                                                    !!errors.vehicle_year
                                                                }
                                                                helperText={
                                                                    touched.vehicle_year &&
                                                                    errors.vehicle_year
                                                                        ? errors.vehicle_year
                                                                        : ''
                                                                }
                                                                value={
                                                                    values.vehicle_year
                                                                }
                                                            />
                                                        </Stack>
                                                    </Grid>
                                                </>
                                            )}
                                        </>
                                    )}
                                </Grid>

                                <Grid item xs={6}>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        sx={{ marginBottom: '20px' }}
                                    >
                                        <Stack spacing={0.5}>
                                            <Box>
                                                <FormControlLabel
                                                    value="end"
                                                    control={
                                                        <Checkbox
                                                            checked={values.has_expire}
                                                            onChange={() => {
                                                                if (!values.has_expire) {
                                                                    setFieldValue(
                                                                        'expires_at',
                                                                        getDefDateTime()
                                                                    );
                                                                }
                                                                setFieldValue(
                                                                    'has_expire',
                                                                    !values.has_expire
                                                                );
                                                            }}
                                                        />
                                                    }
                                                    label={
                                                        <Typography
                                                            sx={{
                                                                color: '#8c8c8c'
                                                            }}
                                                        >
                                                            Has expiration
                                                        </Typography>
                                                    }
                                                    labelPlacement="end"
                                                    sx={{ ml: 0 }}
                                                />
                                            </Box>
                                        </Stack>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        sx={{ marginBottom: '20px' }}
                                    >
                                        {values.has_expire && (
                                            <Stack>
                                                <InputLabel>Expiration Date</InputLabel>
                                                <FormControl
                                                    sx={{ width: '100%' }}
                                                    error={Boolean(
                                                        touched.expires_at &&
                                                            errors.expires_at
                                                    )}
                                                >
                                                    <LocalizationProvider
                                                        dateAdapter={AdapterDateFns}
                                                    >
                                                        <DatePicker
                                                            minDate={getDefDateTime()}
                                                            value={values.expires_at}
                                                            onChange={newValue =>
                                                                setFieldValue(
                                                                    'expires_at',
                                                                    newValue
                                                                )
                                                            }
                                                        />
                                                    </LocalizationProvider>
                                                </FormControl>
                                            </Stack>
                                        )}
                                    </Grid>
                                    {type === 'edit' && (
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            sx={{ marginBottom: '20px' }}
                                        >
                                            <Stack spacing={0.5}>
                                                <Box>
                                                    <FormControlLabel
                                                        value="end"
                                                        control={
                                                            <Checkbox
                                                                checked={values.disabled}
                                                                onChange={() =>
                                                                    setFieldValue(
                                                                        'disabled',
                                                                        !values.disabled
                                                                    )
                                                                }
                                                            />
                                                        }
                                                        label={
                                                            <Typography
                                                                sx={{
                                                                    color: '#8c8c8c'
                                                                }}
                                                            >
                                                                Disabled
                                                            </Typography>
                                                        }
                                                        labelPlacement="end"
                                                        sx={{ ml: 0 }}
                                                    />
                                                </Box>
                                            </Stack>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <Divider />
                        <DialogActions sx={{ p: 2.5 }}>
                            <Grid
                                container
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Grid item>
                                    {type === 'edit' && (
                                        <Tooltip title="Delete Device" placement="top">
                                            <IconButton
                                                size="large"
                                                color="error"
                                                onClick={handleRemove}
                                            >
                                                <DeleteFilled />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </Grid>
                                <Grid item>
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                        alignItems="center"
                                    >
                                        <Button color="error" onClick={onClose}>
                                            Cancel
                                        </Button>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            onClick={handleSubmit}
                                        >
                                            {type === 'edit' ? 'Update' : 'Add'}
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </>
                )}
            </Formik>
        </>
    );
};

export default CreateUpdateDeviceModal;
