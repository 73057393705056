import React from 'react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

// project import
import { connect } from 'react-redux';
import { selectProperty } from 'store/actions/Properties';
import { userRoles } from 'utils/constants';

// ==============================|| AUTH GUARD ||============================== //

const ResiGuard = ({
    children,
    userData,
    selectedProperty,
    selectProperty,
    authUser
}) => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (userData && userData.properties && !selectedProperty) {
            const userProperties = [...Object.values(userData?.properties)];

            selectProperty(userProperties[0]);
        }
    }, [userData, selectedProperty, selectProperty]);

    useEffect(() => {
        if (!authUser) {
            navigate('login', { replace: true });
        }
    }, [authUser, navigate]);

    if (userData && userData?.role !== userRoles.RESIDENT) {
        return <Navigate to="/maintenance/404" state={{ from: location }} />;
    } else {
        return children;
    }
};

ResiGuard.propTypes = {
    children: PropTypes.node
};

const mapStateToProps = ({ properties, user, auth }) => {
    const { authUser } = auth;
    const { selectedProperty } = properties;
    const { userData } = user;

    return { selectedProperty, userData, authUser };
};

export default connect(mapStateToProps, { selectProperty })(ResiGuard);
