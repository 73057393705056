import {
    all,
    fork,
    take,
    cancelled,
    takeLatest,
    race,
    put,
    call,
    select
} from 'redux-saga/effects';

import * as selectors from './Selectors';

import {
    ADD_OTC,
    REVOKE_OTC,
    LOGOUT_USER,
    // SET_ACTIVE_USER_ORG,
    GET_ORG_OTC_CODES
} from '../actions/types';

import { eventChannel } from 'redux-saga';

import {
    addOtcFailure,
    addOtcSuccess,
    revokeOtcFailure,
    revokeOtcSuccess,
    getOtcCodesSuccess
} from '../actions/Otc';

import { db } from 'config/firebase';

import {
    Timestamp,
    collection,
    doc,
    onSnapshot,
    query,
    serverTimestamp,
    setDoc,
    updateDoc,
    where
} from 'firebase/firestore';
import { openSnackbar } from 'store/actions/Snackbar';
import { log } from 'utils/Loggers';

const otcCollectionRef = collection(db, 'otc');

//////////////// Get User Organization ////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////// OTC Codes Collection /////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* otcCollectionWatch(org) {
    let unsubscribeOtcData;
    const creators = org?.users?.length ? org.users.map(manager => manager.id) : [];
    const otcCollectionQuery = query(
        otcCollectionRef,
        where('org_id', '==', org.org_id),
        where('expires_at', '>', Timestamp.now()),
        where('creator_id', 'in', creators)
    );
    const otcCollectionChannel = eventChannel(emit => {
        unsubscribeOtcData = onSnapshot(otcCollectionQuery, querySnapshot => {
            if (!querySnapshot.empty) {
                const codes = querySnapshot.docs.map(doc => doc.data());
                emit(codes);
            } else {
                emit({ exists: false });
            }
        });
        return unsubscribeOtcData;
    });
    try {
        while (true) {
            const { userSignOut, orgCodesData } = yield race({
                userSignOut: take(LOGOUT_USER),
                // resettingOrg: take(SET_ACTIVE_USER_ORG),
                orgCodesData: take(otcCollectionChannel)
            });

            // const currentImports = yield select(selectors._importedMembers);
            // const currentMembers = yield select(selectors._communityMembers);
            // const currentAccessLog = yield select(selectors._accessLog);
            // const initialized = yield select(selectors._appInitialized);

            if (userSignOut) {
                yield call([otcCollectionChannel, 'close']); // Detach saga event emitter
                // else if (resettingOrg) {
                //     if (initialized) {
                //         if (
                //             (currentImports && currentImports?.length) ||
                //             (currentMembers && currentMembers?.length) ||
                //             (currentAccessLog && currentAccessLog?.length)
                //         ) {
                //             yield call([otcCollectionChannel, 'close']); // Detach saga event emitter
                //         }
                //     }
                // }
            } else {
                yield put(getOtcCodesSuccess(orgCodesData));
            }
        }
    } catch (error) {
        log('OTC Error: getting org codes collection data (FS)', {
            error,
            org
        });
    } finally {
        yield call(unsubscribeOtcData); // Detach firebase listener
        if (yield cancelled()) {
            yield call([otcCollectionChannel, 'close']); // Detach saga event emitter
            yield call(unsubscribeOtcData); // Detach firebase listener
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////// Add OTC //////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const editingOtcRequest = async (otcId, editedFields) => {
    try {
        const otcDocRef = doc(otcCollectionRef, otcId);
        await updateDoc(otcDocRef, editedFields);
        return { res: editedFields };
    } catch (error) {
        console.error('Error: editing One Time Access Code', error);
        throw error;
    }
};

const addingOtcRequest = async otcData => {
    try {
        const otcId = doc(otcCollectionRef).id;
        const otcDocRef = doc(otcCollectionRef, otcId);
        const data = { ...otcData, code_id: otcId, created_at: serverTimestamp() };
        await setDoc(otcDocRef, data);
        return { res: data };
    } catch (error) {
        console.error('Error: creating One Time Access Code', error);
        throw error;
    }
};

export function* addingOtc({ payload }) {
    let res, error;

    if (payload?.code_id) {
        const { code_id, ...editedFields } = payload;
        ({ res, error } = yield call(() => editingOtcRequest(code_id, editedFields)));
    } else {
        ({ res, error } = yield call(() => addingOtcRequest(payload)));
    }

    if (res) {
        yield put(addOtcSuccess(res));
    } else {
        yield put(addOtcFailure(error));
        // Error Handling for sentry with put and maybe UI message
        log(`OTC Error: error adding Org otc (FS)`, {
            error,
            ...payload
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////// Revoke OTC's /////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const revokingOtcRequest = async ({ id, expire, userData }) => {
    try {
        const otcDocRef = doc(otcCollectionRef, id);
        await updateDoc(otcDocRef, {
            expires_at: expire,
            creator_id: `${userData.uid}_expired`
        });
        return { res: true };
    } catch (error) {
        console.error('Error: revoking One Time Access Code', error);
        throw error;
    }
};

export function* revokingOtc({ payload }) {
    const { codes } = payload;
    const userData = yield select(selectors._userData);

    try {
        const results = yield all(
            codes.map(code =>
                call(revokingOtcRequest, {
                    id: code.code_id,
                    expire: code.created_at,
                    userData
                })
            )
        );

        const successResults = results.filter(result => result.res);
        const failedResults = results.filter(result => !result.res);

        if (successResults.length === codes.length) {
            yield put(revokeOtcSuccess());

            yield put(
                openSnackbar({
                    open: true,
                    message: `${codes.length} ${
                        codes.length === 1
                            ? 'One Time Access Code was'
                            : 'One Time Access Codes were'
                    } successfully revoked`,
                    variant: 'alert',
                    alert: {
                        color: 'success',
                        variant: 'outlined'
                    },
                    close: false
                })
            );
        } else {
            yield put(revokeOtcFailure(failedResults.map(result => result.error)));
            console.log(`Error: error revoking One Time Access Code`, {
                errors: failedResults.map(result => result.error)
            });

            yield put(
                openSnackbar({
                    open: true,
                    message: `Failed to revoke some One Time Access Codes`,
                    variant: 'alert',
                    alert: {
                        color: 'error',
                        variant: 'outlined'
                    },
                    close: false
                })
            );
        }
    } catch (error) {
        log('OTC Error: revoking One Time Access Code', { error, codes, userData });
    }
}

///////////// Action Creators For Root Saga //////////////////

export function* getOtcCodes() {
    yield takeLatest(GET_ORG_OTC_CODES, otcCollectionWatch);
}

export function* addOtcCode() {
    yield takeLatest(ADD_OTC, addingOtc);
}

export function* revokeOtcCode() {
    yield takeLatest(REVOKE_OTC, revokingOtc);
}

export default function* rootSaga() {
    yield all([fork(getOtcCodes), fork(addOtcCode), fork(revokeOtcCode)]);
}
