import {
    SET_ACTIVE_USER_ORG,
    SET_ACTIVE_USER_ORG_SUCCESS,
    SET_ACTIVE_USER_ORG_FAILURE,
    SET_USER_COLLECTION,
    SET_ACTIVE_USER_ORG_PERMISSIONS
} from '../actions/types';

const INITIAL_STATE = {
    userData: null,
    activePermissions: null,
    activeOrg: null,
    changingFocus: false,
    focusError: null,
    success: null
};

const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_USER_COLLECTION:
            return {
                ...state,
                userData: action.payload
            };
        case SET_ACTIVE_USER_ORG:
            return {
                ...state,
                changingFocus: true,
                focusError: null
            };
        case SET_ACTIVE_USER_ORG_SUCCESS:
            return {
                ...state,
                changingFocus: false,
                activeOrg: action.payload.activeOrg
            };
        case SET_ACTIVE_USER_ORG_FAILURE:
            return {
                ...state,
                changingFocus: false,
                focusError: action.payload
            };
        case SET_ACTIVE_USER_ORG_PERMISSIONS:
            return {
                ...state,
                activePermissions: action.payload.activePermissions
            };
        default:
            return state;
    }
};

export default userReducer;
