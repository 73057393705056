import React, { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import RouteGuard from 'utils/route-guard/RouteGuard';
import { moduleKeys, permissionKeys } from 'utils/constants';
import { Navigate } from 'react-router';

// pages maintenance pages
const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')));

// render - main-pages
const Dashboard = Loadable(lazy(() => import('pages/main-pages/Dashboard')));
// const Devices = Loadable(lazy(() => import('pages/main-pages/Devices')));
// const Clips = Loadable(lazy(() => import('pages/main-pages/Clips')));
const Settings = Loadable(lazy(() => import('pages/main-pages/Settings')));
const Cameras = Loadable(lazy(() => import('pages/main-pages/Cameras')));
const LiveView = Loadable(lazy(() => import('pages/main-pages/LiveView')));
const NewReservation = Loadable(lazy(() => import('pages/main-pages/NewReservation')));
const EditReservation = Loadable(lazy(() => import('pages/main-pages/EditReservation')));
const TenantLookup = Loadable(lazy(() => import('pages/main-pages/TenantLookup')));
const AccessControl = Loadable(lazy(() => import('pages/main-pages/AccessControl')));
const Property = Loadable(lazy(() => import('pages/main-pages/Property')));
const VirtualGuard = Loadable(lazy(() => import('pages/main-pages/VirtualGuard')));
const ManagerAddOtc = Loadable(lazy(() => import('pages/main-pages/ManagerAddOtc')));
const WebPrivacyPolicy = Loadable(
    lazy(() => import('pages/main-pages/WebPrivacyPolicy'))
);
const WebTerms = Loadable(lazy(() => import('pages/main-pages/WebTerms')));
const CaPrivacyPolicy = Loadable(lazy(() => import('pages/main-pages/CaPrivacyPolicy')));
const DoNotSell = Loadable(lazy(() => import('pages/main-pages/DoNotSell')));
const MobileEULA = Loadable(lazy(() => import('pages/main-pages/MobileEULA')));
const MobilePrivacyPolicy = Loadable(
    lazy(() => import('pages/main-pages/MobilePrivacyPolicy'))
);
const AcceptInvitation = Loadable(
    lazy(() => import('pages/invitation/accept-invitation'))
);
const AcceptNewInvitation = Loadable(
    lazy(() => import('pages/invitation/accept-new-invitation'))
);
const InvitationConfirmation = Loadable(
    lazy(() => import('pages/invitation/invitation-confirmation'))
);
const InvitationError = Loadable(lazy(() => import('pages/invitation/invitation-error')));
const Queue = Loadable(lazy(() => import('pages/main-pages/Queue')));
const LprViewer = Loadable(lazy(() => import('pages/main-pages/LprViewer')));
const TabInfo = Loadable(lazy(() => import('sections/view-edit-org/TabInfo')));
const TabUsers = Loadable(lazy(() => import('sections/view-edit-org/TabUsers')));

// ==============================|| MAIN ROUTING ||============================== //

// CloudVideo - test feeds

const feeds = [
    {
        focused: true,
        id: 'player1',
        player: null,
        token: 'eyJjYW1pZCI6IDE4LCAiY21uZ3JpZCI6IDE4LCAiYWNjZXNzIjogImFsbCIsICJ0b2tlbiI6ICJzaGFyZS5leUp6YVNJNklEUXlmUS42NDNlYzJmM3RiYzFmYWIwMC5PXzNNNXJFcWdYZkpITk1JUnRXVVBjN2lUMUkiLCAiYXBpIjogIndlYi5yYW1jby5yYW1jby5jbG91ZC12bXMuY29tIiwgImNhbSI6ICJjYW0ucmFtY28ucmFtY28uY2xvdWQtdm1zLmNvbSJ9',
        name: 'Main Gate'
    },
    {
        focused: true,
        id: 'player2',
        player: null,
        token: 'eyJjYW1pZCI6IDE0LCAiY21uZ3JpZCI6IDE0LCAiYWNjZXNzIjogImFsbCIsICJ0b2tlbiI6ICJzaGFyZS5leUp6YVNJNklETTBmUS42NDM5YmU1Y3RiYzFmYWIwMC5QcHQzQktONHpaSXY5RTgwVTAtemtTeWNjelUiLCAiYXBpIjogIndlYi5yYW1jby5yYW1jby5jbG91ZC12bXMuY29tIiwgImNhbSI6ICJjYW0ucmFtY28ucmFtY28uY2xvdWQtdm1zLmNvbSJ9',
        name: 'South Gate 1'
    },
    {
        focused: false,
        id: 'player3',
        player: null,
        token: 'eyJjYW1pZCI6IDE0LCAiY21uZ3JpZCI6IDE0LCAiYWNjZXNzIjogImFsbCIsICJ0b2tlbiI6ICJzaGFyZS5leUp6YVNJNklETTBmUS42NDM5YmU1Y3RiYzFmYWIwMC5QcHQzQktONHpaSXY5RTgwVTAtemtTeWNjelUiLCAiYXBpIjogIndlYi5yYW1jby5yYW1jby5jbG91ZC12bXMuY29tIiwgImNhbSI6ICJjYW0ucmFtY28ucmFtY28uY2xvdWQtdm1zLmNvbSJ9',
        name: 'South Gate 2'
    },
    {
        focused: false,
        id: 'player4',
        player: null,
        token: 'eyJjYW1pZCI6IDE4LCAiY21uZ3JpZCI6IDE4LCAiYWNjZXNzIjogImFsbCIsICJ0b2tlbiI6ICJzaGFyZS5leUp6YVNJNklEUXlmUS42NDNlYzJmM3RiYzFmYWIwMC5PXzNNNXJFcWdYZkpITk1JUnRXVVBjN2lUMUkiLCAiYXBpIjogIndlYi5yYW1jby5yYW1jby5jbG91ZC12bXMuY29tIiwgImNhbSI6ICJjYW0ucmFtY28ucmFtY28uY2xvdWQtdm1zLmNvbSJ9',
        name: 'Service/Maintenance'
    },
    {
        focused: false,
        id: 'player5',
        player: null,
        token: 'eyJjYW1pZCI6IDE4LCAiY21uZ3JpZCI6IDE4LCAiYWNjZXNzIjogImFsbCIsICJ0b2tlbiI6ICJzaGFyZS5leUp6YVNJNklEUXlmUS42NDNlYzJmM3RiYzFmYWIwMC5PXzNNNXJFcWdYZkpITk1JUnRXVVBjN2lUMUkiLCAiYXBpIjogIndlYi5yYW1jby5yYW1jby5jbG91ZC12bXMuY29tIiwgImNhbSI6ICJjYW0ucmFtY28ucmFtY28uY2xvdWQtdm1zLmNvbSJ9',
        name: 'Secondary Gate'
    },
    {
        focused: false,
        id: 'player6',
        player: null,
        token: 'eyJjYW1pZCI6IDE0LCAiY21uZ3JpZCI6IDE0LCAiYWNjZXNzIjogImFsbCIsICJ0b2tlbiI6ICJzaGFyZS5leUp6YVNJNklETTBmUS42NDM5YmU1Y3RiYzFmYWIwMC5QcHQzQktONHpaSXY5RTgwVTAtemtTeWNjelUiLCAiYXBpIjogIndlYi5yYW1jby5yYW1jby5jbG91ZC12bXMuY29tIiwgImNhbSI6ICJjYW0ucmFtY28ucmFtY28uY2xvdWQtdm1zLmNvbSJ9',
        name: 'South Gate 3'
    },
    {
        focused: false,
        id: 'player7',
        player: null,
        token: 'eyJjYW1pZCI6IDE0LCAiY21uZ3JpZCI6IDE0LCAiYWNjZXNzIjogImFsbCIsICJ0b2tlbiI6ICJzaGFyZS5leUp6YVNJNklETTBmUS42NDM5YmU1Y3RiYzFmYWIwMC5QcHQzQktONHpaSXY5RTgwVTAtemtTeWNjelUiLCAiYXBpIjogIndlYi5yYW1jby5yYW1jby5jbG91ZC12bXMuY29tIiwgImNhbSI6ICJjYW0ucmFtY28ucmFtY28uY2xvdWQtdm1zLmNvbSJ9',
        name: 'West Service/Maintenance'
    },
    {
        focused: false,
        id: 'player8',
        player: null,
        token: 'eyJjYW1pZCI6IDE4LCAiY21uZ3JpZCI6IDE4LCAiYWNjZXNzIjogImFsbCIsICJ0b2tlbiI6ICJzaGFyZS5leUp6YVNJNklEUXlmUS42NDNlYzJmM3RiYzFmYWIwMC5PXzNNNXJFcWdYZkpITk1JUnRXVVBjN2lUMUkiLCAiYXBpIjogIndlYi5yYW1jby5yYW1jby5jbG91ZC12bXMuY29tIiwgImNhbSI6ICJjYW0ucmFtY28ucmFtY28uY2xvdWQtdm1zLmNvbSJ9',
        name: 'West Service/Maintenance 2'
    }
];

// end CloudVideo

const MainRoutes = {
    path: '/',
    children: [
        {
            path: '*',
            element: <MaintenanceError />
        },
        {
            path: '/',
            element: (
                <AuthGuard>
                    <MainLayout />
                </AuthGuard>
            ),
            children: [
                {
                    path: 'dashboard',
                    element: (
                        <RouteGuard
                            module={moduleKeys.ORG_DASHBOARD}
                            permission={permissionKeys.ORG_DASHBOARD}
                        >
                            <Dashboard feeds={feeds} />
                        </RouteGuard>
                    )
                },
                // {
                //     path: 'devices',
                //     element: (
                //         <RouteGuard
                //             module={moduleKeys.ORG_DASHBOARD}
                //             permission={permissionKeys.ORG_DASHBOARD}
                //         >
                //             <Devices />
                //         </RouteGuard>
                //     )
                // },
                // {
                //     path: 'clips',
                //     element: (
                //         <RouteGuard
                //             module={moduleKeys.CLOUD_VIDEO}
                //             permission={permissionKeys.CLOUD_VIDEO}
                //         >
                //             <Clips />
                //         </RouteGuard>
                //     )
                // },
                {
                    path: 'virtual-guard',
                    element: (
                        <RouteGuard
                            module={moduleKeys.VIRTUAL_GUARD}
                            permission={permissionKeys.VIRTUAL_GUARD}
                        >
                            <VirtualGuard feeds={feeds} />
                        </RouteGuard>
                    )
                },
                {
                    path: 'queue',
                    element: (
                        <RouteGuard
                            module={moduleKeys.VIRTUAL_GUARD}
                            permission={permissionKeys.ORG_DASHBOARD}
                        >
                            <Queue />
                        </RouteGuard>
                    )
                },
                {
                    path: 'settings',
                    element: (
                        <RouteGuard
                            module={moduleKeys.SETTINGS}
                            permission={permissionKeys.SETTINGS}
                        >
                            <Settings />
                        </RouteGuard>
                    ),
                    children: [
                        {
                            path: 'org-info',
                            element: <TabInfo />
                        },
                        {
                            path: 'org-users',
                            element: <TabUsers />
                        },
                        { path: '*', element: <Navigate to="org-info" replace /> }
                    ]
                },
                {
                    path: 'cameras',
                    element: (
                        <RouteGuard
                            module={moduleKeys.CLOUD_VIDEO}
                            permission={permissionKeys.CLOUD_VIDEO}
                        >
                            <Cameras />
                        </RouteGuard>
                    )
                },
                {
                    path: 'live-view',
                    element: (
                        <RouteGuard
                            module={moduleKeys.CLOUD_VIDEO}
                            permission={permissionKeys.CLOUD_VIDEO}
                        >
                            <LiveView />
                        </RouteGuard>
                    )
                },
                {
                    path: 'new-reservation',
                    element: (
                        <RouteGuard
                            module={moduleKeys.RENTAL_MANAGER}
                            permission={permissionKeys.RENTAL_MANAGER}
                        >
                            <NewReservation />
                        </RouteGuard>
                    )
                },
                {
                    path: 'new-reservation/:reservationId',
                    element: (
                        <RouteGuard
                            module={moduleKeys.RENTAL_MANAGER}
                            permission={permissionKeys.RENTAL_MANAGER}
                        >
                            <EditReservation />
                        </RouteGuard>
                    )
                },
                {
                    path: 'tenant-lookup',
                    element: (
                        <RouteGuard
                            module={moduleKeys.RENTAL_MANAGER}
                            permission={permissionKeys.RENTAL_MANAGER}
                        >
                            <TenantLookup />
                        </RouteGuard>
                    )
                },
                {
                    path: 'access-control',
                    element: (
                        <RouteGuard
                            module={moduleKeys.ORG_DASHBOARD}
                            permission={permissionKeys.ORG_DASHBOARD}
                        >
                            <AccessControl />
                        </RouteGuard>
                    )
                },
                {
                    path: 'access-control/add-otc',
                    element: (
                        <RouteGuard
                            module={moduleKeys.ORG_DASHBOARD}
                            permission={permissionKeys.ORG_DASHBOARD}
                        >
                            <ManagerAddOtc />
                        </RouteGuard>
                    )
                },
                {
                    path: 'access-control/property/:id',
                    element: <Property />
                },
                {
                    path: 'plate-search',
                    element: (
                        <RouteGuard
                            module={moduleKeys.LPR}
                            permission={permissionKeys.LPR}
                        >
                            <LprViewer />
                        </RouteGuard>
                    )
                }
            ]
        },
        {
            path: '/accept-invitation',
            element: <AcceptInvitation />
        },
        {
            path: '/accept-new-invitation',
            element: <AcceptNewInvitation />
        },
        {
            path: '/invitation-confirmation',
            element: <InvitationConfirmation />
        },
        {
            path: '/invitation-error',
            element: <InvitationError />
        },
        {
            path: '/privacy',
            element: <WebPrivacyPolicy />
        },
        {
            path: '/terms',
            element: <WebTerms />
        },
        {
            path: '/ca-privacy',
            element: <CaPrivacyPolicy />
        },
        {
            path: '/mobile-app-eula',
            element: <MobileEULA />
        },
        {
            path: '/mobile-app-privacy',
            element: <MobilePrivacyPolicy />
        },
        {
            path: '/do-not-sell',
            element: <DoNotSell />
        },
        {
            path: '/maintenance',
            element: <CommonLayout />,
            children: [
                {
                    path: '404',
                    element: <MaintenanceError />
                },
                {
                    path: '500',
                    element: <MaintenanceError500 />
                }
            ]
        }
    ]
};

export default MainRoutes;
