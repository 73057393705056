// React
import React, { useState, useEffect } from 'react';

// Components
import CallInterval from './CallInterval';

// Packages
import { Button, Stack, Typography, List, ListItem, ListItemText } from '@mui/material';

const CallQueue = ({
    fieldPanelCall,
    endPanelCall,
    userData,
    pending,
    isActive,
    setActiveManagerOrg,
    orgs,
    changingFocus
}) => {
    const [preppingCallState, setPreppingCallState] = useState(false);
    const [panelCall, setPanelCall] = useState(null);

    const handleFielding = call => {
        if (userData.active_org_id !== call.org_id) {
            const org = orgs.find(org => org.org_id === call.org_id);
            setActiveManagerOrg(org);
            setPanelCall(call);
            setPreppingCallState(true);
        } else {
            setPreppingCallState(true);
            setPanelCall(call);
        }
    };

    useEffect(() => {
        if (preppingCallState) {
            if (
                panelCall &&
                panelCall.org_id === userData.active_org_id &&
                !changingFocus
            ) {
                fieldPanelCall({ panelCall, userData });
                setPreppingCallState(false);
                setPanelCall(null);
            }
        }
    }, [
        preppingCallState,
        userData,
        panelCall,
        changingFocus,
        fieldPanelCall,
        setPreppingCallState,
        setPanelCall
    ]);

    return (
        <Stack
            sx={{
                height: '38vh',
                marginTop: '2%',
                border: '1px solid rgba(0, 0, 0, 0.06)',
                borderRadius: '14px',
                backgroundColor: '#F9F9FA',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            {pending && pending.length ? (
                <List
                    sx={{
                        width: '100%',
                        height: '100%',
                        backgroundColor: '#F9F9FA',
                        borderRadius: '14px',
                        overflowY: 'auto'
                    }}
                >
                    {pending?.map(call => (
                        <Stack key={call.id}>
                            <ListItem
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '7.5vh',
                                    width: '96%',
                                    marginLeft: '2%',
                                    marginBottom: '2%',
                                    backgroundColor: '#ffffff',
                                    borderRadius: '10px',
                                    border: '1px solid rgba(0, 0, 0, 0.06)',
                                    paddingX: '4%'
                                }}
                                disableGutters
                            >
                                <ListItemText
                                    sx={{
                                        maxWidth: '60%!important',
                                        position: 'absolute',
                                        left: '4%'
                                    }}
                                    primary={
                                        <Typography
                                            sx={{ display: 'block' }}
                                            component="span"
                                            variant="body1"
                                            color="text.primary"
                                        >
                                            {call.panel_name}
                                        </Typography>
                                    }
                                    secondary={
                                        <Typography
                                            sx={{ display: 'block' }}
                                            component="span"
                                            variant="body2"
                                            color="text.secondary"
                                        >
                                            <CallInterval createdAt={call.created_at} />
                                        </Typography>
                                    }
                                />
                                <Button
                                    disableRipple
                                    sx={{
                                        position: 'absolute',
                                        left: '50%',
                                        '&.MuiButton-root:hover': {
                                            bgcolor: 'transparent'
                                        },
                                        '&:active::after': {
                                            display: 'none'
                                        },
                                        color: 'rgba(230, 58, 58, 1)',
                                        fontSize: '0.9375rem'
                                    }}
                                    variant="text"
                                    onClick={() => {
                                        endPanelCall({ panelCall: call, userData });
                                    }}
                                >
                                    Clear
                                </Button>
                                <Button
                                    disabled={isActive}
                                    sx={{
                                        width: '23%',
                                        height: '54%',
                                        position: 'absolute',
                                        left: '74%',
                                        borderRadius: '26.5px',
                                        '&:active::after': {
                                            borderRadius: '26.5px'
                                        },
                                        fontSize: '0.9375rem'
                                    }}
                                    onClick={() => handleFielding(call)}
                                    variant="contained"
                                >
                                    Answer
                                </Button>
                            </ListItem>
                        </Stack>
                    ))}
                </List>
            ) : (
                <Typography
                    sx={{ display: 'block', color: 'rgba(167, 167, 167, 1)' }}
                    component="span"
                    variant="body1"
                >
                    The call queue is clear
                </Typography>
            )}
        </Stack>
    );
};

export default CallQueue;
