import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// import { Button } from 'reactstrap';
import { resetAddReservationState } from '../../store/actions/Reservations';
import AnimateButton from 'components/@extended/AnimateButton';
import { Box, Button, Typography } from '@mui/material';
import LogoIcon from 'components/logo/LogoIcon';
import { useNavigate } from 'react-router';

const AddReservationSuccess = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        return () => {
            dispatch(resetAddReservationState());
        };
    }, [dispatch]);

    return (
        <Box sx={{ mx: 'auto', pt: 8 }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column'
                }}
            >
                <LogoIcon />
                <Box sx={{ mb: 3 }}>
                    <Typography
                        variant="h3"
                        color="primary"
                        sx={{
                            mt: 5,
                            mb: 8,
                            fontWeight: 'semibold',
                            textAlign: 'center'
                        }}
                    >
                        Success!
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        color="secondary"
                        sx={{ mt: 5, mb: 8 }}
                    >
                        Reservation has been created.
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3, pt: 3 }}>
                <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', m: 2 }}>
                    <AnimateButton>
                        <Button
                            variant="contained"
                            onClick={() => {
                                dispatch(resetAddReservationState());
                                navigate('/new-reservation');
                            }}
                        >
                            New Reservation
                        </Button>
                    </AnimateButton>
                </Box>
            </Box>
        </Box>
    );
};

export default AddReservationSuccess;
