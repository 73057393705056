import { useRoutes } from 'react-router-dom';

// project import
import AuthRoutes from './AuthRoutes';
import MainRoutes from './MainRoutes';
import ResiRoutes from './ResiRoutes';
import AdminRoutes from './AdminRoutes';
import MiscRoutes from './MiscRoutes';
// import useSubscriptions from 'hooks/useSubscriptions';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    // useSubscriptions();

    return useRoutes([AuthRoutes, MainRoutes, ResiRoutes, AdminRoutes, MiscRoutes]);
}
