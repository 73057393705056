import {
    CHECK_USER_AUTHENTICATED,
    USER_AUTHENTICATED_SUCCESS,
    USER_AUTHENTICATED_FAILURE,
    LOGIN_USER_EMAIL_PASSWORD,
    LOGIN_USER_FAILURE,
    LOGOUT_USER,
    LOGOUT_USER_SUCCESS,
    CLEAR_AUTH_ERROR,
    NON_AUTH_RESET_USER_PASSWORD,
    NON_AUTH_RESET_USER_PASSWORD_SUCCESS,
    NON_AUTH_RESET_USER_PASSWORD_FAILURE,
    RESET_PASSWORD_REQUEST_STATE,
    EXECUTE_RESET_PASSWORD_FLIGHT,
    VERIFY_ACTION_CODE,
    ACTION_CODE_VALID,
    ACTION_CODE_INVALID,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
    UPDATE_AUTH_USER_PASSWORD,
    UPDATE_AUTH_USER_PASSWORD_SUCCESS,
    UPDATE_AUTH_USER_PASSWORD_FAILURE,
    UPDATE_AUTH_USER_PASSWORD_RESET,
    UPDATE_AUTH_USER_EMAIL,
    UPDATE_AUTH_USER_EMAIL_SUCCESS,
    UPDATE_AUTH_USER_EMAIL_FAILURE,
    UPDATE_AUTH_USER_EMAIL_RESET,
    MANAGER_IS_CHANGING_PASSWORD,
    RESET_INITIAL_MANAGER_PASSWORD,
    VALIDATE_INVITE_CODE,
    VALIDATE_INVITE_CODE_SUCCESS,
    VALIDATE_INVITE_CODE_FAILURE,
    RESET_VALIDATE_INVITE_CODE,
    RESEND_INVITE_CODE,
    RESEND_INVITE_CODE_SUCCESS,
    RESEND_INVITE_CODE_FAILURE,
    CREATE_AUTH_USER,
    CREATE_AUTH_USER_SUCCESS,
    CREATE_AUTH_USER_FAILURE,
    RESET_RESEND_INVITE_CODE
} from './types';

export const checkUserAuth = () => {
    return {
        type: CHECK_USER_AUTHENTICATED
    };
};

export const userIsAuthenticated = authUser => {
    return {
        type: USER_AUTHENTICATED_SUCCESS,
        payload: authUser
    };
};

export const userNotAuthenticated = () => {
    return {
        type: USER_AUTHENTICATED_FAILURE
    };
};

export const loginUserEmailPassword = userCreds => {
    return {
        type: LOGIN_USER_EMAIL_PASSWORD,
        payload: userCreds
    };
};

export const loginUserFailure = payload => {
    return {
        type: LOGIN_USER_FAILURE,
        payload: payload
    };
};

export const logoutUser = () => {
    return {
        type: LOGOUT_USER
    };
};

export const logoutUserSuccess = () => {
    return {
        type: LOGOUT_USER_SUCCESS
    };
};

export const clearAuthError = () => {
    return {
        type: CLEAR_AUTH_ERROR
    };
};

export const sendResetPasswordLink = email => {
    return {
        type: NON_AUTH_RESET_USER_PASSWORD,
        payload: email
    };
};

export const sendResetPasswordLinkSuccess = () => {
    return {
        type: NON_AUTH_RESET_USER_PASSWORD_SUCCESS
    };
};

export const sendResetPasswordLinkFailure = () => {
    return {
        type: NON_AUTH_RESET_USER_PASSWORD_FAILURE
    };
};

export const resettingPasswordRequestState = () => {
    return {
        type: RESET_PASSWORD_REQUEST_STATE
    };
};

export const executeResetPasswordFlight = password => {
    return {
        type: EXECUTE_RESET_PASSWORD_FLIGHT,
        payload: password
    };
};

export const validateActionCode = code => {
    return {
        type: VERIFY_ACTION_CODE,
        payload: code
    };
};

export const actionCodeValidationSuccess = () => {
    return {
        type: ACTION_CODE_VALID
    };
};

export const actionCodeValidationFailure = () => {
    return {
        type: ACTION_CODE_INVALID
    };
};

export const resetPasswordSuccess = () => {
    return {
        type: RESET_PASSWORD_SUCCESS
    };
};

export const resetPasswordFailure = () => {
    return {
        type: RESET_PASSWORD_FAILURE
    };
};

export const updateAuthUserPassword = newPassword => {
    return {
        type: UPDATE_AUTH_USER_PASSWORD,
        payload: newPassword
    };
};

export const passwordUpdateSuccess = () => {
    return {
        type: UPDATE_AUTH_USER_PASSWORD_SUCCESS
    };
};

export const passwordUpdateFailure = error => {
    return {
        type: UPDATE_AUTH_USER_PASSWORD_FAILURE,
        payload: error
    };
};
export const resetUpdateAuthPasswordState = () => {
    return {
        type: UPDATE_AUTH_USER_PASSWORD_RESET
    };
};

export const updateAuthUserEmail = email => {
    return {
        type: UPDATE_AUTH_USER_EMAIL,
        payload: email
    };
};

export const emailUpdateSuccess = () => {
    return {
        type: UPDATE_AUTH_USER_EMAIL_SUCCESS
    };
};

export const emailUpdateFailure = error => {
    return {
        type: UPDATE_AUTH_USER_EMAIL_FAILURE,
        payload: error
    };
};

export const resetUpdateAuthEmailState = () => {
    return {
        type: UPDATE_AUTH_USER_EMAIL_RESET
    };
};

export const setManagerIsChangingPassword = isChanging => {
    return {
        type: MANAGER_IS_CHANGING_PASSWORD,
        payload: isChanging
    };
};

export const resetInitialManagerPassword = payload => {
    return {
        type: RESET_INITIAL_MANAGER_PASSWORD,
        payload: payload
    };
};

export const resetValidateInviteCode = code => {
    return {
        type: RESET_VALIDATE_INVITE_CODE,
        payload: code
    };
};

export const validateInviteCode = code => {
    return {
        type: VALIDATE_INVITE_CODE,
        payload: code
    };
};

export const validateInviteCodeSuccess = validGuestInvite => {
    return {
        type: VALIDATE_INVITE_CODE_SUCCESS,
        payload: validGuestInvite
    };
};

export const validateInviteCodeFailure = error => {
    return {
        type: VALIDATE_INVITE_CODE_FAILURE,
        payload: error
    };
};

export const createAuthUser = newUser => {
    return {
        type: CREATE_AUTH_USER,
        payload: newUser
    };
};

export const createAuthUserSuccess = userId => {
    return {
        type: CREATE_AUTH_USER_SUCCESS,
        payload: userId
    };
};

export const createAuthUserFailure = error => {
    return {
        type: CREATE_AUTH_USER_FAILURE,
        payload: error
    };
};

export const resendInviteCode = payload => {
    return {
        type: RESEND_INVITE_CODE,
        payload: payload
    };
};

export const resendInviteCodeSuccess = payload => {
    return {
        type: RESEND_INVITE_CODE_SUCCESS,
        payload: payload
    };
};

export const resendInviteCodeFailure = error => {
    return {
        type: RESEND_INVITE_CODE_FAILURE,
        payload: error
    };
};

export const resetResendInviteCode = payload => {
    return {
        type: RESET_RESEND_INVITE_CODE,
        payload: payload
    };
};
