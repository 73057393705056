import {
    STORE_INTERCOM_COLLECTION,
    CREATE_PANEL_INTERCOM,
    CREATE_PANEL_INTERCOM_SUCCESS,
    CREATE_PANEL_INTERCOM_FAILURE,
    END_PANEL_CALL_SUCCESS,
    END_PANEL_INTERCOM_SUCCESS,
    CREATE_ACCESS_API_START_OVER
} from '../actions/types';

const INITIAL_STATE = {
    active: [],
    pending: [],
    intercom: null,
    loading: null,
    error: null
};

// const INITIAL_STATE = {
//     active: [],
//     pending: [],
//     intercom: {
// ...com,
// connected_by: 'MbpHMSfyflXC517EOXbw3MYEM012',
// connection_end: null,
// connection_start: null,
// created_at: timeStampNow(),
// id: refId,
// org_id: '6C8RzH04yhwP93atjhLl',
// panel_id: 'YqipRYmEzSVjDsP9QYhuPyRwIH53',
// panel_name: 'Ramco Panel',
// status: 'pending'
//     }
// };

const comsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case STORE_INTERCOM_COLLECTION:
            return {
                ...state,
                active: action.payload.active,
                pending: action.payload.pending
            };
        case CREATE_PANEL_INTERCOM:
            return {
                ...state,
                loading: true,
                error: null
            };
        case CREATE_PANEL_INTERCOM_SUCCESS:
            return {
                ...state,
                intercom: action.payload,
                loading: false,
                error: null
            };
        case CREATE_PANEL_INTERCOM_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case END_PANEL_CALL_SUCCESS:
            return {
                ...state,
                intercom: INITIAL_STATE.intercom
            };
        case END_PANEL_INTERCOM_SUCCESS:
            return {
                ...state,
                intercom: INITIAL_STATE.intercom
            };
        case CREATE_ACCESS_API_START_OVER:
            return {
                ...state,
                error: null
            };
        default:
            return state;
    }
};

export default comsReducer;
