import React from 'react';
// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { SearchOutlined } from '@ant-design/icons';

// icons
const icons = {
    SearchOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //
const lpr = {
    id: 'lpr',
    title: <FormattedMessage id="lpr" />,
    type: 'group',
    children: [
        {
            id: 'lprViewer',
            title: <FormattedMessage id="lprViewer" />,
            type: 'item',
            url: '/plate-search',
            icon: icons.SearchOutlined
        }
    ]
};

export default lpr;
