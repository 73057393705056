import {
    SET_ACTIVE_ITEM,
    SET_ACTIVE_ID,
    SET_ACTIVE_COMPONENT,
    SET_OPEN_DRAWER,
    SET_OPEN_COMPONENT_DRAWER,
    SET_MENU_ERROR
} from './types';

export const activeItem = item => {
    return {
        type: SET_ACTIVE_ITEM,
        payload: item
    };
};

export const activeID = id => {
    return {
        type: SET_ACTIVE_ID,
        payload: id
    };
};

export const activeComponent = component => {
    return {
        type: SET_ACTIVE_COMPONENT,
        payload: component
    };
};

export const openDrawer = open => {
    return {
        type: SET_OPEN_DRAWER,
        payload: open
    };
};

export const openComponentDrawer = open => {
    return {
        type: SET_OPEN_COMPONENT_DRAWER,
        payload: open
    };
};

export const hasError = error => {
    return {
        type: SET_MENU_ERROR,
        payload: error
    };
};

// activeItem(state, action) {
//   state.openItem = action.payload.openItem;
// },
// activeID(state, action) {
//   state.selectedID = action.payload;
// },
// activeComponent(state, action) {
//   state.openComponent = action.payload.openComponent;
// },
// openDrawer(state, action) {
//   state.drawerOpen = action.payload;
// },
// openComponentDrawer(state, action) {
//   state.componentDrawerOpen = action.payload.componentDrawerOpen;
// },
// hasError(state, action) {
//   state.error = action.payload;
// }
