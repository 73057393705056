/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { useMemo, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

// material-ui
import {
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    useMediaQuery,
    Button,
    Chip,
    Typography,
    TableContainer,
    Dialog,
    OutlinedInput
} from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { PopupTransition } from 'components/@extended/Transitions';

// third-party
import {
    useFilters,
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable
} from 'react-table';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// project import
import {
    CSVExport,
    CSVImport,
    HeaderSort,
    TablePagination
} from 'components/third-party/ReactTable';
import { ReservationDetailsModal } from 'components';
import { renderFilterTypes, DefaultColumnFilter } from 'utils/react-table';
import { openSnackbar } from 'store/actions/Snackbar';
import { connect } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { importReservations } from 'store/actions/Reservations';
import { flattenObject, parseFile } from 'utils/Helpers';
import { SearchOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

// ==============================|| SAMPLE PAGE ||============================== //

const StatusCell = ({ value }) => {
    switch (value) {
        case false:
            return <Chip color="error" label="Inactive" size="small" variant="light" />;
        case true:
            return <Chip color="success" label="Active" size="small" variant="light" />;
        default:
            return <Chip color="error" label="Inactive" size="small" variant="light" />;
    }
};

const TenantTable = ({ reservations, importReservations, openSnackbar }) => {
    const [file, setFile] = useState(null);
    const [reservation, setReservation] = useState(null);
    const [open, setOpen] = useState(false);
    const [inputSearchFilter, setInputSearchFilter] = useState([]);

    const currentDate = new Date();
    const startOfDay = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
    );

    const [localFromDate, setLocalFromDate] = useState(startOfDay);
    const [localToDate, setLocalToDate] = useState(currentDate);

    useEffect(() => {
        const parseCSV = async () => {
            if (file) {
                const result = await parseFile(file);
                const validationError = validateCSVFormat(result);
                if (validationError) {
                    openSnackbar({
                        open: true,
                        message: `Error: ${validationError}`,
                        variant: 'alert',
                        alert: {
                            color: 'error',
                            variant: 'outlined'
                        },
                        close: false
                    });
                } else {
                    importReservations({ reservationsData: result });
                }
            }
        };

        parseCSV();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [file]);

    const validateCSVFormat = csvData => {
        const requiredFields = [
            'first_name',
            'last_name',
            'check_in_date',
            'check_out_date'
        ];
        const fieldErrors = {};

        for (const entry of csvData) {
            for (const requiredField of requiredFields) {
                if (
                    !entry[requiredField] ||
                    entry[requiredField] === null ||
                    entry[requiredField] === 'null'
                ) {
                    if (!fieldErrors[requiredField]) {
                        fieldErrors[
                            requiredField
                        ] = `Field ${requiredField} is either missing or should not be null.`;
                    }
                }
            }

            const checkInDate = new Date(entry.check_in_date);
            const checkOutDate = new Date(entry.check_out_date);

            if (isNaN(checkInDate.getTime())) {
                if (!fieldErrors.check_in_date) {
                    fieldErrors.check_in_date = 'check_in_date should be a valid date.';
                }
            }

            if (isNaN(checkOutDate.getTime())) {
                if (!fieldErrors.check_out_date) {
                    fieldErrors.check_out_date = 'check_out_date should be a valid date.';
                }
            }

            if (checkOutDate <= checkInDate) {
                if (!fieldErrors.check_out_date) {
                    fieldErrors.check_out_date =
                        'check_out_date should be after check_in_date by at least one day.';
                }
            }
        }

        const errors = Object.values(fieldErrors);

        return errors.length > 0 ? errors.join(' ') : null;
    };

    const filteredData = useMemo(() => {
        if (inputSearchFilter && inputSearchFilter.length) {
            return inputSearchFilter;
        } else {
            return reservations.filter(item => {
                const checkInDate = new Date(item.check_in_date);
                return checkInDate >= localFromDate && checkInDate <= localToDate;
            });
        }
    }, [reservations, localFromDate, localToDate, inputSearchFilter]);

    const isToday = ({ from, to }) => {
        const today = dayjs().isSame(from, 'day') && dayjs().isSame(to, 'day');
        return today
            ? `Total Reservations: ${filteredData.length} (today)`
            : `Total Reservations: ${filteredData.length}`;
    };

    const reservationInputSearch = input => {
        if (input.trim() !== '') {
            const match = input
                .trim()
                .toString()
                .match(/[0-9-]+/g);

            if (!isNaN(input[0])) {
                // Address or Client Reservation ID
                const address = match && match[0];
                const filteredId = () => {
                    const idSuggestions = [];

                    reservations.filter(res => {
                        if (
                            res.client_reservation_id &&
                            res.client_reservation_id.toString().includes(address)
                        ) {
                            idSuggestions.push(res);
                        }
                        return true;
                    });
                    return idSuggestions;
                };
                const filteredAddress = () => {
                    const addressSuggestions = [];
                    reservations.filter(res => {
                        const address = res?.address?.address_1?.toLowerCase();
                        if (address.includes(input.toLowerCase()))
                            addressSuggestions.push(res);
                        return true;
                    });
                    return addressSuggestions;
                };

                const totalSuggestions = [...filteredId(), ...filteredAddress()];
                const finalSuggestions = totalSuggestions.filter(
                    (v, i, a) =>
                        a.findIndex(res => res.reservation_id === v.reservation_id) === i
                );
                setInputSearchFilter(finalSuggestions);
            } else {
                // Last Name
                const filteredName = () => {
                    const nameSuggestions = [];
                    reservations.filter(res => {
                        const firstName = res?.first_name?.toLowerCase();
                        const lastName = res?.last_name?.toLowerCase();
                        const fullName =
                            `${res.first_name} ${res.last_name} `.toLowerCase();

                        if (
                            firstName.includes(input.toLowerCase()) ||
                            lastName.includes(input.toLowerCase()) ||
                            fullName.includes(input.toLowerCase())
                        ) {
                            nameSuggestions.push(res);
                        }
                        return true;
                    });
                    return nameSuggestions;
                };

                const totalSuggestions = [...filteredName()];
                const finalSuggestions = totalSuggestions.filter(
                    (v, i, a) =>
                        a.findIndex(res => res.reservation_id === v.reservation_id) === i
                );
                setInputSearchFilter(finalSuggestions);
            }
        } else {
            setInputSearchFilter([]);
        }
    };

    const data = useMemo(
        () =>
            filteredData.map(item => ({
                ...item,
                name: `${item.last_name}${item.first_name ? ', ' + item.first_name : ''}`
            })),
        [filteredData]
    );

    const navigate = useNavigate();

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name'
            },
            {
                Header: 'Check-in Date',
                accessor: 'check_in_date',
                sortType: (rowA, rowB, columnId) => {
                    return (
                        new Date(rowA.original[columnId]) -
                        new Date(rowB.original[columnId])
                    );
                }
            },
            {
                Header: 'Check-out Date',
                accessor: 'check_out_date',
                sortType: (rowA, rowB, columnId) => {
                    return (
                        new Date(rowA.original[columnId]) -
                        new Date(rowB.original[columnId])
                    );
                }
            },
            {
                Header: 'Reservation ID',
                accessor: 'client_reservation_id'
            },
            {
                Header: 'Status',
                accessor: 'active',
                className: 'cell-center',
                sortType: (rowA, rowB, columnId) => {
                    const valueA = rowA.original[columnId] ? 1 : 0;
                    const valueB = rowB.original[columnId] ? 1 : 0;
                    return valueA - valueB;
                },
                Cell: StatusCell
            }
        ],
        []
    );

    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

    const filterTypes = useMemo(() => renderFilterTypes, []);
    const defaultColumn = useMemo(() => ({ Filter: DefaultColumnFilter }), []);
    const initialState = useMemo(
        () => ({
            filters: [{ id: 'status', value: '' }],
            pageIndex: 0,
            pageSize: 25,
            sortBy: [
                {
                    id: 'check_in_date',
                    desc: false
                }
            ]
        }),
        []
    );

    const handleClick = ({ original }) => {
        setReservation({ ...original });
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setReservation(null);
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        page,
        prepareRow,
        gotoPage,
        setPageSize,
        state: { pageIndex, pageSize }
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            initialState,
            filterTypes
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        usePagination
    );

    return (
        <Stack
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '75vh',
                width: '100%',
                overflow: 'hidden',
                justifyContent: 'space-between'
            }}
        >
            <Stack
                direction={matchDownSM ? 'column' : 'row'}
                spacing={1}
                justifyContent="space-between"
                alignItems="center"
                sx={{ p: 3, pb: 1 }}
            >
                <Stack direction={matchDownSM ? 'column' : 'row'} spacing={2}>
                    <OutlinedInput
                        onChange={e => reservationInputSearch(e.target.value)}
                        placeholder={'Last name, Address, ID)'}
                        id="reservation-search"
                        startAdornment={<SearchOutlined />}
                    />
                </Stack>
                <Stack direction="row" spacing={2}>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Typography align="left" variant="subtitle1">
                            Check-In From:
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                value={localFromDate}
                                onChange={newValue => {
                                    setInputSearchFilter([]);
                                    setLocalFromDate(newValue);
                                }}
                                maxDate={localToDate}
                                slotProps={{ textField: { size: 'small' } }}
                            />
                        </LocalizationProvider>

                        <Typography align="left" variant="subtitle1">
                            To:
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                value={localToDate}
                                onChange={newValue => {
                                    setInputSearchFilter([]);
                                    setLocalToDate(newValue);
                                }}
                                minDate={localFromDate}
                                slotProps={{ textField: { size: 'small' } }}
                            />
                        </LocalizationProvider>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                        <Button
                            variant="contained"
                            startIcon={<PlusOutlined />}
                            onClick={() => navigate('/new-reservation')}
                            size="small"
                        >
                            New Reservation
                        </Button>
                        <CSVExport
                            data={rows.map(row => flattenObject(row.original))}
                            filename="reservations.csv"
                        />
                        <CSVImport onImport={setFile} />
                    </Stack>
                </Stack>
            </Stack>
            <Stack
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '10%',
                    width: '100%',
                    justifyContent: 'center',
                    pl: 3
                }}
            >
                <Typography align="left" variant="subtitle1">
                    {isToday({ from: localFromDate, to: localToDate })}
                </Typography>
            </Stack>
            <Stack
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    width: '100%',
                    overflow: 'hidden',
                    justifyContent: 'space-between'
                }}
            >
                <TableContainer sx={{ maxHeight: '100%' }}>
                    <Table stickyHeader={true} {...getTableProps()}>
                        <TableHead sx={{ borderTopWidth: 1 }}>
                            {headerGroups.map((headerGroup, index) => (
                                <TableRow
                                    {...headerGroup.getHeaderGroupProps()}
                                    key={index}
                                >
                                    {headerGroup.headers.map((column, index) => (
                                        <TableCell
                                            {...column.getHeaderProps([
                                                { className: column.className },
                                                column => column.getSortByToggleProps()
                                            ])}
                                            sx={{
                                                width:
                                                    column.id === 'status'
                                                        ? '30%'
                                                        : 'auto'
                                            }}
                                            key={index}
                                        >
                                            <HeaderSort column={column} sort />
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableHead>
                        <TableBody {...getTableBodyProps()}>
                            {headerGroups.map((group, i) => (
                                <TableRow {...group.getHeaderGroupProps()} key={i}>
                                    {group.headers.map((column, index) => (
                                        <TableCell
                                            {...column.getHeaderProps([
                                                { className: column.className }
                                            ])}
                                            key={index}
                                        />
                                    ))}
                                </TableRow>
                            ))}
                            {page.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <Fragment key={i}>
                                        <TableRow
                                            {...row.getRowProps()}
                                            onClick={() => {
                                                handleClick(row);
                                            }}
                                            sx={{
                                                cursor: 'pointer',
                                                bgcolor: row.isSelected
                                                    ? alpha(
                                                          theme.palette.primary.lighter,
                                                          0.35
                                                      )
                                                    : 'inherit'
                                            }}
                                            key={i}
                                        >
                                            {row.cells.map((cell, i) => (
                                                <TableCell
                                                    {...cell.getCellProps([
                                                        {
                                                            className:
                                                                cell.column.className
                                                        }
                                                    ])}
                                                    key={i}
                                                >
                                                    {cell.render('Cell')}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </Fragment>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Stack sx={{ p: 2, bottom: 0, width: '100%' }} colSpan={7}>
                    <TablePagination
                        gotoPage={gotoPage}
                        rows={rows}
                        setPageSize={setPageSize}
                        pageIndex={pageIndex}
                        pageSize={pageSize}
                    />
                </Stack>
                <Dialog
                    maxWidth="sm"
                    TransitionComponent={PopupTransition}
                    keepMounted
                    fullWidth
                    onClose={() => setOpen(false)}
                    open={open}
                    sx={{
                        '& .MuiDialog-paper': { p: 0 },
                        transition: 'transform 225ms'
                    }}
                    aria-describedby="alert-dialog-slide-description"
                >
                    {open && (
                        <ReservationDetailsModal
                            onClose={handleClose}
                            reservation={reservation}
                        />
                    )}
                </Dialog>
            </Stack>
        </Stack>
    );
};

export default connect(null, {
    importReservations,
    openSnackbar
})(TenantTable);
