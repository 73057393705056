import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { auth } from 'config/firebase';
import { errorMessages } from 'utils/constants';

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        isInit: false,
        loading: false,
        resetPasswordSent: false,
        authUser: null,
        error: null
    },
    reducers: {
        resetAuthState: state => {
            state.loading = false;
            state.resetPasswordSent = false;
            state.authUser = null;
            state.error = null;
        },
        resetError: state => {
            state.error = null;
        },
        extraReducers: builder => {
            //LOGIN
            builder.addCase(login.pending, state => {
                state.loading = true;
            });
            builder.addCase(login.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.authUser = payload;
            });
            builder.addCase(login.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            });

            //LOGOUT
            builder.addCase(logout.pending, state => {
                state.loading = true;
            });
            builder.addCase(logout.fulfilled, state => {
                state.loading = false;
                state.authUser = null;
            });
            builder.addCase(logout.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            });

            //PASSWORD RESET
            builder.addCase(resetPassword.pending, state => {
                state.loading = true;
            });
            builder.addCase(resetPassword.fulfilled, state => {
                state.loading = false;
                state.resetPasswordSent = true;
            });
            builder.addCase(resetPassword.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            });
        }
    },
    extraReducers: builder => {
        //LOGIN
        builder.addCase(login.pending, state => {
            state.loading = true;
        });
        builder.addCase(login.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.authUser = payload;
        });
        builder.addCase(login.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        });

        //LOGOUT
        builder.addCase(logout.pending, state => {
            state.loading = true;
        });
        builder.addCase(logout.fulfilled, state => {
            state.loading = false;
            state.authUser = null;
        });
        builder.addCase(logout.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        });

        //PASSWORD RESET
        builder.addCase(resetPassword.pending, state => {
            state.loading = true;
        });
        builder.addCase(resetPassword.fulfilled, state => {
            state.loading = false;
            state.resetPasswordSent = true;
        });
        builder.addCase(resetPassword.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        });
    }
});

// =================|| THUNKS ||=================== //

export const login = createAsyncThunk('auth/login', async (data, thunkApi) => {
    const { email, password } = data;
    try {
        const {
            user: { apiKey, createdAt, emailVerified, isAnonymous, lastLoginAt, uid }
        } = await auth.signInWithEmailAndPassword(auth, email, password);
        return { apiKey, createdAt, email, emailVerified, isAnonymous, lastLoginAt, uid };
    } catch (error) {
        return thunkApi.rejectWithValue(errorMessages[error.code]);
    }
});

export const logout = createAsyncThunk('auth/logout', async thunkApi => {
    try {
        await auth.signOut(auth);
    } catch (error) {
        return thunkApi.rejectWithValue(errorMessages[error.code]);
    }
});

export const resetPassword = createAsyncThunk(
    'auth/reset_password',
    async (data, thunkApi) => {
        try {
            await auth.sendPasswordResetEmail(auth, data);
        } catch (error) {
            return thunkApi.rejectWithValue(errorMessages[error.code]);
        }
    }
);
