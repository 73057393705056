import React, { useMemo } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { AppBar, Toolbar, useMediaQuery } from '@mui/material';

// project import
import AppBarStyled from './AppBarStyled';
import HeaderContent from './HeaderContent';
import IconButton from 'components/@extended/IconButton';

import { MenuOrientation, ThemeMode } from 'config';
import useConfig from 'hooks/useConfig';
import { connect } from 'react-redux';
import { openDrawer } from 'store/actions/Menu';

// assets
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const Header = ({ drawerOpen, openDrawer }) => {
    const theme = useTheme();
    const downLG = useMediaQuery(theme.breakpoints.down('lg'));
    const { menuOrientation } = useConfig();

    const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downLG;

    // header content
    const headerContent = useMemo(() => <HeaderContent />, []);

    const iconBackColorOpen =
        theme.palette.mode === ThemeMode.DARK ? 'grey.200' : 'grey.300';
    const iconBackColor =
        theme.palette.mode === ThemeMode.DARK ? 'background.default' : 'grey.100';

    // common header
    const mainHeader = (
        <Toolbar sx={{ justifyContent: 'space-between' }}>
            {!isHorizontal ? (
                <IconButton
                    aria-label="open drawer"
                    onClick={() => openDrawer(!drawerOpen)}
                    edge="start"
                    color="secondary"
                    variant="light"
                    sx={{
                        color: 'text.primary',
                        bgcolor: drawerOpen ? iconBackColorOpen : iconBackColor,
                        ml: { xs: 0, lg: -2 }
                    }}
                >
                    {!drawerOpen ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                </IconButton>
            ) : null}
            {headerContent}
        </Toolbar>
    );

    // app-bar params
    const appBar = {
        position: 'fixed',
        color: 'inherit',
        elevation: 0,
        sx: {
            borderBottom: `1px solid ${theme.palette.divider}`,
            zIndex: 1200,
            width: isHorizontal
                ? '100%'
                : drawerOpen
                ? 'calc(100% - 260px)'
                : { xs: '100%', lg: 'calc(100% - 60px)' }
            // boxShadow: theme.customShadows.z1
        }
    };

    return (
        <>
            {!downLG ? (
                <AppBarStyled open={drawerOpen} {...appBar}>
                    {mainHeader}
                </AppBarStyled>
            ) : (
                <AppBar {...appBar}>{mainHeader}</AppBar>
            )}
        </>
    );
};

const mapStateToProps = ({ menu }) => {
    const { drawerOpen } = menu;
    return { drawerOpen };
};

export default connect(mapStateToProps, { openDrawer })(Header);
