import {
    ADD_MEMBER,
    ADD_MEMBER_SUCCESS,
    ADD_MEMBER_FAILURE,
    RESET_MEMBER_CREATION_STATE,
    IMPORT_MEMBERS,
    IMPORT_MEMBERS_SUCCESS,
    IMPORT_MEMBERS_FAILURE,
    RESET_MEMBER_IMPORT_STATE,
    GET_IMPORTED_MEMBERS_FOR_ORG,
    GET_IMPORTED_MEMBERS_FOR_ORG_SUCCESS,
    GET_IMPORTED_MEMBERS_FOR_ORG_FAILURE,
    SET_INVITE_PENDING_MEMBERS,
    RESET_INVITE_PENDING_MEMBERS,
    ADD_INVITE_PENDING_MEMBERS,
    ADD_INVITE_PENDING_MEMBERS_SUCCESS,
    ADD_INVITE_PENDING_MEMBERS_FAILURE,
    GET_INVITED_MEMBERS_FOR_ORG,
    GET_INVITED_MEMBERS_FOR_ORG_SUCCESS,
    GET_INVITED_MEMBERS_FOR_ORG_FAILURE,
    GET_COMMUNITY_MEMBERS_FOR_ORG,
    GET_COMMUNITY_MEMBERS_FOR_ORG_SUCCESS,
    GET_COMMUNITY_MEMBERS_FOR_ORG_FAILURE,
    UPDATE_USER_AVATAR,
    UPDATE_USER_AVATAR_SUCCESS,
    UPDATE_USER_AVATAR_FAILURE,
    UPDATE_USER_PROFILE_INFO,
    UPDATE_USER_PROFILE_INFO_SUCCESS,
    UPDATE_USER_PROFILE_INFO_FAILURE,
    UPDATE_USER_PROFILE_INFO_RESET,
    SET_ACTIVE_USER_ORG,
    SET_PAGE_INDEX,
    SET_ROW_COUNT
} from '../actions/types';

const INITIAL_STATE = {
    addingMember: false,
    memberCreationError: null,
    addedMemberEmail: null,
    importingMembers: false,
    importMembersSuccess: false,
    importMembersError: null,
    importedMembers: null,
    loadingImportedMembers: false,
    importedMembersLoadError: null,
    invitePendingMembers: null,
    addingInvitePendingMembers: false,
    addingInvitePendingMembersError: null,
    addingInvitePendingMembersSuccess: false,
    invitedMembers: null,
    loadingInvitedMembers: false,
    invitedMembersLoadError: null,
    communityMembers: null,
    loadingCommunityMembers: false,
    communityMembersLoadError: null,
    uploadingAvatar: false,
    uploadAvatarSuccess: false,
    uploadAvatarError: null,
    updatingAccountInfo: false,
    updatingAccountInfoSuccess: false,
    updatingAccountInfoError: null,
    importPage: 0,
    importRow: 10,
    invitePage: 0,
    inviteRow: 10
};

const managerReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ADD_MEMBER:
            return {
                ...state,
                addingMember: true,
                addedMemberEmail: null
            };
        case ADD_MEMBER_SUCCESS:
            return {
                ...state,
                addingMember: false,
                addedMemberEmail: action.payload
            };
        case ADD_MEMBER_FAILURE:
            return {
                ...state,
                addingMember: false,
                memberCreationError: action.payload
            };
        case RESET_MEMBER_CREATION_STATE:
            return {
                ...state,
                addingMember: false,
                memberCreationError: null,
                addedMemberEmail: null
            };
        case IMPORT_MEMBERS:
            return {
                ...state,
                importingMembers: true,
                importMembersSuccess: false,
                importMembersError: null
            };

        case IMPORT_MEMBERS_SUCCESS:
            return {
                ...state,
                importingMembers: false,
                importMembersSuccess: action.payload,
                importMembersError: null
            };
        case IMPORT_MEMBERS_FAILURE:
            return {
                ...state,
                importingMembers: false,
                importMembersSuccess: false,
                importMembersError: action.payload
            };
        case RESET_MEMBER_IMPORT_STATE:
            return {
                ...state,
                importingMembers: false,
                importMembersSuccess: false,
                importMembersError: null
            };
        case GET_IMPORTED_MEMBERS_FOR_ORG:
            return {
                ...state,
                importedMembers: null,
                loadingImportedMembers: true,
                importedMembersLoadError: null
            };
        case GET_IMPORTED_MEMBERS_FOR_ORG_SUCCESS:
            return {
                ...state,
                importedMembers: action.payload,
                loadingImportedMembers: false,
                importedMembersLoadError: null
            };
        case GET_IMPORTED_MEMBERS_FOR_ORG_FAILURE:
            return {
                ...state,
                importedMembers: null,
                loadingImportedMembers: false,
                importedMembersLoadError: action.payload
            };
        case SET_INVITE_PENDING_MEMBERS:
            return {
                ...state,
                invitePendingMembers: action.payload
            };
        case RESET_INVITE_PENDING_MEMBERS:
            return {
                ...state,
                invitePendingMembers: null
            };
        case ADD_INVITE_PENDING_MEMBERS:
            return {
                ...state,
                addingInvitePendingMembers: true,
                addingInvitePendingMembersError: null,
                addingInvitePendingMembersSuccess: false
            };
        case ADD_INVITE_PENDING_MEMBERS_SUCCESS:
            return {
                ...state,
                addingInvitePendingMembers: false,
                addingInvitePendingMembersError: null,
                addingInvitePendingMembersSuccess: true
            };
        case ADD_INVITE_PENDING_MEMBERS_FAILURE:
            return {
                ...state,
                addingInvitePendingMembers: false,
                addingInvitePendingMembersError: action.payload,
                addingInvitePendingMembersSuccess: false
            };
        case GET_INVITED_MEMBERS_FOR_ORG:
            return {
                ...state,
                invitedMembers: null,
                loadingInvitedMembers: true,
                invitedMembersLoadError: null
            };
        case GET_INVITED_MEMBERS_FOR_ORG_SUCCESS:
            return {
                ...state,
                invitedMembers: action.payload,
                loadingInvitedMembers: false,
                invitedMembersLoadError: null
            };
        case GET_INVITED_MEMBERS_FOR_ORG_FAILURE:
            return {
                ...state,
                invitedMembers: null,
                loadingInvitedMembers: false,
                invitedMembersLoadError: action.payload
            };
        case GET_COMMUNITY_MEMBERS_FOR_ORG:
            return {
                ...state,
                communityMembers: null,
                loadingCommunityMembers: true,
                communityMembersLoadError: null
            };
        case GET_COMMUNITY_MEMBERS_FOR_ORG_SUCCESS:
            return {
                ...state,
                communityMembers: action.payload,
                loadingCommunityMembers: false,
                communityMembersLoadError: null
            };
        case GET_COMMUNITY_MEMBERS_FOR_ORG_FAILURE:
            return {
                ...state,
                communityMembers: null,
                loadingCommunityMembers: false,
                communityMembersLoadError: action.payload
            };
        case UPDATE_USER_AVATAR:
            return {
                ...state,
                uploadingAvatar: true,
                uploadAvatarSuccess: false,
                uploadAvatarError: null
            };
        case UPDATE_USER_AVATAR_SUCCESS:
            return {
                ...state,
                uploadingAvatar: false,
                uploadAvatarSuccess: action.payload,
                uploadAvatarError: null
            };
        case UPDATE_USER_AVATAR_FAILURE:
            return {
                ...state,
                uploadingAvatar: false,
                uploadAvatarSuccess: false,
                uploadAvatarError: action.payload
            };
        case UPDATE_USER_PROFILE_INFO:
            return {
                ...state,
                updatingAccountInfo: true,
                updatingAccountInfoSuccess: false,
                updatingAccountInfoError: null
            };
        case UPDATE_USER_PROFILE_INFO_SUCCESS:
            return {
                ...state,
                updatingAccountInfo: false,
                updatingAccountInfoSuccess: true,
                updatingAccountInfoError: null
            };
        case UPDATE_USER_PROFILE_INFO_FAILURE:
            return {
                ...state,
                updatingAccountInfo: false,
                updatingAccountInfoSuccess: false,
                updatingAccountInfoError: action.payload
            };
        case UPDATE_USER_PROFILE_INFO_RESET:
            return {
                ...state,
                updatingAccountInfo: false,
                updatingAccountInfoSuccess: false,
                updatingAccountInfoError: null
            };
        case SET_ACTIVE_USER_ORG:
            return {
                ...state,
                importedMembers: [],
                invitedMembers: [],
                importPage: INITIAL_STATE.importPage,
                importRow: INITIAL_STATE.importRow,
                invitePage: INITIAL_STATE.invitePage,
                inviteRow: INITIAL_STATE.inviteRow
            };
        case SET_PAGE_INDEX:
            return {
                ...state,
                importPage:
                    action.payload.type === 'import'
                        ? action.payload.value
                        : state.importPage,
                invitePage:
                    action.payload.type === 'invite'
                        ? action.payload.value
                        : state.invitePage
            };
        case SET_ROW_COUNT:
            return {
                ...state,
                importRow:
                    action.payload.type === 'import'
                        ? action.payload.value
                        : state.importRow,
                inviteRow:
                    action.payload.type === 'invite'
                        ? action.payload.value
                        : state.inviteRow
            };
        default:
            return state;
    }
};

export default managerReducer;
