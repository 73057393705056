import {
    GET_LPR_COLLECTION,
    GET_LPR_COLLECTION_SUCCESS,
    GET_LPR_COLLECTION_FAILURE,
    SET_LPR_QUERY
} from './types';

export const getLprCollection = lpr => {
    return {
        type: GET_LPR_COLLECTION,
        payload: lpr
    };
};

export const getLprCollectionSuccess = lpr => {
    return {
        type: GET_LPR_COLLECTION_SUCCESS,
        payload: lpr
    };
};

export const getLprCollectionFailure = error => {
    return {
        type: GET_LPR_COLLECTION_FAILURE,
        payload: error
    };
};

export const executeLprQuery = query => {
    return {
        type: SET_LPR_QUERY,
        payload: query
    };
};
