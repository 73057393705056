/* eslint-disable react/no-array-index-key */
// React
import React, { useState, useMemo, Fragment } from 'react';

// Components
import { OperationDetailsModal } from 'components';
import { TablePagination } from 'components/third-party/ReactTable';
import { PopupTransition } from 'components/@extended/Transitions';

// Packages
import {
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Dialog,
    Typography
} from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import {
    useFilters,
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable
} from 'react-table';

// Utils
import { renderFilterTypes, DefaultColumnFilter } from 'utils/react-table';

const OperationTable = ({ data, loading, error, completeDeviceOperation }) => {
    const [operation, setOperation] = useState(null);
    const [open, setOpen] = useState(false);

    const columns = useMemo(
        () => [
            {
                Header: 'Resident Name',
                accessor: `resident_name`
            },
            {
                Header: 'Group Name',
                accessor: 'group_name',
                className: 'cell-center'
            },
            {
                Header: 'Device Number',
                accessor: 'device_number',
                className: 'cell-center'
            },

            {
                Header: 'Device Type',
                accessor: 'device_type',
                className: 'cell-center'
            },
            {
                Header: 'Facility Code',
                accessor: 'facility_code',
                className: 'cell-center'
            },
            {
                Header: 'Operation',
                accessor: 'type',
                className: 'cell-center'
            },
            {
                Header: 'Operation Date',
                accessor: 'operation_date_string',
                className: 'cell-center'
            }
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const handleClick = ({ original }) => {
        setOperation({ ...original });
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setOperation(null);
    };

    const theme = useTheme();

    const filterTypes = useMemo(() => renderFilterTypes, []);
    const defaultColumn = useMemo(() => ({ Filter: DefaultColumnFilter }), []);
    const initialState = useMemo(
        () => ({
            filters: [],
            pageIndex: 0,
            pageSize: 10
        }),
        []
    );
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        page,
        prepareRow,
        gotoPage,
        setPageSize,
        state: { pageIndex, pageSize }
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            initialState,
            filterTypes
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        usePagination
    );

    return (
        <Stack
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                width: '100%',
                overflow: 'hidden',
                justifyContent: 'space-between'
            }}
        >
            <TableContainer sx={{ maxHeight: '100%' }}>
                <Table stickyHeader={true} {...getTableProps()}>
                    <TableHead sx={{ borderTopWidth: 1 }}>
                        {headerGroups.map((headerGroup, index) => (
                            <TableRow {...headerGroup.getHeaderGroupProps()} key={index}>
                                {headerGroup.headers.map((column, index) => (
                                    <TableCell
                                        {...column.getHeaderProps([
                                            { className: column.className },
                                            column => column.getSortByToggleProps()
                                        ])}
                                        sx={{
                                            width: 'auto'
                                        }}
                                        key={index}
                                    >
                                        {column.render('Header')}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableHead>
                    <TableBody {...getTableBodyProps()}>
                        {data && data.length === 0 && !loading ? (
                            <TableRow colSpan="4">
                                <TableCell>
                                    <Typography
                                        variant="h3"
                                        component="span"
                                        color="text.secondary"
                                        sx={{
                                            textTransform: 'capitalize',
                                            width: '100%'
                                        }}
                                    >
                                        {`No Pending Device Operations`}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ) : (
                            <>
                                {headerGroups.map((group, i) => (
                                    <TableRow {...group.getHeaderGroupProps()} key={i}>
                                        {group.headers.map((column, index) => (
                                            <TableCell
                                                {...column.getHeaderProps([
                                                    { className: column.className }
                                                ])}
                                                key={index}
                                            />
                                        ))}
                                    </TableRow>
                                ))}
                                {page.map((row, i) => {
                                    prepareRow(row);
                                    return (
                                        <Fragment key={i}>
                                            <TableRow
                                                {...row.getRowProps()}
                                                onClick={() => {
                                                    handleClick(row);
                                                }}
                                                sx={{
                                                    cursor: 'pointer',
                                                    textTransform: 'capitalize',
                                                    bgcolor: row.isSelected
                                                        ? alpha(
                                                              theme.palette.primary
                                                                  .lighter,
                                                              0.35
                                                          )
                                                        : 'inherit'
                                                }}
                                                key={i}
                                            >
                                                {row.cells.map((cell, i) => {
                                                    if (
                                                        cell.column.Header === 'Operation'
                                                    ) {
                                                        return (
                                                            <TableCell
                                                                {...cell.getCellProps([
                                                                    {
                                                                        className:
                                                                            cell.column
                                                                                .className
                                                                    }
                                                                ])}
                                                                key={i}
                                                            >
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        justifyContent:
                                                                            'center',
                                                                        alignItems:
                                                                            'center',
                                                                        height: '80%',
                                                                        backgroundColor:
                                                                            cell.value ===
                                                                            'add'
                                                                                ? 'rgba(45, 143, 87, .1)'
                                                                                : cell.value ===
                                                                                  'edit'
                                                                                ? 'rgba(255, 192, 0, .1)'
                                                                                : 'rgba(255, 0, 0, .1)',
                                                                        borderRadius:
                                                                            '5px',
                                                                        border: '1px solid',
                                                                        borderColor:
                                                                            cell.value ===
                                                                            'add'
                                                                                ? 'rgba(45, 143, 87, 1)'
                                                                                : cell.value ===
                                                                                  'edit'
                                                                                ? 'rgba(255, 192, 0, 1)'
                                                                                : 'rgba(255, 0, 0, 1)'
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        sx={{
                                                                            fontSize:
                                                                                '13px',
                                                                            marginX:
                                                                                '10px'
                                                                        }}
                                                                        component="span"
                                                                        variant="body1"
                                                                        textTransform={
                                                                            'capitalize'
                                                                        }
                                                                        color={
                                                                            cell.value ===
                                                                            'add'
                                                                                ? 'rgba(45, 143, 87, 1)'
                                                                                : cell.value ===
                                                                                  'edit'
                                                                                ? 'rgba(255, 192, 0, 1)'
                                                                                : 'rgba(255, 0, 0, 1)'
                                                                        }
                                                                    >
                                                                        {cell.render(
                                                                            'Cell'
                                                                        )}
                                                                    </Typography>
                                                                </div>
                                                            </TableCell>
                                                        );
                                                    } else {
                                                        return (
                                                            <TableCell
                                                                {...cell.getCellProps([
                                                                    {
                                                                        className:
                                                                            cell.column
                                                                                .className
                                                                    }
                                                                ])}
                                                                key={i}
                                                            >
                                                                {cell.render('Cell')}
                                                            </TableCell>
                                                        );
                                                    }
                                                })}
                                            </TableRow>
                                        </Fragment>
                                    );
                                })}
                            </>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Stack sx={{ p: 2, bottom: 0, width: '100%' }} colSpan={7}>
                <TablePagination
                    gotoPage={gotoPage}
                    rows={rows}
                    setPageSize={setPageSize}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                />
            </Stack>
            <Dialog
                maxWidth="md"
                TransitionComponent={PopupTransition}
                keepMounted
                fullWidth
                onClose={() => setOpen(false)}
                open={open}
                sx={{
                    '& .MuiDialog-paper': { p: 0 },
                    transition: 'transform 225ms'
                }}
                aria-describedby="alert-dialog-slide-description"
            >
                {open && (
                    <OperationDetailsModal
                        onClose={handleClose}
                        operation={operation}
                        completeOperation={completeDeviceOperation}
                    />
                )}
            </Dialog>
        </Stack>
    );
};

export default OperationTable;
