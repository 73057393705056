// React
import React from 'react';

// Packages
import {
    Box,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Stack,
    Typography,
    Button
} from '@mui/material';

// Styles
import 'react-widgets/scss/styles.scss';

// Utils
import { formatThreadTime } from 'utils/Helpers';

const ReservationDetailsModal = ({ onClose, reservation, completeOperation }) => {
    return (
        <>
            <DialogTitle
                sx={{ textTransform: 'capitalize' }}
            >{`Reservation Details`}</DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5, height: 450 }}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Stack spacing={0.5}>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography
                                    align="left"
                                    variant="subtitle1"
                                    sx={{ marginRight: '5px' }}
                                >
                                    Client Reservation ID:
                                </Typography>
                                <Typography
                                    align="left"
                                    variant="subtitle1"
                                    color="text.secondary"
                                >
                                    {reservation.client_reservation_id}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography
                                    align="left"
                                    variant="subtitle1"
                                    sx={{ marginRight: '5px' }}
                                >
                                    First Name:
                                </Typography>
                                <Typography
                                    align="left"
                                    variant="subtitle1"
                                    color="text.secondary"
                                    textTransform="capitalize"
                                >
                                    {reservation.first_name}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography
                                    align="left"
                                    variant="subtitle1"
                                    sx={{ marginRight: '5px' }}
                                >
                                    Last Name:
                                </Typography>
                                <Typography
                                    align="left"
                                    variant="subtitle1"
                                    color="text.secondary"
                                    textTransform="capitalize"
                                >
                                    {reservation.last_name}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography
                                    align="left"
                                    variant="subtitle1"
                                    sx={{ marginRight: '5px' }}
                                >
                                    Check-in Date:
                                </Typography>
                                <Typography
                                    align="left"
                                    variant="subtitle1"
                                    color="text.secondary"
                                >
                                    {reservation.check_in_date}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography
                                    align="left"
                                    variant="subtitle1"
                                    sx={{ marginRight: '5px' }}
                                >
                                    Check-in Time:
                                </Typography>
                                <Typography
                                    align="left"
                                    variant="subtitle1"
                                    color="text.secondary"
                                >
                                    {formatThreadTime(reservation.access_begins.seconds)}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography
                                    align="left"
                                    variant="subtitle1"
                                    sx={{ marginRight: '5px' }}
                                >
                                    Check-out Date:
                                </Typography>
                                <Typography
                                    align="left"
                                    variant="subtitle1"
                                    color="text.secondary"
                                >
                                    {reservation.check_out_date}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography
                                    align="left"
                                    variant="subtitle1"
                                    sx={{ marginRight: '5px' }}
                                >
                                    Check-out Time:
                                </Typography>
                                <Typography
                                    align="left"
                                    variant="subtitle1"
                                    color="text.secondary"
                                >
                                    {formatThreadTime(reservation.access_expires.seconds)}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography
                                    align="left"
                                    variant="subtitle1"
                                    sx={{ marginRight: '5px' }}
                                >
                                    Phone:
                                </Typography>
                                <Typography
                                    align="left"
                                    variant="subtitle1"
                                    color="text.secondary"
                                >
                                    {reservation.phone?.number
                                        ? reservation.phone.number
                                        : 'N/A'}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography
                                    align="left"
                                    variant="subtitle1"
                                    sx={{ marginRight: '5px' }}
                                >
                                    Email:
                                </Typography>
                                <Typography
                                    align="left"
                                    variant="subtitle1"
                                    color="text.secondary"
                                >
                                    {reservation.email ? reservation.email : 'N/A'}
                                </Typography>
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item sx={{ p: 0 }} xs={6}>
                        <Stack
                            sx={{
                                position: 'absolute',
                                bottom: '10%',
                                width: '45%',
                                p: 0
                            }}
                        >
                            <Stack
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    height: '100%',
                                    width: '100%'
                                }}
                            >
                                <Button
                                    sx={{ width: '100%' }}
                                    color={'primary'}
                                    type="submit"
                                    variant="contained"
                                    onClick={onClose}
                                >
                                    {`Close`}
                                </Button>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
            </DialogContent>
        </>
    );
};

export default ReservationDetailsModal;
