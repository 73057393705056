import PropTypes from 'prop-types';

// material-ui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

// ==============================|| DRAWER HEADER - STYLED ||============================== //

const DrawerHeaderStyled = styled(Box, { shouldForwardProp: prop => prop !== 'open' })(
    ({ theme, open }) => ({
        ...theme.mixins.toolbar,
        display: 'flex',
        alignItems: 'center',
        justifyContent: open ? 'flex-start' : 'center',
        paddingLeft: theme.spacing(open ? 3 : 0),
        backgroundColor: '#fff'
    })
);

DrawerHeaderStyled.propTypes = {
    theme: PropTypes.object,
    open: PropTypes.bool
};

export default DrawerHeaderStyled;
