import React, { lazy } from 'react';

// project import
import GuestGuard from 'utils/route-guard/GuestGuard';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';

// render - login
const AuthLogin = Loadable(lazy(() => import('pages/auth/login')));
// const AuthRegister = Loadable(lazy(() => import('pages/auth/register')));
const AuthForgotPassword = Loadable(lazy(() => import('pages/auth/forgot-password')));
const AuthCheckMail = Loadable(lazy(() => import('pages/auth/check-mail')));
const AuthResetPassword = Loadable(lazy(() => import('pages/auth/ResetPassword')));
const NonAuthResetPassword = Loadable(
    lazy(() => import('pages/auth/NonAuthResetPassword'))
);
const AuthCodeVerification = Loadable(lazy(() => import('pages/auth/code-verification')));
const RegisterUser = Loadable(lazy(() => import('pages/auth/register-user')));
const ResendInvite = Loadable(lazy(() => import('pages/auth/resend-invite')));
const LogoutPrompt = Loadable(lazy(() => import('pages/auth/logout')));
const AddRegistration = Loadable(lazy(() => import('pages/main-pages/AddRegistration')));

// ==============================|| AUTH ROUTING ||============================== //

const AuthRoutes = {
    path: '/',
    children: [
        {
            path: '/',
            element: (
                <GuestGuard>
                    <CommonLayout />
                </GuestGuard>
            ),
            children: [
                {
                    path: '/',
                    element: <AuthLogin />
                },
                {
                    path: 'login',
                    element: <AuthLogin />
                },
                {
                    path: 'code',
                    element: <AuthLogin />
                },
                {
                    path: 'new-password',
                    element: <AuthLogin />
                },
                // {
                //   path: 'register',
                //   element: <AuthRegister />
                // },
                {
                    path: 'forgot-password',
                    element: <AuthForgotPassword />
                },
                {
                    path: 'check-mail',
                    element: <AuthCheckMail />
                },
                {
                    path: 'reset-password-authenticated',
                    element: <AuthResetPassword />
                },
                {
                    path: 'auth-service',
                    element: <NonAuthResetPassword />
                },
                {
                    path: 'logout',
                    element: <LogoutPrompt />
                },
                {
                    path: 'code-verification',
                    element: <AuthCodeVerification />
                },
                {
                    path: 'register-user',
                    element: <RegisterUser />
                },
                {
                    path: 'resend-invite',
                    element: <ResendInvite />
                },
                {
                    path: 'register/:shortCode',
                    element: <AddRegistration />
                }
            ]
        }
    ]
};

export default AuthRoutes;
