import React from 'react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

// project import
import { connect } from 'react-redux';
import { userTypes } from 'utils/constants';

// ==============================|| AUTH GUARD ||============================== //

const AdminGuard = ({ children, authUser, userData }) => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!authUser) {
            navigate('login', { replace: true });
        }
    }, [authUser, navigate]);

    if (userData && userData?.type !== userTypes.SUPER_ADMIN) {
        return <Navigate to="/maintenance/404" state={{ from: location }} />;
    } else {
        return children;
    }
};

AdminGuard.propTypes = {
    children: PropTypes.node
};

const mapStateToProps = ({ auth, user, org }) => {
    const { authUser, managerIsChangingPassword } = auth;
    const { orgs } = org;
    const { activeOrg, userData } = user;
    return { authUser, orgs, activeOrg, userData, managerIsChangingPassword };
};

export default connect(mapStateToProps, {})(AdminGuard);
