import React from 'react';
// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { VideoCameraOutlined, ScissorOutlined, TableOutlined } from '@ant-design/icons';

// icons
const icons = {
    VideoCameraOutlined,
    ScissorOutlined,
    TableOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const cloudVideo = {
    id: 'cloud_video',
    title: <FormattedMessage id="cloudVideo" />,
    type: 'group',
    children: [
        {
            id: 'liveView',
            title: <FormattedMessage id="liveView" />,
            type: 'item',
            url: '/live-view',
            icon: icons.TableOutlined
        },
        {
            id: 'cameras',
            title: <FormattedMessage id="cameras" />,
            type: 'item',
            url: '/cameras',
            icon: icons.VideoCameraOutlined
        },
        {
            id: 'clips',
            title: <FormattedMessage id="clips" />,
            type: 'item',
            url: '/clips',
            icon: icons.ScissorOutlined
        }
    ]
};

export default cloudVideo;
