// User
export const _userData = state => state.user.userData;
export const _activeOrg = state => state.user.activeOrg;

// Access
export const _accessLog = state => state.access.accessLog;

// Orgs
export const _orgs = state => state.org.orgs;
export const _selectedOrg = state => state.org.selectedOrg;

// Init
export const _appInitialized = state => state.init.appInitialized;

// Manager
export const _communityMembers = state => state.manager.communityMembers;
export const _importedMembers = state => state.manager.importedMembers;

// Router
export const _getCurrentRoute = state => state.router.location.pathname;

// Intercom
export const _getActiveIntercom = state => state.coms.intercom;

// Snackbar
export const _getSnackbarOpen = state => state.snack.open;
export const _getSnackbarMessage = state => state.snack.message;
export const _getSnackbarIsGueard = state => state.snack.isGuardAlert;

// LPR
export const _lprDocs = state => state.lpr.lprDocs;
export const _lprIds = state => state.lpr.lprIds;
export const _fromDate = state => state.lpr.fromDate;
export const _toDate = state => state.lpr.toDate;
