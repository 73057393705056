import {
    STORE_CALLS_COLLECTION,
    FIELD_PANEL_CALL,
    END_PANEL_CALL_SUCCESS,
    END_PANEL_INTERCOM_SUCCESS,
    CREATE_ACCESS_API_START_OVER
} from '../actions/types';

const INITIAL_STATE = {
    active: [],
    pending: [],
    call: null,
    error: null
};

// const INITIAL_STATE = {
//     active: [],
//     pending: [],
//     call: {
//         active_station_roll: 'EqMIB52pvyUhage8pdl5',
//         field_end: null,
//         panel_name: 'Gate 1',
//         panel_id: 'UdZsNFcGe7k7uhN6h7Kf4NRetxnh',
//         id: 'p5pDlQfgI0BuDgBR0RrT',
//         fielded_by: null,
//         field_start: null,
//         status: 'pending',
//         org_id: 'SFig3GvnrsJr0d2zdiIO'
//     }
// };

const callsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case STORE_CALLS_COLLECTION:
            return {
                ...state,
                active: action.payload.active,
                pending: action.payload.pending
            };
        case FIELD_PANEL_CALL:
            return {
                ...state,
                call: action.payload.panelCall
            };
        case END_PANEL_CALL_SUCCESS:
            return {
                ...state,
                call: INITIAL_STATE.call,
                accessGranted: false
            };
        case END_PANEL_INTERCOM_SUCCESS:
            return {
                ...state,
                call: INITIAL_STATE.call,
                accessGranted: false
            };
        case CREATE_ACCESS_API_START_OVER:
            return {
                ...state,
                accessGranted: false,
                error: null
            };
        default:
            return state;
    }
};

export default callsReducer;
