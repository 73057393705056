import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { MY_FAMILY_ADD, MY_FAMILY_EDIT } from 'utils/route-constants';
import { Grid, Typography, Button, Box, List, ListItem } from '@mui/material';

const FamilyList = ({ members, registrations, userData, selectedProperty }) => {
    const navigate = useNavigate();

    registrations = registrations || [];
    members = members || [];

    const membersAndRegistrations = [...registrations, ...members];
    const currentUserProperty = userData?.properties[selectedProperty?.id];

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box
                    sx={{
                        mb: 6,
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    <Box>
                        <Typography
                            variant="h3"
                            mb={0}
                            color="text.primary"
                            fontWeight="bold"
                            textTransform="capitalize"
                        >
                            {userData?.first_name || ''} {userData?.last_name || ''}
                        </Typography>
                        <Typography
                            variant="body1"
                            color="text.secondary"
                            className="small"
                            textTransform="capitalize"
                        >
                            {selectedProperty?.address?.full_address || ''}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                            alignItems: 'center',
                            width: { xs: '100%', sm: 'auto' },
                            mt: { xs: 2, sm: 0 }
                        }}
                    >
                        {currentUserProperty?.type === 'member' &&
                            currentUserProperty?.primary &&
                            currentUserProperty?.permissions?.invite && (
                                <Button
                                    component={Link}
                                    to={MY_FAMILY_ADD}
                                    variant="contained"
                                    color="primary"
                                    sx={{ width: { xs: '100%', sm: 'auto' } }}
                                >
                                    Add Family Member
                                </Button>
                            )}
                    </Box>
                </Box>
                <Box sx={{ height: '45vh', overflow: 'auto' }}>
                    <List sx={{ padding: 0, margin: 0, overflow: 'auto' }}>
                        {membersAndRegistrations?.map((member, index) => (
                            <ListItem
                                key={member?.uid || member?.id}
                                onClick={() =>
                                    navigate(
                                        `${MY_FAMILY_EDIT}/${member?.uid || member?.id}`
                                    )
                                }
                                sx={{
                                    cursor: 'pointer',
                                    py: 1,
                                    borderBottom:
                                        index === members.length - 1
                                            ? 'none'
                                            : '1px solid #ccc',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'start'
                                }}
                            >
                                <Typography
                                    variant="h5"
                                    mb={0}
                                    color="text.primary"
                                    textTransform="capitalize"
                                >
                                    {member.first_name} {member.last_name}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    color="text.secondary"
                                    textTransform="capitalize"
                                >
                                    {member?.member_type
                                        ? 'Pending'
                                        : member?.properties[selectedProperty?.id]?.type}
                                </Typography>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = ({ guests, user, properties }) => {
    const { guestInvites, guestAccessKeys, oneTimeAccessCodes } = guests;
    const { userData } = user;
    const { selectedProperty } = properties;
    return {
        guestInvites,
        userData,
        guestAccessKeys,
        oneTimeAccessCodes,
        selectedProperty
    };
};

export default connect(mapStateToProps, null)(FamilyList);
// export default FamilyList;
