import React from 'react';
import { Box, Typography } from '@mui/material';

const DisplayOtc = ({ otc, children }) => {
    function formatTimestamp(timestamp) {
        const date = new Date(timestamp * 1000);

        const options = {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timeZoneName: 'short'
        };

        return new Intl.DateTimeFormat('en-US', options).format(date);
    }

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                mx: 'auto',
                mb: 3,
                pt: 4,
                width: '75%',
                '& > p': {
                    fontSize: '20px',
                    textAlign: 'center',
                    mt: 5,
                    mb: 5
                }
            }}
        >
            <Typography
                variant="h2"
                color="secondary"
                sx={{
                    fontWeight: '900'
                }}
            >
                {otc.name}
            </Typography>
            <Typography variant="h1" color="primary" sx={{}}>
                {otc.code}
            </Typography>
            <Typography variant="body1" color="secondary" sx={{ fontSize: '20px' }}>
                Share this code with your guest or vendor for giving access. This code
                will expire at{' '}
                <Typography
                    component="strong"
                    variant="body1"
                    sx={{ fontSize: '20px', fontWeight: 'bolder' }}
                >
                    {formatTimestamp(otc.expires_at.seconds)}
                </Typography>
            </Typography>
            {children}
        </Box>
    );
};

export default DisplayOtc;
