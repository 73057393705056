import { createRoot } from 'react-dom/client';
import 'simplebar/dist/simplebar.css';
import 'assets/third-party/apex-chart.css';
import 'assets/third-party/react-table.css';

import React from 'react';
// project import
import AppProviders from 'contexts';
import App from './App';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <AppProviders>
        <App />
    </AppProviders>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
