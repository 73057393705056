import {
    GET_DEVICE_OPERATIONS,
    GET_DEVICE_OPERATIONS_SUCCESS,
    GET_DEVICE_OPERATIONS_FAILURE,
    COMPLETE_DEVICE_OPERATION
} from './types';

export const getDeviceOperations = () => {
    return {
        type: GET_DEVICE_OPERATIONS
    };
};

export const getDeviceOperationsSuccess = operations => {
    return {
        type: GET_DEVICE_OPERATIONS_SUCCESS,
        payload: operations
    };
};

export const getDeviceOperationsFailure = error => {
    return {
        type: GET_DEVICE_OPERATIONS_FAILURE,
        payload: error
    };
};

export const completeDeviceOperation = operation => {
    return {
        type: COMPLETE_DEVICE_OPERATION,
        payload: operation
    };
};
