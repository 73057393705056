import React from 'react';
// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { FileSearchOutlined, UploadOutlined } from '@ant-design/icons';

// icons
const icons = {
    FileSearchOutlined,
    UploadOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const rentalManager = {
    id: 'rental_manager',
    title: <FormattedMessage id="rentals" />,
    type: 'group',
    children: [
        {
            id: 'tenantLookup',
            title: <FormattedMessage id="tenantLookup" />,
            type: 'item',
            url: '/tenant-lookup',
            icon: icons.FileSearchOutlined
        },
        {
            id: 'newReservation',
            title: <FormattedMessage id="newReservation" />,
            type: 'item',
            url: '/new-reservation',
            icon: icons.UploadOutlined
        }
    ]
};

export default rentalManager;
