// React
import React from 'react';

// Packages
import { useStopwatch } from 'react-timer-hook';

const CallInterval = ({ createdAt }) => {
    const getTimeDiff = () => {
        if (createdAt) {
            const now = new Date();
            const ts = createdAt.toDate();
            const diff = now.getTime() - ts.getTime();
            const start = now;
            start.setMilliseconds(diff);
            return start;
        }
    };

    const { seconds, minutes, hours } = useStopwatch({
        autoStart: true,
        offsetTimestamp: getTimeDiff()
    });

    return (
        <span className="m-4">{`${hours > 0 ? `${hours < 10 ? 0 : ''}${hours}:` : ''}${
            minutes < 10 ? 0 : ''
        }${minutes}:${seconds < 10 ? 0 : ''}${seconds}`}</span>
    );
};

export default CallInterval;
