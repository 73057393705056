import React from 'react';
// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    BorderOutlined,
    BoxPlotOutlined,
    ChromeOutlined,
    DeploymentUnitOutlined,
    GatewayOutlined,
    MenuUnfoldOutlined,
    QuestionOutlined,
    SmileOutlined,
    StopOutlined,
    DashboardOutlined,
    VideoCameraOutlined,
    ScissorOutlined,
    SettingOutlined,
    SafetyCertificateOutlined,
    ControlOutlined,
    TabletOutlined,
    IssuesCloseOutlined
} from '@ant-design/icons';

// icons
const icons = {
    ChromeOutlined,
    DashboardOutlined,
    MenuUnfoldOutlined,
    BoxPlotOutlined,
    StopOutlined,
    BorderOutlined,
    SmileOutlined,
    GatewayOutlined,
    QuestionOutlined,
    DeploymentUnitOutlined,
    VideoCameraOutlined,
    ScissorOutlined,
    SettingOutlined,
    SafetyCertificateOutlined,
    ControlOutlined,
    TabletOutlined,
    IssuesCloseOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const dashboard = {
    id: 'org_dashboard',
    title: <FormattedMessage id="dashboard" />,
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            url: '/dashboard',
            icon: icons.DashboardOutlined
        },
        {
            id: 'accessControl',
            title: <FormattedMessage id="accessControl" />,
            type: 'item',
            url: '/access-control',
            icon: icons.ControlOutlined
        }
        // {
        //     id: 'devices',
        //     title: <FormattedMessage id="devices" />,
        //     type: 'item',
        //     url: '/devices',
        //     icon: icons.TabletOutlined
        // },
        // {
        //     id: 'virtualGuard',
        //     title: <FormattedMessage id="virtualGuard" />,
        //     type: 'item',
        //     url: '/virtual-guard',
        //     icon: icons.SafetyCertificateOutlined
        // },
        // {
        //     id: 'queue',
        //     title: <FormattedMessage id="queue" />,
        //     type: 'item',
        //     url: '/queue',
        //     icon: icons.IssuesCloseOutlined
        // }
    ]
};

export default dashboard;
