import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

// project import
import { connect } from 'react-redux';
import { userRoles } from 'utils/constants';

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children, authUser, userData }) => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!authUser) {
            navigate('login', { replace: true });
        }
    }, [authUser, navigate]);

    if (userData && userData?.role !== userRoles.OPERATOR) {
        return <Navigate to="/maintenance/404" state={{ from: location }} />;
    } else {
        return children;
    }
};

AuthGuard.propTypes = {
    children: PropTypes.node
};

const mapStateToProps = ({ auth, user }) => {
    const { authUser, managerIsChangingPassword } = auth;
    const { userData } = user;
    return { authUser, userData, managerIsChangingPassword };
};

export default connect(mapStateToProps, {})(AuthGuard);
