import {
    SET_USER_ORG,
    CREATE_ORG,
    CREATE_ORG_SUCCESS,
    CREATE_ORG_FAILURE,
    UPDATE_ORG,
    UPDATE_ORG_SUCCESS,
    UPDATE_ORG_FAILURE,
    RESET_ORG_UPDATE_STATE,
    GET_DEFAULT_ROLES_COLLECTION,
    GET_DEFAULT_ROLES_COLLECTION_SUCCESS,
    GET_DEFAULT_ROLES_COLLECTION_FAILURE,
    RESET_ORG_CREATION_STATE,
    SET_PRIMARY_ORG,
    ADD_INVITE_TO_ORG,
    ADD_INVITE_TO_ORG_SUCCESS,
    ADD_INVITE_TO_ORG_FAILURE,
    GET_ORGS_COLLECTION_SUCCESS,
    SET_SELECTED_ORG,
    RESET_INVITE_ADDING_STATE,
    UPDATE_ORG_IMAGE,
    UPDATE_ORG_IMAGE_SUCCESS,
    UPDATE_ORG_IMAGE_FAILURE,
    RESEND_MANAGER_INVITE,
    RESEND_MANAGER_INVITE_SUCCESS,
    RESEND_MANAGER_INVITE_FAILURE
} from '../actions/types';

const INITIAL_STATE = {
    orgs: null,
    selectedOrg: null,
    defaultRoles: null,
    loadingDefaultRoles: false,
    defaultRolesLoadError: null,
    creatingOrg: false,
    orgCreationError: null,
    newOrgName: null,
    updatingOrg: false,
    orgUpdateError: null,
    updatedOrgName: null,
    primaryOrg: null,
    addingInvite: false,
    inviteAddingError: null,
    inviteAddingSuccess: false,
    uploadingOrgImage: false,
    uploadOrgImageSuccess: false,
    uploadOrgImageError: null,
    resendingInvite: false,
    resendingInviteSuccess: null,
    resendingInviteError: null
};

const alphabetizedOrgs = orgs => {
    if (orgs.length > 1) return orgs.sort((a, b) => a.org_name.localeCompare(b.org_name));
    return orgs;
};

const orgReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ORGS_COLLECTION_SUCCESS:
        case SET_USER_ORG:
            return {
                ...state,
                orgs: alphabetizedOrgs(action.payload)
            };
        case CREATE_ORG:
            return {
                ...state,
                creatingOrg: true,
                newOrgName: null
            };
        case CREATE_ORG_SUCCESS:
            return {
                ...state,
                creatingOrg: false,
                newOrgName: action.payload
            };
        case CREATE_ORG_FAILURE:
            return {
                ...state,
                creatingOrg: false,
                orgCreationError: action.payload
            };
        case UPDATE_ORG:
            return {
                ...state,
                updatingOrg: true
            };
        case UPDATE_ORG_SUCCESS:
            return {
                ...state,
                updatingOrg: false,
                updatedOrgName: action.payload
            };
        case UPDATE_ORG_FAILURE:
            return {
                ...state,
                updatingOrg: false,
                orgUpdateError: action.payload
            };
        case RESET_ORG_UPDATE_STATE:
            return {
                ...state,
                updatingOrg: false,
                orgUpdateError: null,
                newOrgName: null
            };
        case RESET_ORG_CREATION_STATE:
            return {
                ...state,
                creatingOrg: false,
                orgCreationError: null,
                newOrgName: null
            };
        case SET_PRIMARY_ORG:
            return {
                ...state,
                primaryOrg: action.payload
            };
        case ADD_INVITE_TO_ORG:
            return {
                ...state,
                addingInvite: true,
                inviteAddingError: null,
                inviteAddingSuccess: false
            };
        case ADD_INVITE_TO_ORG_SUCCESS:
            return {
                ...state,
                addingInvite: false,
                inviteAddingError: null,
                inviteAddingSuccess: action.payload
            };
        case ADD_INVITE_TO_ORG_FAILURE:
            return {
                ...state,
                addingInvite: false,
                inviteAddingError: action.payload,
                inviteAddingSuccess: false
            };
        case GET_DEFAULT_ROLES_COLLECTION:
            return {
                ...state,
                defaultRoles: null,
                loadingDefaultRoles: true,
                defaultRolesLoadError: null
            };
        case GET_DEFAULT_ROLES_COLLECTION_SUCCESS:
            return {
                ...state,
                defaultRoles: action.payload,
                loadingDefaultRoles: false,
                defaultRolesLoadError: null
            };
        case GET_DEFAULT_ROLES_COLLECTION_FAILURE:
            return {
                ...state,
                defaultRoles: null,
                loadingDefaultRoles: false,
                defaultRolesLoadError: action.payload
            };
        case SET_SELECTED_ORG:
            return {
                ...state,
                selectedOrg: action.payload
            };
        case RESET_INVITE_ADDING_STATE:
            return {
                ...state,
                addingInvite: false,
                inviteAddingError: null,
                inviteAddingSuccess: false
            };
        case UPDATE_ORG_IMAGE:
            return {
                ...state,
                uploadingOrgImage: true,
                uploadOrgImageSuccess: false,
                uploadOrgImageError: null
            };
        case UPDATE_ORG_IMAGE_SUCCESS:
            return {
                ...state,
                uploadingOrgImage: false,
                uploadOrgImageSuccess: action.payload,
                uploadOrgImageError: null
            };
        case UPDATE_ORG_IMAGE_FAILURE:
            return {
                ...state,
                uploadingOrgImage: false,
                uploadOrgImageSuccess: false,
                uploadOrgImageError: action.payload
            };
        case RESEND_MANAGER_INVITE:
            return {
                ...state,
                resendingInvite: true
            };
        case RESEND_MANAGER_INVITE_SUCCESS:
            return {
                ...state,
                resendingInvite: false,
                resendingInviteSuccess: action.payload
            };
        case RESEND_MANAGER_INVITE_FAILURE:
            return {
                ...state,
                resendingInvite: false,
                resendingInviteError: action.payload
            };
        default:
            return state;
    }
};

export default orgReducer;
