import React from 'react';

// material-ui
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Checkbox,
    FormControlLabel,
    Box,
    Typography,
    Stack,
    InputLabel,
    FormControl
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers';

// assets
import 'react-widgets/scss/styles.scss';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { errorMessage } from 'utils/constants';
import DisplayOtc from './DisplayOtc';

// ==============================|| CUSTOMER ADD / EDIT / DELETE ||============================== //

const EditOtcModal = ({ onClose, onUpdate, otc, newOtc }) => {
    const submit = values => {
        onUpdate(values);
    };

    const currentDateTime = new Date();

    const getMinDateTime = () => {
        const dateCopy = new Date(currentDateTime.getTime());
        const hoursToAdd = 7 * 60 * 60 * 1000;
        dateCopy.setTime(currentDateTime.getTime() + hoursToAdd);
        return dateCopy;
    };

    const validationSchema = Yup.object().shape({
        expiration_date: Yup.string().trim().required(errorMessage.toDate.required),
        reusable: Yup.boolean()
    });

    return (
        <>
            <DialogTitle>Edit Code</DialogTitle>
            <Divider />
            {!newOtc ? (
                <Formik
                    initialValues={{
                        reusable: otc?.reusable || false,
                        expiration_date: new Date(
                            otc.expires_at.seconds * 1000 +
                                otc.expires_at.nanoseconds / 1000000
                        )
                    }}
                    onSubmit={submit}
                    validationSchema={validationSchema}
                    validateOnChange
                >
                    {({ errors, handleSubmit, setFieldValue, touched, values }) => (
                        <>
                            <DialogContent sx={{ p: 2.5, minHeight: 360 }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12}>
                                        <Typography
                                            sx={{}}
                                            variant="h3"
                                            fontWeight={700}
                                            component="div"
                                            color="secondary"
                                        >
                                            {otc.name}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <Stack>
                                            <InputLabel>
                                                Expiration Date & Time (minimum now + 8
                                                hrs)
                                            </InputLabel>
                                            <FormControl
                                                sx={{ width: '100%' }}
                                                error={Boolean(
                                                    touched.expiration_date &&
                                                        errors.expiration_date
                                                )}
                                            >
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDateFns}
                                                >
                                                    <DateTimePicker
                                                        value={values.expiration_date}
                                                        onChange={newValue =>
                                                            setFieldValue(
                                                                'expiration_date',
                                                                newValue
                                                            )
                                                        }
                                                        minDateTime={getMinDateTime()}
                                                    />
                                                </LocalizationProvider>
                                            </FormControl>
                                        </Stack>
                                    </Grid>
                                    <h1>{values.reusable}</h1>
                                    <Grid item xs={12} sm={12}>
                                        <Stack spacing={0.5}>
                                            <Box>
                                                <FormControlLabel
                                                    value="end"
                                                    control={
                                                        <Checkbox
                                                            checked={values.reusable}
                                                            onChange={() =>
                                                                setFieldValue(
                                                                    'reusable',
                                                                    !values.reusable
                                                                )
                                                            }
                                                        />
                                                    }
                                                    label="Reusable Code"
                                                    labelPlacement="end"
                                                    sx={{ ml: 0 }}
                                                />
                                            </Box>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <Stack spacing={0.5}>
                                            <Typography variant="h6" color="secondary">
                                                * This feature may not be available yet at
                                                your site. Please contact SAGE Support to
                                                enable it.
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <Divider />
                            <DialogActions sx={{ p: 2.5 }}>
                                <Grid
                                    container
                                    justifyContent="right"
                                    alignItems="center"
                                >
                                    <Grid item>
                                        <Stack
                                            direction="row"
                                            spacing={2}
                                            alignItems="center"
                                        >
                                            <Button color="error" onClick={onClose}>
                                                Cancel
                                            </Button>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                onClick={handleSubmit}
                                            >
                                                Edit
                                            </Button>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </DialogActions>
                        </>
                    )}
                </Formik>
            ) : (
                <>
                    <DialogContent sx={{ p: 2.5, minHeight: 360 }}>
                        <DisplayOtc otc={newOtc} />
                    </DialogContent>
                    <DialogActions sx={{ p: 2.5 }}>
                        <Grid container justifyContent="right" alignItems="center">
                            <Grid item>
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <Button variant="contained" onClick={onClose}>
                                        Finished
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </>
            )}
        </>
    );
};

export default EditOtcModal;
