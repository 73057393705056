import {
    CHECK_USER_AUTHENTICATED,
    USER_AUTHENTICATED_SUCCESS,
    USER_AUTHENTICATED_FAILURE,
    LOGIN_USER_EMAIL_PASSWORD,
    LOGIN_USER_FAILURE,
    LOGOUT_USER_SUCCESS,
    CLEAR_AUTH_ERROR,
    NON_AUTH_RESET_USER_PASSWORD,
    NON_AUTH_RESET_USER_PASSWORD_SUCCESS,
    NON_AUTH_RESET_USER_PASSWORD_FAILURE,
    RESET_PASSWORD_REQUEST_STATE,
    EXECUTE_RESET_PASSWORD_FLIGHT,
    ACTION_CODE_VALID,
    ACTION_CODE_INVALID,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
    UPDATE_AUTH_USER_PASSWORD,
    UPDATE_AUTH_USER_PASSWORD_SUCCESS,
    UPDATE_AUTH_USER_PASSWORD_FAILURE,
    UPDATE_AUTH_USER_PASSWORD_RESET,
    UPDATE_AUTH_USER_EMAIL,
    UPDATE_AUTH_USER_EMAIL_SUCCESS,
    UPDATE_AUTH_USER_EMAIL_FAILURE,
    UPDATE_AUTH_USER_EMAIL_RESET,
    MANAGER_IS_CHANGING_PASSWORD,
    VALIDATE_INVITE_CODE,
    VALIDATE_INVITE_CODE_SUCCESS,
    VALIDATE_INVITE_CODE_FAILURE,
    RESET_VALIDATE_INVITE_CODE,
    RESEND_INVITE_CODE,
    RESEND_INVITE_CODE_SUCCESS,
    RESEND_INVITE_CODE_FAILURE,
    CREATE_AUTH_USER,
    CREATE_AUTH_USER_SUCCESS,
    CREATE_AUTH_USER_FAILURE,
    RESET_RESEND_INVITE_CODE
} from '../actions/types';

const INITIAL_STATE = {
    authUser: null,
    loading: false,
    error: null,
    validGuestInvite: null,
    resendGuestInviteSuccess: null,
    resetPasswordComplete: false,
    validActionCode: null,
    validatingCode: true,
    resetPasswordSuccess: false,
    resetPasswordFailure: false,
    updatingPassword: false,
    updatePasswordError: null,
    updatingPasswordSuccess: false,
    updatingEmail: false,
    updateEmailError: null,
    updatingEmailSuccess: false,
    managerIsChangingPassword: false,
    newAuthUserId: null
};

const authReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CHECK_USER_AUTHENTICATED:
            return {
                ...state,
                loading: true
            };
        case USER_AUTHENTICATED_SUCCESS:
            return {
                ...state,
                loading: false,
                authUser: action.payload,
                error: null
            };
        case USER_AUTHENTICATED_FAILURE:
            return {
                ...state,
                loading: false,
                authUser: null
            };
        case LOGIN_USER_EMAIL_PASSWORD:
            return {
                ...state,
                loading: true
            };
        case LOGIN_USER_FAILURE:
            return {
                ...state,
                loading: false,
                authUser: null,
                error: action.payload
            };
        case LOGOUT_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                authUser: null,
                error: null
            };
        case CLEAR_AUTH_ERROR:
            return {
                ...state,
                error: null
            };
        case NON_AUTH_RESET_USER_PASSWORD:
            return {
                ...state,
                loading: true
            };
        case NON_AUTH_RESET_USER_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                resetPasswordComplete: true
            };
        case NON_AUTH_RESET_USER_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                resetPasswordComplete: true
            };
        case RESET_PASSWORD_REQUEST_STATE:
            return {
                ...state,
                loading: false,
                resetPasswordComplete: false,
                resetPasswordFailure: false,
                resetPasswordSuccess: false
            };
        case EXECUTE_RESET_PASSWORD_FLIGHT:
            return {
                ...state,
                loading: true
            };
        case ACTION_CODE_VALID:
            return {
                ...state,
                validActionCode: true,
                validatingCode: false
            };
        case ACTION_CODE_INVALID:
            return {
                ...state,
                validActionCode: false,
                validatingCode: false
            };
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                resetPasswordSuccess: true
            };
        case RESET_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                resetPasswordFailure: true
            };
        case UPDATE_AUTH_USER_PASSWORD:
            return {
                ...state,
                updatingPassword: true,
                updatePasswordError: null,
                updatingPasswordSuccess: false
            };
        case UPDATE_AUTH_USER_PASSWORD_SUCCESS:
            return {
                ...state,
                updatingPassword: false,
                updatingPasswordSuccess: true
            };
        case UPDATE_AUTH_USER_PASSWORD_FAILURE:
            return {
                ...state,
                updatingPassword: false,
                updatePasswordError: action.payload
            };
        case UPDATE_AUTH_USER_PASSWORD_RESET:
            return {
                ...state,
                updatingPassword: false,
                updatePasswordError: null,
                updatingPasswordSuccess: false
            };
        case UPDATE_AUTH_USER_EMAIL:
            return {
                ...state,
                updatingEmail: true,
                updateEmailError: null,
                updatingEmailSuccess: false
            };
        case UPDATE_AUTH_USER_EMAIL_SUCCESS:
            return {
                ...state,
                updatingEmail: false,
                updatingEmailSuccess: true
            };
        case UPDATE_AUTH_USER_EMAIL_FAILURE:
            return {
                ...state,
                updatingEmail: false,
                updateEmailError: action.payload
            };
        case UPDATE_AUTH_USER_EMAIL_RESET:
            return {
                ...state,
                updatingEmail: false,
                updateEmailError: null,
                updatingEmailSuccess: false
            };
        case MANAGER_IS_CHANGING_PASSWORD:
            return {
                ...state,
                managerIsChangingPassword: action.payload
            };
        case VALIDATE_INVITE_CODE:
            return {
                ...state,
                loading: true,
                validGuestInvite: null,
                error: null
            };
        case VALIDATE_INVITE_CODE_SUCCESS:
            return {
                ...state,
                loading: false,
                validGuestInvite: action.payload,
                error: null
            };
        case VALIDATE_INVITE_CODE_FAILURE:
            return {
                ...state,
                loading: false,
                validGuestInvite: null,
                error: action.payload
            };
        case RESET_VALIDATE_INVITE_CODE:
            return {
                ...state,
                loading: false,
                validGuestInvite: null,
                error: null
            };
        case CREATE_AUTH_USER:
            return {
                ...state,
                loading: true
            };
        case CREATE_AUTH_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                newAuthUserId: action.payload,
                error: null
            };
        case CREATE_AUTH_USER_FAILURE:
            return {
                ...state,
                loading: false,
                newAuthUserId: null,
                error: action.payload
            };
        case RESEND_INVITE_CODE:
            return {
                ...state,
                loading: true,
                resendGuestInviteSuccess: null,
                error: null
            };
        case RESEND_INVITE_CODE_SUCCESS:
            return {
                ...state,
                loading: false,
                resendGuestInviteSuccess: true,
                error: null
            };
        case RESEND_INVITE_CODE_FAILURE:
            return {
                ...state,
                loading: false,
                resendGuestInviteSuccess: null,
                error: action.payload
            };
        case RESET_RESEND_INVITE_CODE:
            return {
                ...state,
                loading: false,
                resendGuestInviteSuccess: null,
                error: null
            };
        default:
            return state;
    }
};

export default authReducer;
