import { Loader } from 'components';
import React from 'react';
import { connect } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

const RouteGuard = ({ children, module, activeOrg, permission, activePermissions }) => {
    const location = useLocation();
    if (activePermissions === null || activeOrg === null) {
        return <Loader />;
    }

    if (
        !activePermissions?.includes(permission) ||
        !activeOrg?.modules?.includes(module)
    ) {
        return <Navigate to="/maintenance/404" state={{ from: location }} />;
    }

    return children;
};

const mapStateToProps = ({ user }) => {
    const { activeOrg, defaultRoles, activePermissions } = user;
    return { activeOrg, defaultRoles, activePermissions };
};

export default connect(mapStateToProps, {})(RouteGuard);
