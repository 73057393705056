import {
    SET_OPEN_SNACKBAR,
    SET_CLOSE_SNACKBAR,
    SET_HANDLER_INCREASE,
    SET_HANDLER_DENSE,
    SET_HANDLER_ICON_VARIANTS
} from '../actions/types';

const INITIAL_STATE = {
    action: false,
    open: false,
    message: 'Note archived',
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
    },
    variant: 'default',
    alert: {
        color: 'primary',
        variant: 'filled'
    },
    transition: 'Fade',
    close: true,
    actionButton: false,
    maxStack: 3,
    dense: false,
    iconVariant: 'usedefault',
    isGuardAlert: false,
    autoHideDuration: 3000
};

const snackbarReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_OPEN_SNACKBAR:
            return {
                ...state,
                action: !state.action,
                open: action.payload.open || INITIAL_STATE.open,
                isGuardAlert: action.payload.isGuardAlert || INITIAL_STATE.isGuardAlert,
                persist: action.payload.persist || INITIAL_STATE.persist,
                message: action.payload.message || INITIAL_STATE.message,
                anchorOrigin: action.payload.anchorOrigin || INITIAL_STATE.anchorOrigin,
                variant: action.payload.variant || INITIAL_STATE.variant,
                alert: {
                    color: action.payload.alert?.color || INITIAL_STATE.alert.color,
                    variant: action.payload.alert?.variant || INITIAL_STATE.alert.variant
                },
                transition: action.payload.transition || INITIAL_STATE.transition,
                close:
                    action.payload.close === false
                        ? action.payload.close
                        : INITIAL_STATE.close,
                actionButton: action.paylod?.actionButton || INITIAL_STATE.actionButton,
                autoHideDuration:
                    action.payload.autoHideDuration !== undefined
                        ? action.payload?.autoHideDuration
                        : INITIAL_STATE.autoHideDuration
            };
        case SET_CLOSE_SNACKBAR:
            return {
                ...state,
                open: false
            };
        case SET_HANDLER_INCREASE:
            return {
                ...state,
                maxStack: action.payload.maxStack
            };
        case SET_HANDLER_DENSE:
            return {
                ...state,
                dense: action.payload.dense
            };
        case SET_HANDLER_ICON_VARIANTS:
            return {
                ...state,
                iconVariant: action.payload.iconVariant
            };
        default:
            return state;
    }
};

export default snackbarReducer;
